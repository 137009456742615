import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Input, Modal, notification, Row } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from 'axios';
import { auth_headers } from "../helpers/headers";
import useCurrentWidth from "../helpers/useCurrentWidth";
import { history } from "../helpers/history";
import { urlList } from "../helpers/urlList";
import i18next from 'i18next';

function CreateWebSiteComponent({ type, is_blocked, getWebsites }) {

    const [show_modal, setShowModal] = useState(false);
    const [is_blank_website, setIsBlankWebSite] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    const [error_message, setErrorMessage] = useState(null);
    const [website_create_form] = Form.useForm();
    const width = useCurrentWidth();


    const openModal = () => {
        setShowModal(true);
        setIsBlankWebSite(false);
        website_create_form.resetFields();
        setErrorMessage('');
    }

    const checkSubdomain = async (subdomain) => {
        let url = process.env.REACT_APP_API_URL + `validate-subdomain?query=${subdomain}`;
        try {
            let res = await axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            return res.status === 204
        }
        catch (error) {
            return error.response.status !== 422
        }

    }
    const openBlankWebsiteForm = async () => {
        setIsBlankWebSite(true);
        let subdomain = urlList[Math.floor(Math.random() * 3398)] + "-" + urlList[Math.floor(Math.random() * 3398)]
        while (!checkSubdomain(subdomain)) {
            subdomain = urlList[Math.floor(Math.random() * 3398)] + "-" + urlList[Math.floor(Math.random() * 3398)];
        }
        website_create_form.setFieldsValue({
            subdomain: subdomain
        })
    }

    const closeModal = () => {
        setShowModal(false);
    };

    const handleError = (error) => {
        setIsLoading(false);
        if (error.response.status === 422 && error.response.data.errors) {
            let field = Object.keys(error.response.data['errors'])[0];
            website_create_form.setFields([{ name: field, errors: error.response.data['errors'][field] }])
        } else {
            setErrorMessage(error.response.data.message);
        }
    }

    const submitForm = (data) => {
        setIsLoading(true);
        setErrorMessage('');
        for (let item in data) {
            if (data.hasOwnProperty(item) && data[item] !== undefined) {
                data[item] = data[item].toLowerCase();
                data[item] = data[item].trim();
            }
        }

        let url = process.env.REACT_APP_API_URL + 'websites';
        axios.post(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                notification.success({
                    message: i18next.t("website_created_successfully"),
                    duration: 2
                })
                setIsLoading(false);
                setShowModal(false);
                website_create_form.resetFields();
                getWebsites(res.data.data.id);
            })
            .catch(error => handleError(error))
    };

    return (
        <>
            <Button
                type={type}
                icon={<PlusOutlined />}
                size={width > 768 ? 'large' : 'middle'}
                block={is_blocked}
                onClick={openModal}
            >
                {i18next.t("add_website")}
            </Button>
            <Modal title={i18next.t("create_new_website")} visible={show_modal} onCancel={closeModal} footer={null} centered>
                {is_blank_website ?
                    <Row>
                        <Col span={24}>
                            <Form hideRequiredMark={'false'} name='create_website' layout="vertical" size='middle' className='website-create-form'
                                onFinish={submitForm} form={website_create_form} scrollToFirstError>
                                {error_message && <Alert type={'error'} message={error_message} style={{ marginBottom: '0.5rem' }} />}
                                <Form.Item label={i18next.t("name")} id='name' name='name'
                                    rules={[
                                        { required: true, message: 'Please enter name of your website' },
                                        { min: 3, message: 'Website name should contain 3 or more letters' },
                                        // {validator: validateName}
                                    ]}>
                                    <Input
                                        placeholder={i18next.t("name_of_your_website")}
                                        allowClear />
                                </Form.Item>
                                <Form.Item label={i18next.t("sub_domain")} id='subdomain' name='subdomain' rules={[{ required: true, message: 'Please enter sub domain' }]}>
                                    <Input
                                        placeholder={i18next.t("enter_subdomain")}
                                        allowClear name={'url'}
                                        addonAfter={<small>.{process.env.REACT_APP_SITE_URL}</small>}
                                        onKeyPress={(e) => {
                                            const regex = new RegExp('[a-zA-Z0-9-]')
                                            const pressed_key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                                            if (!regex.test(pressed_key)) {
                                                e.preventDefault();
                                                return false;
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item style={{ marginTop: '1.5rem', marginBottom: '0' }}>
                                    <div className='action-buttons'>
                                        <Button
                                            className="mr--10"
                                            type='default'
                                            size='middle'
                                            htmlType='reset'
                                            onClick={closeModal}
                                        >
                                            {i18next.t("cancel")}
                                        </Button>
                                        <Button
                                            type='primary'
                                            size='middle'
                                            htmlType='submit'
                                        >
                                            {is_loading && <LoadingOutlined />}
                                            {i18next.t("create_website")}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                    :
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Card className='website-option' onClick={openBlankWebsiteForm}>
                                <span>{i18next.t("blank_websites")}</span>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Card className='website-option' onClick={() => { history.push('/templates') }}>
                                <span>{i18next.t("view_templates")}</span>
                            </Card>
                        </Col>
                    </Row>
                }
            </Modal>
        </>
    )
}

export default CreateWebSiteComponent;
