import React from "react";
import cheerio from 'cheerio';
import { Button, Col, DatePicker, Dropdown, Input, Menu, Row, Select, Tooltip } from "antd";
import { block_icons } from "../helpers/blockTypes";
import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import moment from 'moment';
import { textAlign } from "../helpers/textAlign";
import { header_tsizes } from "../helpers/textSizes";
import { timezones } from "../helpers/timezones";
import TextSizePicker from './BlockHelper/TextSizePicker';
import TextAlignPicker from './BlockHelper/TextAlignPicker';
import i18next from 'i18next';

const { Option } = Select;

function TimerBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, other } = block;
    const { end_date, message, timezone, value } = JSON.parse(other);
    const $ = cheerio.load(html);

    const timer_wrapper = $('[data-block-type=timer]');
    const timer = $('[data-block-type=timer] div');
    const script = $('[data-block-type=timer] #script');

    const getColor = () => {
        return timer.css('color') !== undefined ? timer.css('color') : changeColor('rgba(0,0,0,255)')
    }

    const getBackgroundColor = () => {
        return timer.css('background') !== undefined ? timer.css('background') : changeBackgroundColor('rgba(255,255,255,255)')
    }

    const getTimerAlign = () => {
        for (let i = 0, len = textAlign.length; i < len; i++) {
            if (timer.hasClass(`has-text-${textAlign[i].type}`)) {
                return textAlign[i].icon;
            }
        }
        alignChange(textAlign[1])
    }

    const getSelectedSize = () => {
        for (let i = 0, len = header_tsizes.length; i < len; i++) {
            if (timer.hasClass(`is-${header_tsizes[i].value}`)) {
                return header_tsizes[i].name;
            }
        }
        sizeChange(header_tsizes[0]);
    }

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const changeColor = (color) => {
        let temp = block;
        timer.css('color', color);
        temp.html = cheerio.html(timer_wrapper);
        updateBlock(index, temp);
    }

    const changeBackgroundColor = (color) => {
        let temp = block;
        timer.css('background', color);
        temp.html = cheerio.html(timer_wrapper);
        updateBlock(index, temp);
    }

    const alignChange = (align) => {
        let temp = block;
        textAlign.map(item => (
            timer.removeClass(`has-text-${item.type}`)
        ))
        timer.addClass(`has-text-${align.type}`)
        temp.html = cheerio.html(timer_wrapper);
        updateBlock(index, temp);
    }

    const sizeChange = (size) => {
        let temp = block;
        header_tsizes.map(item => (
            timer.removeClass(`is-${item.value}`)
        ))
        timer.addClass(`is-${size.value}`)
        temp.html = cheerio.html(timer_wrapper);
        updateBlock(index, temp);
    }

    const endDateChange = (date) => {
        let temp = block;
        if (date !== null) {
            if (moment(date) > moment()) {
                temp.other = JSON.stringify({
                    end_date: moment(date),
                    message: message,
                    timezone: timezone,
                    value: value
                })
                temp.html = cheerio.html(timer_wrapper);
                updateBlock(temp, index);
            } else {
                temp.other = JSON.stringify({
                    end_date: moment().add(7, 'days'),
                    message: message,
                    timezone: timezone,
                    value: value
                })
                temp.html = cheerio.html(timer_wrapper);
                updateBlock(temp, index);
            }
        } else {
            temp.other = JSON.stringify({
                end_date: moment().utcOffset(timezone).add(7, 'days')._d,
                message: message,
                timezone: timezone,
                value: value
            })
            temp.html = cheerio.html(timer_wrapper);
            updateBlock(temp, index);
        }
    }

    const changeMessageText = (event) => {
        let temp = block;
        temp.other = JSON.stringify({
            end_date: end_date,
            message: event.target.value,
            timezone: timezone,
            value: value
        })
        temp.html = cheerio.html(timer_wrapper);
        updateBlock(temp, index);
    }

    const timezoneChange = (value, timezone) => {
        let temp = block;
        temp.other = JSON.stringify({
            end_date: moment(end_date),
            message: message,
            timezone: value,
            value: value
        })
        temp.html = cheerio.html(timer_wrapper);
        updateBlock(temp, index);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        {/* <Dropdown overlay={<TextSizePicker text_tsizes={header_tsizes} sizeChange={sizeChange} />} trigger={['click']}>
                            <Button className="btn" style={{ width: '5rem' }}>
                                {getSelectedSize()}
                            </Button>
                        </Dropdown> */}
                        <Tooltip
                            placement="top"
                            title={i18next.t("timer_color")}
                        >
                            <Dropdown overlay={
                                <SketchPicker
                                    onChange={(color) => changeColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                    color={getColor()} />
                            } trigger={['click']} placement={'bottomCenter'}>
                                <Button className="btn" style={{ padding: 4 }}>
                                    <span style={{ background: getColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={i18next.t("background_color")}
                        >
                            <Dropdown overlay={
                                <SketchPicker
                                    onChange={(color) => changeBackgroundColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                    color={getBackgroundColor()} />
                            } trigger={['click']} placement={'bottomCenter'}>
                                <Button className="btn" style={{ padding: 4 }}>
                                    <span style={{ background: getBackgroundColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                        <Dropdown overlay={<TextAlignPicker textAlign={textAlign} alignChange={alignChange} getTextAlign={getTimerAlign} />} trigger={['click']}>
                            <Button className={'align-display'}>
                                {getTimerAlign()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'} align={'middle'}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <label className="label">{i18next.t("end_date")}</label>
                    <div className="datePicker-box">
                        <DatePicker
                            value={moment(end_date)}
                            placeholder={i18next.t("select_end_date")}
                            onChange={endDateChange}
                            showTime
                            style={{ width: '100%' }}
                            disabledDate={current => {
                                return moment(current) <= moment()
                            }}
                            showToday={false}
                            dateRender={current => {
                                const style = {};
                                if (moment(current).format('L') === moment(end_date).format('L')) {
                                    style.border = '1px solid #1890ff';
                                    style.borderRadius = '50%';
                                }
                                return (
                                    <div className="ant-picker-cell-inner" style={style}>
                                        {moment(current).date()}
                                    </div>
                                );
                            }}
                        />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <label className="label">{i18next.t("timezone")}</label>
                    <div className="timeZone-box">
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={i18next.t("select_timezone")}
                            optionFilterProp="children" defaultValue={value}
                            onChange={timezoneChange}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={trigger => trigger.parentNode}
                        >
                            {timezones.map((timezone, index) => (
                                <Option value={timezone.value} key={index + '_' + timezone.value}>{timezone.name}</Option>
                            ))}
                            {/* {moment.tz.names().map((tz) => (
                                <Option value={tz}>{tz}</Option>
                            ))} */}
                        </Select>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <label className="label">{i18next.t("message")}</label>
                    <div className="message-box">
                        <Input placeholder={i18next.t("message_text")}
                            value={message} onChange={changeMessageText} autoSize allowClear
                            suffix={
                                <Tooltip
                                    title={i18next.t("timer_expired_text")}
                                    overlayClassName={'desc-tooltip'}>
                                    <ExclamationCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                                </Tooltip>
                            } />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default TimerBlockComponent;
