export const nav_block_html = '<nav class="navbar" data-block-type="nav" style="background: rgba(0,0,0,255)"><div class="mw7 center db fw8 nav-content"><div class="flex items-center justify-between w-100 border-box pa3 menu-items"><div class="logo-box" data-field="logo"><img src="" style="max-width: 4rem;" class="dib w-100 br0 logo" alt="" /></div><div class="links" data-field="link"></div></div></div></nav>';
export const nav_footer_block_html = '<nav class="footer-navbar" data-block-type="nav" style="background: rgba(0,0,0,255)"><div class="mw7 center db fw8 nav-content"><div class="flex items-center justify-between w-100 border-box pa3 menu-items"><div class="links" data-field="link"></div></div></div></nav>';
export const icon_block_html = '<div class="icons-div" data-block-type="icons"><div class="mw7 center db fw8 icons-content"><div class="flex items-center justify-between w-100 border-box pa3 icons-items"><div class="links title is-3 " data-field="link"></div></div></div></div>';
export const headline_block_html = '<div class="headline-div" data-block-type="headline"><h1 style="color: rgb(0, 0, 0);" class="fw8 lh-title title is-3 has-text-centered">Your strong offer here...</h1></div>';
export const subheadline_block_html = '<div class="subheadline-div" data-block-type="subheadline"><h1 style=" color: rgb(0, 0, 0);" class="dib-m fw5 lh-copy subtitle is-3 has-text-centered"> Supporting text for your offer</h1></div>';

export const text_block_html = '<div class="text-div" data-block-type="text"><div style="color: rgb(0, 0, 0);" class="pv2 db lh-copy content is-medium has-text-centered"></div></div>';

export const image_block_html = '<div class="image-div image image-center is-50" data-block-type="image" ><img class="dib w-100" style="word-break: break-all;" src="" alt="" /></div>';
export const image_block_html_with_link = '<div class="image-div image image-center is-50" data-block-type="image" ><a class="link dim f6 f5-ns dib" href=""><img class="dib w-100" style="word-break: break-all;" src="" alt=""></a></div>';

export const youtube_block_html = `<div class="has-text-centered youtube-video-div" data-block-type="youtube"  class="pa2 pa3-ns"><div class="tc db w-100 relative pa0" style="position: relative; padding-top: 56.25%;"><iframe class="youtube-video-iframe" wmode="opaque" src="${process.env.REACT_APP_YOUTUBE_EMBED_LINK}?autoplay=0&modestbranding=1&controls=1&showinfo=0&rel=0&hd=1&wmode=transparent" frameborder="0" title="youtube" allow="autoplay; encrypted-media" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div></div>`;

export const vimeo_block_html = `<div class="has-text-centered vimeo-video-div" data-block-type="vimeo"  class="pa2 pa3-ns"><div class="tc db w-100 relative pa0" style="position: relative; padding-top: 56.25%;"><iframe class="vimeo-video-iframe" wmode="opaque" src="${process.env.REACT_APP_VIMEO_EMBED_LINK}" frameborder="0" title="vimeo" allow="autoplay; encrypted-media" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div></div>`;

export const spacer_block_html = '<div class="spacer-div db cf h3" data-block-type="spacer"></div>';
export const separator_block_html = '<div class="separator-div thick solid db cf h3" style="color: rgb(0, 0, 0, 255);" data-block-type="separator"></div>';
export const button_block_html = `<div class="button-div" data-block-type="button" class="pv1 ph2 ph4-ns mw6 center cf tc"><a href="${process.env.REACT_APP_BUTTON_LINK}" target="_blank" style="color: rgb(255, 255, 255); background: rgb(0, 87, 255);" class="link relative button-reset pa3 db tc ba b--transparent bg-animate pointer w-100 br2 fw8 button is-normal">Click Here</a></div>`;

export const form_block_html = '<form data-block-type="form" class="pa2 pa4-ns mw6 center db cf mb4 form-block" _lpchecked="1">\n' +
    '            <div data-field="form-fields">'+
    '            <input type="hidden" name="block_id" id="block_id" value="__block_id__">\n' +
    '            <input type="hidden" name="page_id" id="page_id" value="__page_id__">\n' +
    '            <input type="hidden" name="website_id" id="website_id" value="__website_id__">\n' +
    '            <input type="hidden" name="company_id" id="company_id" value="__company_id__">\n' +
    '            <input type="hidden" name="redirect_url" id="redirect_url" value="__redirect_url__">\n' +
    '            <input type="hidden" name="forward_data" id="forward_data" value="__forward_data__">\n' +
    '            <div data-field="true" id="first_name_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;">\n' +
    '                <label class="form-label" for="first_name">First Name</label>\n' +
    '                <input class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2"\n' +
    '                       placeholder="John" type="text" name="first_name" id="first_name" maxlength="200" required>\n' +
    '            </div>\n' +
    '            <div data-field="true" id="last_name_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;">\n' +
    '                <label class="form-label" for="last_name">Last Name</label>\n' +
    '                <input class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2"\n' +
    '                       placeholder="Doe" type="text" name="last_name" id="last_name" maxlength="200" required>\n' +
    '            </div>\n' +
    '            <div data-field="true" id="email_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;">\n' +
    '                <label class="form-label" for="email">Email</label>\n' +
    '                <input class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2"\n' +
    '                       placeholder="Your Email" type="email" name="email" id="email" maxlength="200" required>\n' +
    '            </div>\n' +
    '            <div data-field="true" id="phone_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;">\n' +
    '                <label class="form-label" for="phone_number">Phone</label>\n' +
    '                <input class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2"\n' +
    '                       placeholder="0123456789" type="text" name="phone_number" id="phone_number" maxlength="200" required>\n' +
    '            </div>\n' +
    '            </div>' +
    '            <div data-button="true" class="db cf tc">\n' +
    '                <button type="submit" id="submit" class="button is-normal relative form-button button-reset pv3 tc ba b--transparent bg-animate pointer w-100 br2 fw8"\n' +
    '                        style="color: rgb(255, 255, 255); background: rgb(255, 193, 7); border-color: transparent">Let\'s Go!</button>\n' +
    '            </div>\n' +
    '        </form>';

export const timer_block_html = '<div class="timer-container" data-block-type="timer"><div style="color: rgba(0, 0, 0, 255); background: rgba(255, 255, 255, 255);" class="pv2 db lh-copy has-text-centered timer-box" id="timer-div"></div></div>';
export const calendly_block_html = '<div data-block-type="calendly" class="pa2 pa3-ns calendly-div"><div class="tc db w-100 relative pa0"><iframe class="calendly-iframe" wmode="opaque" src="" frameborder="0" title="calendly" allow="autoplay; encrypted-media" allowfullscreen "></iframe></div></div>';