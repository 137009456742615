import React, { useState } from "react";
import { Badge, Button, Col, Modal, notification, Row, Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { FaLink } from "react-icons/all";
import { Link } from "react-router-dom";
import axios from 'axios';
import { FaChartLine, FaCogs, FaEdit, FaTrashAlt } from "react-icons/fa";
import { EditOutlined, DeleteOutlined, LineChartOutlined, SettingOutlined, CopyOutlined } from '@ant-design/icons'
import { Empty } from "antd";
import { auth_headers } from "../helpers/headers";
import { history } from "../helpers/history";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "antd";
import useCurrentWidth from "../helpers/useCurrentWidth";
import EmptyState from './EmptyState';
import Spinner from "./Spinner";
import i18next from 'i18next';

const { Text, Paragraph } = Typography;

function PageListComponent({ website_id, pages, getting_loading, details, onPageDelete }) {

    const [show_modal, setShowModal] = useState(false);
    const [selected_page, setSelectedPage] = useState(null);
    const [deleting_page, setDeletingPage] = useState(false);
    const width = useCurrentWidth();

    const actions = [
        { text: 'design', icon: <EditOutlined /> },
        { text: 'settings', icon: <SettingOutlined /> },
        { text: 'stats', icon: <LineChartOutlined /> },
        { text: 'remove', icon: <DeleteOutlined className="remove-icon" /> },
    ];

    const onCopy = (message) => {
        notification.success({
            message: message,
            duration: 2
        })
    }

    const columns = [
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{i18next.t("pages")}</span>
                    {/* <Badge count={pages.length} style={{ marginLeft: '0.75rem' }} /> */}
                </div>
            ),
            dataIndex: 'meta_title',
            key: 'meta_title',
            width: 400,
            render: (name, item) => (
                <Link className={'p-detail-pages'} to={'/' + website_id + '/edit/' + item.id}>
                    {name ? name : process.env.REACT_APP_DEFAULT_DOCUMENT_NAME}
                </Link>
            )
        },
        {
            title: i18next.t("link"),
            dataIndex: 'url',
            key: 'url',
            width: 500,
            render: url => (
                <>
                    {details !== null &&
                        <div className="input-group">
                            <div className="share-link-box fx fx--ai-c">
                                <div className="share-link">
                                    {details['subdomain']}.{process.env.REACT_APP_SITE_URL}/{url}
                                </div>
                                <div className="copyBtn-box">
                                    <CopyToClipboard text={`https://${details['subdomain']}.${process.env.REACT_APP_SITE_URL}/` + (url ? url : "")}
                                        onCopy={text =>
                                            onCopy(`Link copied to clipboard`)
                                        }>
                                        <Tooltip trigger={['click']}>
                                            <Button className="btn btn-primary copy-btn">
                                                <CopyOutlined />
                                            </Button>
                                        </Tooltip>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )
        },
        {
            title: i18next.t("updated"),
            dataIndex: 'updated',
            key: 'updated',
            width: 300,
            render: updated => (
                <Tooltip
                    placement="top"
                    title={moment(updated).format('MMMM Do YYYY, h:mm:ss a')}>
                    {moment(updated).fromNow()}
                </Tooltip>
            )
        },
        {
            title: i18next.t("actions"),
            key: 'actions',
            render: item => (
                <div className="action-button" style={{ display: 'flex', justifyContent: 'center' }}>
                    {actions.map((action, index) => {
                        switch (action.text) {
                            case 'design':
                                return <Tooltip placement='top' title={i18next.t("design")} key={'actions' + index}>
                                    <span onClick={() => history.push('/' + website_id + '/edit/' + item.id)} className={'p-action-icons ant-btn btn-icon info ant-btn-link'}>
                                        <EditOutlined />
                                    </span>
                                </Tooltip>
                            case 'settings':
                                return <Tooltip placement='top' title={i18next.t("settings")} key={'actions' + index}>
                                    <span onClick={() => history.push('/' + website_id + '/edit/' + item.id + '/settings')} className={'p-action-icons ant-btn btn-icon warning ant-btn-link'}>
                                        <SettingOutlined />
                                    </span>
                                </Tooltip>
                            case 'stats':
                                return <Tooltip placement='top' title={i18next.t("trend")} key={'actions' + index}>
                                    <span onClick={() => history.push('/' + website_id + '/edit/' + item.id + '/stats')} className={'p-action-icons ant-btn btn-icon primary ant-btn-link'}>
                                        <LineChartOutlined />
                                    </span>
                                </Tooltip>
                            case 'remove':
                                return <Tooltip placement='top' title={i18next.t("remove")} key={'actions' + index}>
                                    <span className={'p-action-icons ant-btn btn-icon danger ant-btn-link'} onClick={() => openPageDeleteModal(item)}>
                                        <DeleteOutlined />
                                    </span>
                                </Tooltip>
                            default:
                                return false;
                        }
                    })}
                </div>
            )
        }
    ];

    const small_column = [
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{i18next.t("pages")}</span>
                    {/* <Badge count={pages.length} style={{ marginLeft: '0.75rem' }} /> */}
                </div>
            ),
            dataIndex: 'meta_title',
            key: 'meta_title',
            width: 0,
            render: (name, item) => (
                <div className="sm-content-container">
                    <div className="pageTitle-link-box item">
                        <label className="label lg-hide sm-block">{i18next.t("page_title")}</label>
                        <div className="pageTitle-link">
                            <Link className={'p-detail-pages'} to={'/' + website_id + '/edit/' + item.id}>
                                {name ? name : process.env.REACT_APP_DEFAULT_DOCUMENT_NAME}
                            </Link>
                        </div>
                    </div>
                    <div className="pageLink-box item">
                        {details !== null &&
                            <>
                                <label className="label lg-hide sm-block">{i18next.t("link")}</label>
                                <div className="pageLink">
                                    <div className="input-group">
                                        <div className="share-link-box fx fx--ai-c">
                                            <div className="share-link">
                                                {details['subdomain']}.{process.env.REACT_APP_SITE_URL}/{item.url}
                                            </div>
                                            <div className="copyBtn-box">
                                                <CopyToClipboard text={'https://' + details['subdomain'] + "." + process.env.REACT_APP_SITE_URL + "/" + item.url}
                                                    onCopy={text =>
                                                        onCopy(`Link copied to clipboard`)
                                                    }>
                                                    <Tooltip trigger={['click']}>
                                                        <Button className="btn btn-primary copy-btn">
                                                            <CopyOutlined />
                                                        </Button>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="timeStamp-box item">
                        <label className="label lg-hide sm-block">{i18next.t("updated")}</label>
                        <div className="timeStamp">{moment(item.updated).fromNow()}</div>
                    </div>
                    <div className="action-button-box item">
                        <label className="label lg-hide sm-block">{i18next.t("actions")}</label>
                        <div className="action-button" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {actions.map((action, index) => {
                                switch (action.text) {
                                    case 'design':
                                        return <span onClick={() => history.push('/' + website_id + '/edit/' + item.id)} className={' ant-btn btn-icon info ant-btn-link'}><EditOutlined /></span>
                                    case 'settings':
                                        return <span onClick={() => history.push('/' + website_id + '/edit/' + item.id + '/settings')} className={' ant-btn btn-icon warning ant-btn-link'}><SettingOutlined /></span>
                                    case 'stats':
                                        return <span onClick={() => history.push('/' + website_id + '/edit/' + item.id + '/stats')} className={' ant-btn btn-icon primary ant-btn-link'}><LineChartOutlined /></span>
                                    case 'remove':
                                        return <span className={' ant-btn btn-icon danger ant-btn-link'} onClick={() => openPageDeleteModal(item)}><DeleteOutlined /></span>
                                    default:
                                        return false;
                                }
                            })}
                        </div>
                    </div>
                </div>
            )
        },
    ]

    const openPageDeleteModal = (page) => {
        setSelectedPage(page);
        setShowModal(true);
        setDeletingPage(false);
    }

    const closePageDeleteModal = () => {
        setShowModal(false);
        setSelectedPage(null);
    }

    const deleteWebsite = () => {
        setDeletingPage(true);
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + selected_page.id;
        axios.delete(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDeletingPage(false);
                setShowModal(false);
                setSelectedPage(null);
                notification.success({
                    message: i18next.t("page_deleted_successfully"),
                    duration: 2
                })
                onPageDelete()
            })
            .catch(error => handleError(error))
    }

    const handleError = (error) => {
        setShowModal(false);
        setDeletingPage(false);
        if (error.response.status === 422 && error.response.data.errors) {
            notification.error({
                message: error.response.data.errors[0]['msg'],
                duration: 2
            })
        } else {
            notification.error({
                message: error.response.data.message,
                duration: 2
            })
        }
    }

    return (
        <>
            <div className="tab-content pageList">
                {getting_loading &&
                    <Row>
                        <Col span={24}>
                            <Spinner />
                        </Col>
                    </Row>
                }
                {!getting_loading &&
                    <>
                        {pages.length !== 0 ?
                            <Table
                                style={{ fontSize: 13 }}
                                dataSource={pages}
                                columns={width > 768 ? columns : small_column}
                                pagination={{ position: ['bottomCenter'], pageSize: 5 }}
                                className="pages-list"
                                rowkey={page => page.id}
                            />
                            :
                            <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                <EmptyState message={i18next.t("page_not_found")} />
                            </div>
                        }
                        {details !== null && selected_page !== null &&
                            <Modal visible={show_modal} title={'Confirm Delete Page'} onCancel={closePageDeleteModal}
                                footer={[
                                    <Button
                                        key={'delete_website'}
                                        type={'danger'}
                                        loading={deleting_page}
                                        onClick={deleteWebsite}
                                    >
                                        {i18next.t("confirm_delete_page")}
                                    </Button>,
                                    <Button key={'cancel'} type={'default'} onClick={closePageDeleteModal}>
                                        {i18next.t("cancel")}
                                    </Button>
                                ]}>
                                <Row>
                                    <Col span={24}>
                                        <p>
                                            {i18next.t("this_will_delete_your_page")} <Text strong>({details['subdomain']}.{process.env.REACT_APP_SITE_URL}/{selected_page.url})</Text> {i18next.t("and_all_of_its_data")}.<br />
                                            {i18next.t("this_can't_be_undone")}!
                                        </p>
                                    </Col>
                                </Row>
                            </Modal>
                        }
                    </>
                }
            </div>
        </>

    )
}

export default PageListComponent;
