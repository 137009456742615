import moment from "moment-timezone";

const initial_state = {
    primary_loading: false,
    page_details: {
        name: null,
        blocks: [],
        title: null,
        description: null,
        og_title: null,
        og_image: null,
        og_description: null,
    },
    error: null
}

const temp = [
    {
        key: 1,
        blocks_list: [
            {
                id: 1,
                type: 'HEADLINE',
                html: '<div data-block-type="headline" class="header-wrapper"><h1 id="header" style="font-size: 1.5rem; color: rgb(0, 0, 0); text-align: center; margin: 0">Header Text</h1></div>',
                link: null
            },
            {
                id: 2,
                type: 'SUBHEADLINE',
                html: '<div data-block-type="subheadline" class="sub-header-wrapper"><h1 id="subheader" style="font-size: 1rem; color: rgb(0, 0, 0); text-align: center; margin: 0">Sub Header Text</h1></div>',
                link: null
            },
            // {
            //     id: 3,
            //     type: 'TEXT',
            //     html: '<div data-block-type="text" class="text-wrapper"><p id="text" style="font-size: 0.875rem; color: rgb(0, 0, 0); text-align: center; margin: 0"></p></div>',
            //     link: null
            // },
            // {
            //     id: 4,
            //     type: 'IMAGE',
            //     html: '<div data-block-type="image" class="image-wrapper" style="text-align: center"><img style="word-break: break-all; max-width: 16rem; width: 100%; display: inline-block" src="" alt=""/><a hidden href=""></a><p hidden id="desc"></p></div>',
            //     link: null
            // },
            // {
            //     id: 5,
            //     type: 'YOUTUBE',
            //     html: '<div data-block-type="youtube" style="text-align: center"><div style="position: relative; padding-top: 56.25%;"><iframe wmode="opaque" src="https://www.youtube-nocookie.com/embed/B17-W9el0xg?autoplay=0&modestbranding=1&controls=1&showinfo=0&rel=0&hd=1&wmode=transparent" frameborder="0" title="youtube" allow="autoplay; encrypted-media" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div></div>',
            //     link: 'https://www.youtube.com/watch?v=B17-W9el0xg',
            // },
            // {
            //     id: 6,
            //     type: 'VIMEO',
            //     html: '<div data-block-type="vimeo" style="text-align: center"><div style="position: relative; padding-top: 56.25%;"><iframe style="position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0" allowfullscreen src="https://player.vimeo.com/video/379285215"></iframe></div></div>',
            //     link: 'https://vimeo.com/379285215',
            // },
            // {
            //     id: 7,
            //     type: 'BUTTON',
            //     html: '<div data-block-type="button" style="padding: 0.25rem 2rem; text-align: center; margin-right: auto; margin-left: auto; max-width: 32rem"><a href="https://phonesites.com" target="_blank" style="color: rgb(255, 255, 255); background: rgb(0, 87, 255); text-align: center;width: 100%; position: relative; padding: 1rem; font-weight: 800; display: block; border: 1px solid transparent; border-radius: 2px; cursor:pointer; word-break: initial; font-size: 1rem">Click Here</a></div>',
            //     link: 'https://phonesites.com'
            // },
            // {
            //     id: 8,
            //     type: 'SPACER',
            //     html: '<div data-block-type="spacer" style="height: 4rem; display: block;"></div>',
            //     link: null
            // },
            {
                id: 9,
                type: 'FORM',
                html: '<form data-block-type="form" style="margin-bottom: 2rem; padding: 2rem; max-width: 32rem; display: block" method="POST">\n' +
                    '    <div data-field="true" id="name_wrap" style="position: relative; display: block;">\n' +
                    '        <input autocomplete="name" style="margin-bottom: 0.5rem; padding: 0.7rem 1rem 0; background: #fff; width: 100%; line-height: 1.5; border: 1px solid #f1f1f1; border-radius: 2px; height: 50px; max-width: 100%; position: relative; overflow: visible;"\n' +
                    '         placeholder="Your Name" type="text" name="name" id="name" maxlength="200" required="">\n' +
                    '        <label style="position: absolute; z-index: 2; top: .5rem; left: 1rem; margin-top: 0; font-size: .65rem; pointer-events: none;" for="name">Your Name</label>\n' +
                    '    </div>\n' +
                    '    <div data-field="true" id="phone_wrap" style="position: relative; display: block;">\n' +
                    '        <input style="margin-bottom: 0.5rem; padding: 0.7rem 1rem 0; background: #fff; width: 100%; line-height: 1.5; border: 1px solid #f1f1f1; border-radius: 2px; height: 50px; max-width: 100%; position: relative; overflow: visible;"\n' +
                    '         placeholder="Your Phone" type="text" name="phone_number" id="phone_number" maxlength="200" required="">\n' +
                    '        <label style="position: absolute; z-index: 2; top: .5rem; left: 1rem; margin-top: 0; font-size: .65rem; pointer-events: none;" for="phone">Your Phone</label>\n' +
                    '    </div>\n' +
                    '    <div data-field="true" id="email_wrap" style="position: relative; display: block;">\n' +
                    '        <input style="margin-bottom: 0.5rem; padding: 0.7rem 1rem 0; background: #fff; width: 100%; line-height: 1.5; border: 1px solid #f1f1f1; border-radius: 2px; height: 50px; max-width: 100%; position: relative; overflow: visible;"\n' +
                    '         placeholder="Your Email" type="text" name="email" id="email" maxlength="200" required="">\n' +
                    '        <label style="position: absolute; z-index: 2; top: .5rem; left: 1rem; margin-top: 0; font-size: .65rem; pointer-events: none;" for="email">Your Email</label>\n' +
                    '    </div>\n' +
                    '    <div data-field="true" id="address_wrap" style="position: relative; display: block;">\n' +
                    '        <input style="margin-bottom: 0.5rem; padding: 0.7rem 1rem 0; background: #fff; width: 100%; line-height: 1.5; border: 1px solid #f1f1f1; border-radius: 2px; height: 50px; max-width: 100%; position: relative; overflow: visible;"\n' +
                    '         placeholder="Address" type="text" name="address" id="address" maxlength="200" required="">\n' +
                    '        <label style="position: absolute; z-index: 2; top: .5rem; left: 1rem; margin-top: 0; font-size: .65rem; pointer-events: none;" for="address">Address</label>\n' +
                    '    </div>\n' +
                    '    <div data-button="true" style="text-align: center; display: block">\n' +
                    '        <button type="button" style="text-align: center; padding: 1rem 0; position: relative; width: 100%; font-weight: 800; border: 1px solid transparent; border-radius: 0.25rem; cursor:pointer; word-break: initial; line-height: 1.15; color: rgb(255, 255, 255); background: rgb(255, 193, 7);">Let\'s Go!</button>\n' +
                    '    </div>\n' +
                    '</form>',
                link: null
            },
            // {
            //     id: 10,
            //     type: 'TIMER',
            //     html: '<div data-block-type="timer" id="timer"><p style="font-size: 1.5rem; color: #000000; text-align: center; margin: 0"></p></div>',
            //     link: null,
            //     end_date: moment().tz('UTC').add(7, 'days')._d,
            //     message: 'Time has expired!!!',
            //     timezone: 'UTC'
            // },
            // {
            //     id: 11,
            //     type: 'IFRAME',
            //     html: '<div data-block-type="iframe" style="max-width: 16rem; margin: auto"><div style="position: relative; padding-top: 56.25%;"><iframe style="position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0" allowfullscreen src="https://phonesites.com"></iframe></div></div>',
            //     link: 'https://phonesites.com'
            // },
            // {
            //     id: 12,
            //     type: 'CUSTOM_HTML',
            //     html: ''
            // },
            {
                id: 13,
                type: 'CALENDLY',
                html: '<div data-block-type="calendly" style="text-align: center"><div style="position: relative; padding-top: 56.25%;"><iframe style="position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0" allowfullscreen src=""></iframe></div></div>',
                link: ''
            }
        ]
    }
];

const PageDetailReducer = (state = initial_state, action) => {
    switch (action.type) {
        case 'GET_PAGE_DETAILS':
            // let data = {};
            // for (let i = 0, len = initial_state.length; i < len; i++) {
            //     if (initial_state[i].key === parseInt(action.payload)) {
            //         data = initial_state[i];
            //         break;
            //     }
            // }
            // return data;
            return initial_state[0];
        case 'GET_PAGE_DETAIL_REQ':
            return {
                ...state,
                primary_loading: true
            }
        case 'GET_PAGE_DETAIL_SUC':
            return {
                ...state,
                page_details: action.payload,
                primary_loading: false
            }
        case 'GET_PAGE_DETAIL_ERR':
            return {
                ...state,
                primary_loading: false,
            }
        default:
            return state;
    }
}

export default PageDetailReducer;
