import React from "react";
import '../css/template.css';
import { Col, Row } from "antd";
import parse from 'html-react-parser';
import moment from 'moment-timezone';
import sanitizeHtml from 'sanitize-html';
import { useSelector } from "react-redux";
import EmptyState from './EmptyState';
import cheerio from 'cheerio';
import Spinner from "./Spinner";
import PreviewComponent from './PreviewComponent';
import i18next from 'i18next';

const allowed_tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre']

function PageTemplateDisplayComponent({ blocks_data, nav_block, nav_footer_block, page_details }) {

    const { primary_loading } = useSelector(state => state.page_details);

    const getTimerHtml = (block) => {
        const $ = cheerio.load(block.html);
        const { other } = block;
        let parsed_other = other ? JSON.parse(other) : {}
        while (typeof parsed_other === 'string') {
            parsed_other = JSON.parse(parsed_other)
        }
        const { end_date, message, timezone } = parsed_other;
        const timer_wrapper = $('[data-block-type=timer]')
        let timer = $('[data-block-type=timer] div');
        const end_time = moment.tz(moment(end_date).format('YYYY-MM-DD HH:mm:ss'), timezone);
        const current_time = moment.tz(moment().format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
        const distance = end_time - current_time;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (end_time < current_time) {
            timer.html(`<span class="expiry_message">${message}</span>`);
        }
        else {
            timer.html(`<div class="day-box item"><span class="timer_days numeric">${days}</span><span class="days alphabet"> days </span></div><div class="hour-box item"><span class="timer_hours numeric">${hours}</span><span class="hours alphabet"> hours </span></div><div class="minutes-box item"><span class="timer_minutes numeric">${minutes}</span><span class="minutes alphabet"> minutes </span></div><div class="second-box item"><span class="timer_seconds numeric">${seconds}</span><span class="seconds alphabet"> seconds </span></div>`);
        }
        return cheerio.html(timer_wrapper)
    }
    return (
        <React.Fragment>
            {primary_loading &&
                <Row>
                    <Col span={24}>
                        <Spinner />
                    </Col>
                </Row>
            }
            {!primary_loading &&
                <React.Fragment>
                    {blocks_data.length !== 0 || (nav_block && nav_block.is_enabled) || (nav_footer_block && nav_footer_block.is_enabled) ?
                        <PreviewComponent page_details={page_details}>
                            <div className="main-content" id="block-content" >
                                {nav_block && nav_block.is_enabled &&
                                    parse(nav_block.html)
                                }
                                <div className="content-div">
                                    {blocks_data && blocks_data.map((block, index) => (
                                        <React.Fragment key={index}>
                                            {block.type === 'TIMER' &&
                                                <>
                                                    {parse(getTimerHtml(block))}
                                                    {parse('<script>var countDownDate = ' + moment.tz(JSON.parse(block.other).end_date, JSON.parse(block.other).timezone) + '; var x = setInterval(function () { var now = ' + moment.tz(moment.tz.guess()) + '; var distance = countDownDate - now; var days = Math.floor(distance / (1000 * 60 * 60 * 24)); var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)); var seconds = Math.floor((distance % (1000 * 60)) / 1000); document.getElementById("timer-div").innerHTML = \'<div class="day-box item"><span class="timer_days numeric">\' + days + \'</span><span class="days alphabet"> days </span></div><div class="hour-box item"><span class="timer_hours numeric">\' + hours + \'</span><span class="hours alphabet"> hours </span></div><div class="minutes-box item"><span class="timer_minutes numeric">\' + minutes + \'</span><span class="minutes alphabet"> minutes </span></div><div class="second-box item"><span class="timer_seconds numeric">\' + seconds + \'</span><span class="seconds alphabet"> seconds </span></div>\'; if (distance < 0) { clearInterval(x); document.getElementById("timer-div").innerHTML = \'<span class="expiry_message">' + JSON.parse(block.other).message + '</span>\'; } }, 1000); </script>')
                                                    }
                                                </>
                                            }
                                            {block.type === 'HTML' &&
                                                <>
                                                    {parse(sanitizeHtml(block.html, {
                                                        allowedTags: allowed_tags,
                                                        allowedAttributes: false,
                                                        allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
                                                    }))}
                                                </>
                                            }
                                            {block.type !== 'TIMER' && block.type !== 'HTML' && parse(block.html)}
                                        </React.Fragment>
                                    ))}
                                </div>
                                {nav_footer_block && nav_footer_block.is_enabled &&
                                    parse(nav_footer_block.html)
                                }
                            </div>
                        </PreviewComponent>
                        :
                        <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                            <EmptyState message={i18next.t("no_block_found")} />
                        </div>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default PageTemplateDisplayComponent;