import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Col, Tooltip, Popover } from "antd";
import { block_icons } from "../helpers/blockTypes";
import i18next from 'i18next';
import { animateScroll as scroll } from "react-scroll";

function AddBlockComponent({ is_form_available, is_timer_available, addNewBlock }) {

    const [visible, setVisible] = useState(false);
    const [permissions, setPermissions] = useState([]);

    const openModal = () => {
        setVisible(true);
    }

    const closeModal = () => {
        setVisible(false);
    }

    const addBlock = (block) => {
        addNewBlock(block);
        closeModal();
    }

    const hide = () => {
        setVisible(false);
    }

    const handleVisibleChange = (visible) => {
        setVisible(visible);
    }

    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])

    return (
        <>
            <Row>
                <Col span={24}>
                    <Popover
                        // title="Add Block"
                        trigger="click"
                        // placement="bottom"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                        getPopupContainer={trigger => trigger.parentNode}
                        overlayClassName="addBlock-popover"
                        content={
                            <div className="addBlock-content">
                                <Row gutter={16} className={'add-block-list'}>
                                    {Object.keys(block_icons).map((block) => {
                                        if (block === 'FORM') {
                                            if (permissions.includes('ADD_FORM') && is_form_available) {
                                                return (
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} key={block} className={'add-block-item'} onClick={() => addBlock(block)}>
                                                        <div className="content" onClick={() => scroll.scrollToBottom()}>
                                                            <div className={'add-block-item--icon'}>{block_icons[block]}</div>
                                                            <div className={'add-block-item--name'}>{i18next.t(block)}</div>
                                                        </div>
                                                    </Col>
                                                )
                                            } else {
                                                return null;
                                            }
                                        } else {
                                            if (block === 'TIMER') {
                                                if (permissions.includes('ADD_TIMER') && is_timer_available) {
                                                    return (
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} key={block} className={'add-block-item'} onClick={() => addBlock(block)}>
                                                            <div className="content" onClick={() => scroll.scrollToBottom()}>
                                                                <div className={'add-block-item--icon'}>{block_icons[block]}</div>
                                                                <div className={'add-block-item--name'}>{i18next.t(block)}</div>
                                                            </div>
                                                        </Col>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            }
                                            else {
                                                if (permissions.includes('ADD_' + block)) {
                                                    return (
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} key={block} className={'add-block-item'} onClick={() => addBlock(block)}>
                                                            <div className="content" onClick={() => scroll.scrollToBottom()}>
                                                                <div className={'add-block-item--icon'}>{block_icons[block]}</div>
                                                                <div className={'add-block-item--name'}>
                                                                    {i18next.t(block)}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                else {
                                                    return null
                                                }

                                            }

                                        }
                                    }
                                    )}
                                </Row>
                            </div>
                        }
                    >
                        <Button className="add-block-btn" icon={<PlusOutlined />}></Button>
                    </Popover>
                </Col>
            </Row>
        </>
    )
}

export default AddBlockComponent;
