import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Col, Input, Row, Modal, Select, List, Typography, Form, notification, Tooltip, Tabs, Popconfirm } from "antd";
import axios from 'axios';
import { auth_headers } from "../helpers/headers";
import moment from "moment";
import i18next from 'i18next';
import { SaveOutlined, SyncOutlined, CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import gravatarUrl from "gravatar-url";
import BreadcrumbsComponent from './BreadcrumbsComponent';
import Spinner from "./Spinner";
import EmptyState from './EmptyState';
import locales from './config/locales';

const { Title, } = Typography;
const { TabPane } = Tabs

function AccountsComponent() {

    const [init_loader, setInitLoader] = useState(true);
    const [refresh_api_loader, setRefreshApiLoader] = useState(false);
    const [user_details, setUserDetails] = useState(null);
    const [saving_loader, setSavingLoader] = useState(false);
    const [language, setLanguage] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [user_details_form] = Form.useForm();
    const [password_form] = Form.useForm();

    useEffect(() => getUserDetails(), [])
    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])
    const getUserDetails = () => {
        setInitLoader(true);
        let url = process.env.REACT_APP_API_URL + 'profile';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setUserDetails(res.data.data);
                    setPermissions(Object.values(res.data.data.permissions))
                    for (let item in res.data.data) {
                        if (res.data.data.hasOwnProperty(item)
                            && item !== 'api_key'
                            && item !== 'mobile_number'
                            && item !== 'language') {
                            localStorage.setItem(item, res.data.data[item])
                        }
                    }
                    saveLanguage(res.data.data['language'])
                    setFormValue(res.data.data)
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }

                setInitLoader(false);
            })
            .catch(error => {
                setInitLoader(false);
                let error_message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Oops! Something went wrong.";
                notification.error({
                    message: error_message,
                    duration: 2
                })
            })
    }

    const saveLanguage = (value) => {
        if (value) {
            const current_language = localStorage.getItem('language')
            i18next.changeLanguage(value);
            localStorage.setItem('language', value)
            setLanguage(value)
            if (value !== current_language) {
                window.location.reload();
            }
        }
        else {
            i18next.changeLanguage("en");
            localStorage.setItem('language', "en")
            setLanguage("en")
        }

    }

    const setFormValue = (values) => {
        user_details_form.setFieldsValue({
            company_name: values['company_name'],
            name: values['name'],
            email: values['email'],
            mobile_number: values['mobile_number'],
            language: values['language']
        })
    }

    const editDetails = (detail) => {
        setSavingLoader(true);
        let url = process.env.REACT_APP_API_URL + 'profile'
        axios.patch(url, detail, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setSavingLoader(false);
                if (res && res.data && res.data.data) {
                    for (let item in res.data.data) {
                        if (res.data.data.hasOwnProperty(item)
                            && item !== 'api_key'
                            && item !== 'mobile_number'
                            && item !== 'language') {
                            localStorage.setItem(item, res.data.data[item])
                        }
                    }
                    setUserDetails(res.data.data);
                    saveLanguage(res.data.data['language']);
                    setFormValue(res.data.data)
                    notification.success({
                        message: i18next.t("saved_successfully"),
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
            })
            .catch(error => {
                setSavingLoader(false);
                if (error.response.status === 422 && error.response.data['errors']) {
                    setConfigError(error.response.data['errors'], user_details_form)
                }
                else {
                    let error_message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Oops! Something went wrong.";
                    notification.error({
                        message: error_message,
                        duration: 2
                    })
                }
            })
    }

    const changePassword = (detail) => {
        setSavingLoader(true);
        let url = process.env.REACT_APP_API_URL + 'change-password'
        axios.patch(url, detail, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setSavingLoader(false);
                password_form.resetFields();
                notification.success({
                    message: i18next.t("password_changed_successfully"),
                    duration: 2
                })
            })
            .catch(error => {
                setSavingLoader(false);
                if (error.response.status === 422 && error.response.data['errors']) {
                    setConfigError(error.response.data['errors'], password_form)
                }
                else {
                    let error_message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Oops! Something went wrong.";
                    notification.error({
                        message: error_message,
                        duration: 2
                    })
                }
            })
    }

    const setConfigError = (error, form_name) => {
        const fields = Object.keys(error)
        for (const field of fields) {
            form_name.setFields([{
                name: field,
                errors: error[field]
            }])
        }
    }

    const refreshApiKey = () => {
        setRefreshApiLoader(true);
        let url = process.env.REACT_APP_API_URL + 'update-api';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setRefreshApiLoader(false);
                if (res && res.data && res.data.data) {
                    setUserDetails(res.data.data);
                    notification.success({
                        message: i18next.t("successfully_updated_api_key"),
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }

            })
            .catch(error => {
                setRefreshApiLoader(false);
                notification.error({
                    message: i18next.t("oops_something_went_wrong"),
                    description: 'Please Try Again',
                    duration: 2
                })
            })
    }

    const onCopy = (message) => {
        notification.success({
            message: message,
            duration: 2
        })
    }

    return (
        <div className="bodyContent-wrapper">
            <div className="container">
                <div className="breadcrumb-row">
                    <Row align={'middle'}>
                        <Col flex={'auto'} align={'left'} className="title-badge">
                            <BreadcrumbsComponent my_account={true} />
                        </Col>
                    </Row>
                </div>
                <div className="content-row">
                    <Card className="animated slideInUp white-blue-bg container-card">
                        <div className="accountForm-container">
                            {init_loader &&
                                <Spinner />
                            }
                            {!init_loader && user_details &&
                                <>
                                    <div className="content">
                                        <Row gutter={30} className="row">
                                            <Col className="col main-col gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                                <div className="user-details">
                                                    <div className="userDetails-box">
                                                        <div className="user-image-box">
                                                            <Avatar
                                                                size="large"
                                                                src={gravatarUrl(user_details['email'], {
                                                                    size: 200,
                                                                    default: "retro",
                                                                    rating: "g"
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="userName">
                                                            <Title level={4}>{user_details['name']}</Title>
                                                        </div>
                                                    </div>
                                                    <div className="memberFrom-box">
                                                        <div className="timeStamp-box">
                                                            <label className="label">{i18next.t("member_since")}: </label>
                                                            <span className="timestamp">
                                                                {moment(user_details['created_at']).format('MM/DD/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col main-col gutter-row" xs={24} sm={24} md={24} lg={16} xl={16}>
                                                <div className="tab-conatiner">
                                                    <Tabs type="card">
                                                        <TabPane tab={i18next.t("account_settings")} key="1">
                                                            <Form form={user_details_form} name={'user_details_form'} onFinish={editDetails}>
                                                                <div className="form-content">
                                                                    <div className="form-group">
                                                                        <Row className="row">
                                                                            <Col className="col" xs={24} sm={24} md={6} lg={6} xl={6}>
                                                                                <label className="label bold">{i18next.t("company_name")}</label>
                                                                            </Col>
                                                                            <Col className="col" xs={24} sm={24} md={18} lg={18} xl={18}>
                                                                                <div className="input-field">
                                                                                    <Form.Item id={'company_name'} name={'company_name'}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true, message: i18next.t("company_name_can't_be_blank")
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input className="input" disabled={user_details && !user_details['is_owner']} maxLength={255} type="text" placeholder={i18next.t("enter_your_company_name")} allowClear />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <Row className="row">
                                                                            <Col className="col" xs={24} sm={24} md={6} lg={6} xl={6}>
                                                                                <label className="label bold">{i18next.t("name")}</label>
                                                                            </Col>
                                                                            <Col className="col" xs={24} sm={24} md={18} lg={18} xl={18}>
                                                                                <div className="input-field">
                                                                                    <Form.Item id={'name'} name={'name'} rules={[{ required: true, message: i18next.t("name_can't_be_blank") }]}>
                                                                                        <Input className="input" maxLength={255} type="text" placeholder={i18next.t("enter_your_name")} allowClear />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <Row className="row">
                                                                            <Col className="col" xs={24} sm={24} md={6} lg={6} xl={6}>
                                                                                <label className="label bold">{i18next.t("email")}</label>
                                                                            </Col>
                                                                            <Col className="col" xs={24} sm={24} md={18} lg={18} xl={18}>
                                                                                <div className="input-field">
                                                                                    <Form.Item id={'email'} name={'email'}
                                                                                        rules={[
                                                                                            { required: true, message: i18next.t("email_can't_be_blank") },
                                                                                            { type: 'email', message: i18next.t("enter_vaild_email_id") },
                                                                                        ]
                                                                                        }
                                                                                    >
                                                                                        <Input className="input" maxLength={255} type="email" placeholder={i18next.t("enter_your_email")} allowClear />
                                                                                    </Form.Item>
                                                                                    {/* <Input className="input" type="email" placeholder="Enter your email" /> */}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <Row className="row">
                                                                            <Col className="col" xs={24} sm={24} md={6} lg={6} xl={6}>
                                                                                <label className="label bold">{i18next.t("mobile_no")}</label>
                                                                            </Col>
                                                                            <Col className="col" xs={24} sm={24} md={18} lg={18} xl={18}>
                                                                                <div className="input-field">
                                                                                    <Form.Item id={'mobile_number'} name={'mobile_number'}
                                                                                        rules={[
                                                                                            { required: true, message: i18next.t("mobile_number_can't_be_blank") }
                                                                                        ]
                                                                                        }
                                                                                    >
                                                                                        <Input className="input" type="text" inputMode="numeric" allowClear placeholder={i18next.t("enter_your_mobile_number")} />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    {permissions.includes('API_KEYS') && user_details && user_details['is_owner'] === 1 &&
                                                                        <div className="form-group">
                                                                            <Row className="row">
                                                                                <Col className="col" xs={24} sm={24} md={6} lg={6} xl={6}>
                                                                                    <label className="label bold">{i18next.t("api_key")}</label>
                                                                                </Col>
                                                                                <Col className="col" xs={24} sm={24} md={18} lg={18} xl={18}>
                                                                                    <div className="input-group fx fx--ai-c">
                                                                                        <div className={'share-link-wrapper'}>
                                                                                            <div className={'share-link'}>{user_details['api_key']}</div>
                                                                                        </div>
                                                                                        <div className="copyBtn-box">
                                                                                            <CopyToClipboard text={user_details['api_key']}
                                                                                                onCopy={() =>
                                                                                                    onCopy(i18next.t("api_key_cpoied_to_clipboard")
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Tooltip trigger={['click']}>
                                                                                                    <Button className="btn btn-primary copy-btn"><CopyOutlined />{i18next.t("copy")}</Button>
                                                                                                </Tooltip>
                                                                                            </CopyToClipboard>
                                                                                        </div>
                                                                                        <div className="refreshBtn-box">
                                                                                            <Popconfirm
                                                                                                placement="topRight"
                                                                                                title={'Are you sure you want to refresh API key?'} okText={'Confirm'} onConfirm={refreshApiKey}>
                                                                                                <Button
                                                                                                    className="btn btn-success-outline refresh-btn"
                                                                                                    ghost icon={<SyncOutlined spin={refresh_api_loader} />}
                                                                                                >
                                                                                                    {i18next.t("refresh")}
                                                                                                </Button>
                                                                                            </Popconfirm>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group">
                                                                        <Row className="row">
                                                                            <Col className="col" xs={24} sm={24} md={6} lg={6} xl={6}>
                                                                                <label className="label bold">{i18next.t("CHANGE_LANGUAGE")}</label>
                                                                            </Col>
                                                                            <Col className="col" xs={24} sm={24} md={18} lg={18} xl={18}>
                                                                                <Form.Item id={'language'} name={'language'}>
                                                                                    <Select
                                                                                        className="w-100"
                                                                                        showSearch
                                                                                        defaultValue={language}
                                                                                        key={language}
                                                                                        optionFilterProp="children"
                                                                                    >
                                                                                        {locales.map(option => (
                                                                                            <Select.Option value={option.value} key={option.value}>
                                                                                                {option.label}
                                                                                            </Select.Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                    <div className="">
                                                                        <div className="form-action-button align-right">
                                                                            <Button size="large" htmlType="submit" loading={saving_loader} className="btn btn-success save-btn"><SaveOutlined />{i18next.t("save")}</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </TabPane>
                                                        {permissions.includes('CHANGE_PASSWORD') &&
                                                            <TabPane tab={i18next.t("change_password")} key="2">
                                                                <div className="resetPassword-card">
                                                                    <div className="form-content">
                                                                        <Form form={password_form} name={'password_form'} onFinish={changePassword}>
                                                                            <div className="form-group">
                                                                                <Row className="row">
                                                                                    <Col className="col" xs={24} sm={24} md={8} lg={8} xl={8}>
                                                                                        <label className="label bold">{i18next.t("old_password")}</label>
                                                                                    </Col>
                                                                                    <Col className="col" xs={24} sm={24} md={16} lg={16} xl={16}>
                                                                                        <div className="input-field">
                                                                                            <Form.Item id={'old_password'} name={'old_password'}
                                                                                                rules={[
                                                                                                    { required: true, message: i18next.t("old_password_can't_be_blank") },
                                                                                                ]
                                                                                                }
                                                                                            >
                                                                                                <Input.Password className="input" placeholder={i18next.t("enter_your_old_password")} allowClear />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <Row className="row">
                                                                                    <Col className="col" xs={24} sm={24} md={8} lg={8} xl={8}>
                                                                                        <label className="label bold">{i18next.t("new_password")}</label>
                                                                                    </Col>
                                                                                    <Col className="col" xs={24} sm={24} md={16} lg={16} xl={16}>
                                                                                        <div className="input-field">
                                                                                            <Form.Item id={'password'} name={'password'}
                                                                                                rules={[
                                                                                                    { required: true, message: i18next.t("new_password_can't_be_blank") },
                                                                                                ]}
                                                                                            >
                                                                                                <Input.Password className="input" placeholder={i18next.t("enter_your_new_password")} allowClear />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <Row className="row">
                                                                                    <Col className="col" xs={24} sm={24} md={8} lg={8} xl={8}>
                                                                                        <label className="label bold">{i18next.t("confirm_password")}</label>
                                                                                    </Col>
                                                                                    <Col className="col" xs={24} sm={24} md={16} lg={16} xl={16}>
                                                                                        <div className="input-field">
                                                                                            <Form.Item id={'password_confirmation'} name={'password_confirmation'}
                                                                                                rules={[
                                                                                                    { required: true, message: i18next.t("confirm_password_can't_be_blank") },
                                                                                                ]}
                                                                                            >
                                                                                                <Input.Password className="input" placeholder={i18next.t("confirm_password")} allowClear />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="action-button-box">
                                                                                <div className="form-action-button align-right">
                                                                                    <Button size="large" htmlType="submit" className="btn btn-success save-btn"><SaveOutlined />{i18next.t("save")}</Button>
                                                                                </div>
                                                                            </div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                        }
                                                        <TabPane tab={i18next.t("what_you_can_do")} key="3">
                                                            <div className="form-group">
                                                                <Row className="row">
                                                                    <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                        <Card
                                                                            className="permission-card"
                                                                            // title="What you can do"
                                                                            bordered={false}
                                                                        >
                                                                            <Row className="row">
                                                                                {permissions.map(permission => (<Col className="permission" xs={24} sm={24} md={12} lg={12} xl={12}><CheckOutlined />{i18next.t(permission)}</Col>))}
                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            }
                            {!init_loader && !user_details &&
                                <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                    <EmptyState message={i18next.t("account_details_not_found")} />
                                </div>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default AccountsComponent;
