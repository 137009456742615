import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa';
import { Button, Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import cheerio from 'cheerio';
import { SketchPicker } from 'react-color';
import { image_sizes, logo_sizes } from "../helpers/textSizes";
import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';

function NavFooterBlockComponent({ data, updateData: updateDisplay, updateBlock }) {

    const [permissions, setPermissions] = useState([]);

    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])

    let { is_enabled, html, link, other } = data;
    let other_json = other ? JSON.parse(other) : {}

    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }

    let links = other_json ? other_json.links : null;
    let image = other_json ? other_json.image : null;
    let image_size = other_json ? other_json.image_size : null;
    let image_size_name = other_json ? other_json.image_size_name : null;
    let alt = other_json ? other_json.alt : null;
    let background_color = other_json ? other_json.background_color : null;
    let text_color = other_json ? other_json.text_color : null;

    const $ = cheerio.load(html);
    const nav_wrapper = $('[data-block-type=nav]');
    const links_wrapper = $('[data-block-type=nav] [data-field=link]');
    const logo_wrapper = $('[data-block-type=nav] [data-field=logo]');
    const logo = $('[data-block-type=nav] [data-field=logo] img');
    const [field_arr, setFieldArr] = useState([]);

    useEffect(() => {
        let fields = [];
        for (let i = 0; i < links_wrapper.children().length; i++) {
            fields.push(links_wrapper.children()[i].attribs['data-field-index']);
        }
        setFieldArr(fields);
    }, [])

    const getColor = () => {
        if (text_color)
            return text_color
        changeColor('rgba(255,255,255,255)');
    }

    const getBackgroundColor = () => {
        if (background_color)
            return background_color
        changeBackground('rgba(0, 0, 0, 255)')
    }

    const getLinkText = (index) => {
        let link = links[index]
        return link.text
    }

    const getLink = (index) => {
        let link = links[index]
        return link.href
    }

    const getImageLink = () => {
        return image;
    }

    const getImageDescription = () => {
        return alt;
    }

    const getImageSize = () => {
        if(image_size){
            return {name: image_size_name, value: image_size}
        }
        imageSizeChange(image_sizes[1]);
    }

    const settings_menu = (
        <Menu>
            <Menu.Item key={'setting-cp'} onClick={() => updateDisplay(false)}>
                <span>
                    {i18next.t("hide")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const size_menu = (
        <Menu>
            {logo_sizes.map(size => (
                <Menu.Item onClick={() => imageSizeChange(size)} key={size.name + size.value}>
                    <span>
                        {size.name}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    )

    const addLink = () => {
        let new_data = data;
        let new_other = other_json;
        let index = uuidv4();
        new_other.links.push({ text: "Link", href: "https://", index: index });
        new_data.other = JSON.stringify(new_other);
        links_wrapper.append('<a data-field-index="' + index + '" class="menu-link dim f6 f5-ns dib mr3 mr4-ns" href="/" title="Link" style="color: ' + getColor() + ';">Link</a>');
        let new_fields = [...field_arr];
        new_fields.push(index);
        new_data.html = cheerio.html(nav_wrapper);
        setFieldArr(new_fields);
        updateBlock(new_data);
    }

    const removeLink = (link_index) => {
        let new_data = data;
        links_wrapper.children($('[data-field-index=' + links[link_index].index + ']')).remove();
        let new_fields = [...field_arr];
        let new_other = other_json
        new_other.links.splice(link_index, 1);
        new_data.other = JSON.stringify(new_other);
        new_fields.splice(link_index, 1);
        new_data.html = cheerio.html(nav_wrapper);
        setFieldArr(new_fields);
        updateBlock(new_data);
    }

    const changeLinkText = (event, index) => {
        let new_data = data;
        let text = event.target.value;
        let new_other = other_json;
        new_other.links[index].text = text
        new_data.other = JSON.stringify(new_other);
        links_wrapper.children($('[data-field-index=' + links[index].index + ']')).text(text)
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const changeLink = (event, index) => {
        let new_data = data;
        let link_url = event.target.value;
        let new_other = other_json;
        new_other.links[index].href = link_url
        new_data.other = JSON.stringify(new_other);
        links_wrapper.children($('[data-field-index=' + links[index].index + ']')).attr('href', link_url)
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const changeColor = (color) => {
        let new_data = data;
        for (let i = 0, len = links.length; i < len; i++) {
            links_wrapper.children(($('[data-field-index=' + links[i].index + ']'))).css('color', color);
        }
        let new_other = other_json;
        new_other.text_color = color
        new_data.other = JSON.stringify(new_other);
        new_data.html = cheerio.html(nav_wrapper);
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const changeBackground = (color) => {
        let new_data = data;
        nav_wrapper.css('background', color);
        let new_other = other_json;
        new_other.background_color = color
        new_data.other = JSON.stringify(new_other);
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const changeImageSrc = (link) => {
        let new_data = data;
        logo.attr('src', link);
        let new_other = other_json;
        new_other.image = link
        new_data.other = JSON.stringify(new_other);
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const imageSizeChange = (size) => {
        let new_data = data;
        logo.css('max-width', size.value);
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const imageDescriptionChange = (event) => {
        let new_data = data;
        let alt = event.target.value;
        let new_other = other_json;
        new_other.alt = alt;
        new_data.other = JSON.stringify(new_other);
        logo.attr('alt', alt);
        new_data.html = cheerio.html(nav_wrapper);
        updateBlock(new_data);
    }

    const linkValueChange = (event) => {
        let new_data = data;
        new_data.link = event.target.value;
        updateBlock(new_data);
        if (event.target.value.length === 0) {
            let html = '<div data-field="logo"><img src="" style="max-width: 4rem;" class="dib w-100 br0" alt=""/></div>';
            const $ = cheerio.load(html);
            const new_logo_wrapper = $('[data-field=logo]');
            const new_logo = $('[data-field=logo] img');
            new_logo.attr('src', getImageLink());
            new_logo.attr('alt', getImageDescription());
            new_logo.attr('max-width', getImageSize())
            logo_wrapper.replaceWith(new_logo_wrapper);
            new_data.html = cheerio.html(nav_wrapper);
            updateBlock(new_data);
        } else {
            let html = '<div data-field="logo"><a class="link dim" href="" title="Home"><img style="max-width: 4rem" src="" class="dib w-100 br0" alt=""></a></div>';
            const $ = cheerio.load(html);
            const new_logo_wrapper = $('[data-field=logo]');
            const logo_link = $('[data-field=logo] a');
            logo_link.attr('href', link);
            const new_logo = $('[data-field=logo] img');
            new_logo.attr('src', getImageLink());
            new_logo.attr('alt', getImageDescription());
            new_logo.attr('max-width', getImageSize())
            logo_wrapper.replaceWith(new_logo_wrapper);
            new_data.html = cheerio.html(nav_wrapper);
            updateBlock(new_data);
        }
    }

    return (
        <>
            {permissions.includes('ADD_FOOTER') && !(is_enabled) &&
                <Row>
                    <Col span={24}>
                        <Button
                            className="add-navigation-btn"
                            type={'dashed'}
                            size={'middle'}
                            icon={<PlusOutlined />}
                            block onClick={() => updateDisplay(true)}
                            style={{ height: '3rem', fontSize: '1rem' }}
                        >
                            {i18next.t("add_footer")}
                        </Button>
                    </Col>
                </Row>
            }
            {is_enabled &&
                <div className={'block-item'}>
                    <Row className={'block-item--header'}>
                        <Col align={'left'} flex={'auto'}>
                            <span className={'icon'}><FaBars /></span>
                            <span className={'heading text-uppercase'}>{i18next.t("footer")}</span>
                        </Col>
                        <Col aling={'right'}>
                            <Button.Group style={{ display: 'flex' }}>
                                <Tooltip
                                    placement="top"
                                    title={i18next.t("background_color")}
                                >
                                    <Dropdown
                                        overlay={
                                            <SketchPicker
                                                onChange={(color) => changeBackground('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                                color={getBackgroundColor()}
                                            />
                                        }
                                        trigger={['click']}
                                        placement={'bottomCenter'}
                                    >
                                        <Button
                                            className="btn"
                                            style={{ padding: 4 }}
                                        >
                                            <span style={{ background: getBackgroundColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                                        </Button>
                                    </Dropdown>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={i18next.t("link_color")}
                                >
                                    <Dropdown
                                        overlay={
                                            <SketchPicker
                                                onChange={(color) => changeColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                                color={getColor()} />
                                        }
                                        trigger={['click']}
                                        placement={'bottomCenter'}
                                    >
                                        <Button className="btn" style={{ padding: 4 }}>
                                            <span style={{ background: getColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                                        </Button>
                                    </Dropdown>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={i18next.t("settings")}
                                >
                                    <Dropdown
                                        overlay={settings_menu}
                                        trigger={['click']}
                                    >
                                        <Button className="btn" icon={<SettingOutlined />} />
                                    </Dropdown>
                                </Tooltip>
                            </Button.Group>
                        </Col>
                    </Row>
                    <Row className={'block-item--tbox'}>
                        {/* <Col span={24}>
                            <label className="label">{i18next.t("add_footer_link")}</label>
                        </Col> */}
                        {links_wrapper.children().length !== 0 && links &&
                            <Col span={24}>
                                <>
                                    {links.map((field, index) => (
                                        <Row gutter={[10, 0]} key={'field' + index}>
                                            <Col span={11}>
                                                <Input value={getLinkText(index)}
                                                    placeholder={i18next.t("link_text")} allowClear onChange={(event) => changeLinkText(event, index)} />
                                            </Col>
                                            <Col span={11}>
                                                <Input value={getLink(index)}
                                                    placeholder={i18next.t("https://")} allowClear
                                                    onChange={(event) => changeLink(event, index)} />
                                            </Col>
                                            <Col span={2}>
                                                <Button icon={<MinusCircleOutlined />} danger type={'link'} onClick={() => removeLink(index)} />
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            </Col>
                        }
                        <Col span={24}>
                            <Button
                                block
                                type={'dashed'}
                                disabled={links && links.length > 3}
                                icon={<PlusOutlined />}
                                onClick={addLink}
                            >
                                {i18next.t("add_link")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default NavFooterBlockComponent;