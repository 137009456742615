import React, { useState, useEffect } from "react";
import { Col, Card, Row, notification, Input } from "antd";
import WebSitesListComponent from "./WebSitesListComponent";
import CreateWebSiteComponent from "./CreateWebSiteComponent";
import WeeklyStats from "./WeeklyStats";
import axios from "axios";
import { auth_headers } from "../helpers/headers";
import { history } from "../helpers/history";
import { Typography } from "antd";
import i18next from 'i18next';

const { Title } = Typography;
const { Search } = Input;

function HomeComponent() {

    const [search_text] = useState('');
    const [sort_ascending] = useState(true);
    const [stats_loading, setStatsLoading] = useState(false);
    const [stats_details, setStatsDetails] = useState(null);

    const getWebsiteStats = () => {
        setStatsLoading(true);
        let stats_url = `${process.env.REACT_APP_API_URL}statistics`;
        axios.get(stats_url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setStatsDetails(res.data.data);
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
                setStatsLoading(false)
            })
            .catch(error => {
                setStatsLoading(false)
                if (error && error.response && error.response.data && error.response.data.message) {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
            })
    }

    useEffect(() => getWebsiteStats(), [])
    return (
        <div className="bodyContent-wrapper">
            <div className="container">
                <div className="content-row">
                    <WeeklyStats stats_loading={stats_loading} stats_details={stats_details} />
                    <Row type="flex" className="container-row" justify="space-between" align="middle">
                        <Col className="title-badge">
                            <Title level={4}>{i18next.t("websites")}</Title>
                            {/* <Badge count={website_count} style={{ marginLeft: '0.4rem' }} /> */}
                        </Col>
                        <Col>
                            <CreateWebSiteComponent
                                type={'primary'}
                                is_blocked={false}
                                getWebsites={(website_id) => history.push('/websites/' + website_id)}
                            />
                        </Col>
                    </Row>
                    <Card className="animated slideInUp white-blue-bg container-card">
                        <WebSitesListComponent sort_ascending={sort_ascending} search_text={search_text} />
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default HomeComponent
