import React, { useEffect, useState } from "react";
import axios from 'axios';
import { auth_headers } from "../helpers/headers";
import { Line } from 'react-chartjs-2';
import { Button, Col, Row, Typography, DatePicker } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import i18next from 'i18next';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const defaultRanges = [moment().subtract(7, 'days'), moment()];

function StatsComponent({ type, website_id, page_id }) {

    const [init_loader, setInitLoader] = useState(true);
    const [data, setData] = useState({});
    const [ranges, setRanges] = useState(defaultRanges);

    useEffect(() => getStatistics(), [])

    const getStatistics = () => {
        setInitLoader(true);
        switch (type) {
            case 'website':
                let url = process.env.REACT_APP_API_URL + 'statistics/websites/' + website_id + '?from=' + ranges[0].format('YYYY-MM-DD') + '&to=' + ranges[1].format('YYYY-MM-DD');
                axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                    .then(res => {
                        plotGraph(res.data.data);
                        setInitLoader(false);
                    })
                    .catch(error => {
                        console.log(error.response);
                        setInitLoader(false);
                    })
                break;
            case 'page':
                let page_url = process.env.REACT_APP_API_URL + 'statistics/websites/' + website_id + '/pages/' + page_id + '?from=' + ranges[0].format('YYYY-MM-DD') + '&to=' + ranges[1].format('YYYY-MM-DD');
                axios.get(page_url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                    .then(res => {
                        plotGraph(res.data.data);
                        setInitLoader(false);
                    })
                    .catch(error => {
                        console.log(error.response);
                        setInitLoader(false);
                    })
                break;
            default:
                setInitLoader(false);
                break;
        }
    }

    const plotGraph = (data) => {
        let labels = [];
        let totals_arr = [];
        let uniques_arr = [];
        let leads_arr = [];
        for (let i = 0; i < data.length; i++) {
            labels.push(new Date(data[i]['created_at']).toDateString());
            totals_arr.push(data[i]['total']);
            uniques_arr.push(data[i]['unique']);
            leads_arr.push(data[i]['leads']);
        }
        let ctx = document.getElementById('container').getContext('2d')
        let t_gradient = ctx.createLinearGradient(0, 0, 0, 400);
        t_gradient.addColorStop(0, 'rgba(255, 122, 69, 255)');
        t_gradient.addColorStop(1, 'rgba(255, 122, 69, 0.10)');
        let u_gradient = ctx.createLinearGradient(0, 0, 0, 400);
        u_gradient.addColorStop(0, 'rgba(255, 197, 61, 255)');
        u_gradient.addColorStop(1, 'rgba(255, 197, 61, 0.10)');
        let l_gradient = ctx.createLinearGradient(0, 0, 0, 400);
        l_gradient.addColorStop(0, 'rgba(64, 169, 255, 255)');
        l_gradient.addColorStop(1, 'rgba(64, 169, 255, 0.10)');
        let graph_data = {
            labels: labels,
            datasets: [
                { label: 'Total', backgroundColor: t_gradient, data: totals_arr },
                { label: 'Unique', backgroundColor: u_gradient, data: uniques_arr },
                { label: 'Leads', backgroundColor: l_gradient, data: leads_arr },
            ]
        }
        setData(graph_data);
    }

    const changeRanges = (event) => {
        if (event === null) {
            setRanges(defaultRanges);
        } else {
            setRanges(event);
        }
    }

    return (
        <>
            <div className="tab-content stats">
                <div className="title-row">
                    <div className="filter-row fx fx--ai-c">
                        <div className="rangePicker-filter fx fx--ai-c">
                            <div className="rangePicker">
                                <RangePicker
                                    defaultValue={ranges}
                                    format={['DD-MMM-YYYY', 'DD-MMM-YYYY']}
                                    disabledDate={(current) => {
                                        return current > moment().endOf('day')
                                    }}
                                    onChange={changeRanges}
                                />
                            </div>
                            <div className="go-btn-box">
                                <Button
                                    className="btn go-btn"
                                    type={'primary'}
                                    icon={<SearchOutlined />} onClick={getStatistics}
                                >
                                    {i18next.t("go")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="canvas-content">
                    <Line id={'container'}
                        width={'100%'}
                        height={'30rem'}
                        options={{
                            responsive: true,
                        }}
                        legend={{
                            display: true,
                            position: 'bottom',
                            align: 'left'
                        }}
                        data={data}
                    />
                </div>
            </div>
        </>
    )
}

export default StatsComponent;
