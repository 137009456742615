import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Divider, Empty, Form, Input, Modal, notification, Row, Spin, Table, Tooltip, Typography } from "antd";
import { LoadingOutlined, DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import useCurrentWidth from "../helpers/useCurrentWidth";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { auth_headers } from "../helpers/headers";
import moment from "moment";
import axios from 'axios';
import { history } from "../helpers/history";
import BreadcrumbsComponent from "./BreadcrumbsComponent"
import EmptyState from './EmptyState';
import Spinner from "./Spinner";
import i18next from 'i18next';

const { Text, Title } = Typography;

const SubUserComponent = () => {

    const [show_form_modal, setShowFormModal] = useState(false);
    const [show_delete_modal, setShowDeleteModal] = useState(false);
    const [init_loading, setInitLoading] = useState(true);
    const [delete_loader, setDeleteLoader] = useState(false);
    const [form_loader, setFormLoader] = useState(false);
    const [sub_users, setSubUsers] = useState(null);
    const [selected_user, setSelectedUser] = useState(null);
    const [error_message, setErrorMessage] = useState(null);
    const [is_edit, setIsEdit] = useState(false);
    const [user_create_form] = Form.useForm();
    const width = useCurrentWidth();

    useEffect(() => getSubUsers(), [])

    const openCreateUserModal = () => {
        setErrorMessage(null);
        user_create_form.resetFields();
        setSelectedUser(null);
        setIsEdit(false);
        setFormLoader(false);
        setShowFormModal(true);
    }

    const openDeleteUserModal = (item) => {
        setSelectedUser(item);
        setShowDeleteModal(true);
    }

    const openEditModal = (item) => {
        setErrorMessage(null);
        user_create_form.setFieldsValue({ name: item.name, email: item.email, password: '' });
        setSelectedUser(item);
        setIsEdit(true);
        setFormLoader(false);
        setShowFormModal(true);
    }

    const closeCreateUserModal = () => {
        setShowFormModal(false);
    }

    const closeDeleteUserModal = () => {
        setShowDeleteModal(false);
    }

    const getSubUsers = () => {
        if (localStorage.getItem('is_owner') === '1') {
            setInitLoading(true);
            let url = process.env.REACT_APP_API_URL + 'subuser';
            axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                .then(res => {
                    setSubUsers(res.data.data);
                    setInitLoading(false);
                })
                .catch(error => {
                    setInitLoading(false);
                    notification.error({
                        message: i18next.t("oops_something_went_wrong"),
                        description: 'Please try again',
                        duration: 3
                    })
                })
        } else {
            history.push('/');
        }
    }

    const actions = [
        { text: 'edit', icon: <FaEdit /> },
        { text: 'remove', icon: <FaTrashAlt /> },
    ];

    const createUser = (data) => {
        setFormLoader(true);
        let url = process.env.REACT_APP_API_URL + 'subuser';
        axios.post(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                notification.success({
                    message: i18next.t("user_added_successfully"),
                    duration: 2,
                })
                setFormLoader(false);
                setShowFormModal(false);
                getSubUsers();
            })
            .catch(error => {
                setFormLoader(false);
                // setShowFormModal(false);
                if (error.response.status === 422 && error.response.data.errors) {
                    let field = Object.keys(error.response.data['errors'])[0];
                    user_create_form.setFields([{ name: field, errors: error.response.data['errors'][field] }])
                } else {
                    setErrorMessage(error.response.data.message);
                }
            })
    }

    const editUser = (data) => {
        setFormLoader(true);
        let url = process.env.REACT_APP_API_URL + 'subuser/' + selected_user.id;
        axios.patch(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                notification.success({
                    message: i18next.t("user_updated_successfully"),
                    duration: 2,
                })
                setFormLoader(false);
                setShowFormModal(false);
                getSubUsers();
            })
            .catch(error => {
                setFormLoader(false)
                // setShowFormModal(false);
                if (error.response.status === 422 && error.response.data.errors) {
                    let field = Object.keys(error.response.data['errors'])[0];
                    user_create_form.setFields([{ name: field, errors: error.response.data['errors'][field] }])
                } else {
                    setErrorMessage(error.response.data.message);
                }
            })
    }

    const deleteUser = () => {
        setDeleteLoader(true);
        let url = process.env.REACT_APP_API_URL + 'subuser/' + selected_user.id;
        axios.delete(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                notification.success({
                    message: i18next.t("user_deleted_successfully"),
                    duration: 2,
                })
                setDeleteLoader(false);
                setShowDeleteModal(false);
                getSubUsers();
            })
            .catch(error => {
                setDeleteLoader(false);
                setShowDeleteModal(false);
                if (error.response.status === 422 && error.response.data.errors) {
                    notification.error({
                        message: error.response.data['errors'][Object.keys(error.response.data['errors'])[0]],
                        duration: 2
                    })
                } else {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
            })
    }

    const columns = [
        {
            title: i18next.t("name"),
            dataIndex: 'name',
            key: 'name',
            width: 400,
            render: name => (
                <Text strong level={4}>{name}</Text>
            )
        },
        {
            title: i18next.t("email"),
            dataIndex: 'email',
            key: 'email',
            width: 300,
        },
        {
            title: i18next.t("created_at"),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 500,
            // defaultSortOrder: 'ascend',
            // sorter: (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix(),
            render: created_at => (
                <span>
                    {moment(created_at).fromNow().charAt(0).toUpperCase() + moment(created_at).fromNow().slice(1)}
                </span>
            )
        },
        {
            title: i18next.t("actions"),
            key: 'actions',
            fixed: 'right',
            render: item => (
                <div className="action-button">
                    <Button className="btn-icon info" type="link" onClick={() => openEditModal(item)}>
                        <Tooltip placement='top' title='Edit'>
                            <EditOutlined />
                        </Tooltip>
                    </Button>
                    <Button className="btn-icon danger" type="link" onClick={() => openDeleteUserModal(item)}>
                        <Tooltip placement='top' title='Delete'>
                            <DeleteOutlined className='website-icons remove-icon' />
                        </Tooltip>
                    </Button>
                </div>
            )
        }
    ];

    const small_columns = [
        {
            title: i18next.t("details"),
            dataIndex: 'name',
            key: 'name',
            width: 400,
            render: (name, item) => (
                <>
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <Title level={4}>{item.name}</Title>
                        <Text>{item.email}</Text>
                        <Text type={'secondary'}>{moment(item.created_at).fromNow()}</Text>
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem' }}>
                        {actions.map((action, index) => {
                            switch (action.text) {
                                case 'edit':
                                    return <Tooltip placement='top' title={action.text} key={'actions' + index}>
                                        <span className={'p-action-icons'} onClick={() => openEditModal(item)}>{action.icon}</span>
                                    </Tooltip>
                                case 'remove':
                                    return <Tooltip placement='top' title={action.text} key={'actions' + index}>
                                        <span className={'p-action-icons'} onClick={() => openDeleteUserModal(item)}>{action.icon}</span>
                                    </Tooltip>
                                default:
                                    return false;
                            }
                        })}
                    </div>
                </>
            )
        },
    ]

    const validateEmail = (rule, value) => {
        let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (value === undefined) {
            return Promise.reject('Please enter email')
        } else {
            if (re.test(String(value).toLowerCase())) {
                return Promise.resolve();
            } else {
                return Promise.reject('Please Enter valid email');
            }
        }
    }

    return (
        <div className="bodyContent-wrapper">
            <div className="container">
                <div className="breadcrumb-row">
                    <Row align={'middle'}>
                        <Col flex={'auto'} align={'left'} className="title-badge">
                            <BreadcrumbsComponent team_management={true} />
                        </Col>
                        <Col>
                            <Button disabled={init_loading} type={'primary'} icon={<PlusOutlined />} size={width > 768 ? 'large' : 'middle'}
                                onClick={openCreateUserModal}>
                                {i18next.t("add_user")}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="content-row">
                    <Card
                        className="animated slideInUp white-blue-bg container-card"
                    >
                        {init_loading &&
                            <Row>
                                <Col span={24}>
                                    <Spinner />
                                </Col>
                            </Row>
                        }
                        {!init_loading &&
                            <>
                                {sub_users.length === 0 ?
                                    <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                        <EmptyState message={i18next.t("subusers_not_found")} />
                                    </div>
                                    :
                                    <Table dataSource={sub_users}
                                        columns={columns}
                                        className={'table-list'}
                                        rowkey={user => user.id}
                                        pagination={false}
                                        scroll={{ x: true }} />
                                }
                                <Modal
                                    title={!is_edit ? i18next.t("add_user") : i18next.t("edit_user")}
                                    visible={show_form_modal}
                                    footer={null}
                                    onCancel={closeCreateUserModal}
                                    centered
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Form hideRequiredMark={'false'} name='create_website' layout="vertical" size='middle' className='website-create-form'
                                                onFinish={!is_edit ? createUser : editUser} form={user_create_form} scrollToFirstError>
                                                {error_message && <Alert type={'error'} message={error_message} style={{ marginBottom: '0.5rem' }} />}
                                                <Form.Item label={i18next.t("name")} id='name' name='name' rules={[{ required: true, message: 'Please enter user name' }]}>
                                                    <Input placeholder={'Jane Appleseed'} allowClear />
                                                </Form.Item>
                                                <Form.Item label={i18next.t("email")} id='email' name='email' rules={[{ validator: validateEmail }]}>
                                                    <Input type={'email'} placeholder={'jane@yourcompany.com'} allowClear />
                                                </Form.Item>
                                                <Form.Item label={i18next.t("password")} id='password' name='password'
                                                    rules={[{ min: 6, message: 'Password must be at least 6 character' }]}>
                                                    <Input.Password placeholder={i18next.t("easy_to_remember_hard_to_guess")}
                                                        allowClear />
                                                </Form.Item>
                                                <Form.Item style={{ marginTop: '1.5rem', marginBottom: '0' }}>
                                                    <div className='action-buttons'>
                                                        <Button
                                                            className="mr--10"
                                                            type='default'
                                                            size='middle'
                                                            htmlType='reset'
                                                            onClick={closeCreateUserModal}
                                                        >
                                                            {i18next.t("cancel")}
                                                        </Button>
                                                        <Button
                                                            type='primary'
                                                            size='middle'
                                                            htmlType='submit'
                                                        >
                                                            {form_loader && <LoadingOutlined />}
                                                            {!is_edit ? i18next.t("add_user") : i18next.t("save")}
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Modal>
                                {selected_user !== null &&
                                    <Modal
                                        centered
                                        visible={show_delete_modal}
                                        title={i18next.t("confirm_delete_user")}
                                        onCancel={closeDeleteUserModal}
                                        footer={[
                                            <Button
                                                key={'delete_website'}
                                                type={'danger'}
                                                loading={delete_loader}
                                                onClick={deleteUser}
                                            >
                                                {i18next.t("delete_user")}
                                            </Button>,
                                            <Button
                                                key={'cancel'}
                                                type={'default'}
                                                onClick={closeDeleteUserModal}
                                            >
                                                {i18next.t("cancel")}
                                            </Button>
                                        ]}
                                    >
                                        <Row>
                                            <Col span={24}>
                                                <p>
                                                    {i18next.t("are_you_sure")}?<br />
                                                    {i18next.t("the_user")} (<Text strong>{selected_user.name}</Text>) {i18next.t("will_be_deleted")}<br />
                                                    {i18next.t("this_can't_be_undone")}!
                                                </p>
                                            </Col>
                                        </Row>
                                    </Modal>
                                }
                            </>
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default SubUserComponent;
