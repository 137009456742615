import {combineReducers} from "redux";
import AuthReducer from "./AuthReducer";
import WebSiteReducer from "./WebSiteReducer";
import WebSitePagesReducer from "./WebSitePagesReducer";
import PageTemplateReducer from "./PageTemplateReducer";
import PageDetailReducer from "./PageDetailReducer";
import WebsiteTemplatesReducer from "./WebsiteTemplatesReducer";

const RootReducer = combineReducers({
    auth: AuthReducer,
    websites: WebSiteReducer,
    website_pages: WebSitePagesReducer,
    page_templates: PageTemplateReducer,
    page_details: PageDetailReducer,
    website_templates: WebsiteTemplatesReducer
});

export default RootReducer;
