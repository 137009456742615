const logged_in_user = localStorage.getItem('access_token');
const initial = {error: null, loading: false, fp_loading: false, fp_error: null};
const initial_state = logged_in_user ? {...initial, logged_in: true} : {...initial, logged_in: false};

const AuthReducer = (state = initial_state, action) => {

    switch (action.type) {
        case 'LOGIN_REQUEST':
            return {
                ...state,
                loading: true,
                error: null
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                logged_in: true,
                loading: false,
            };
        case 'LOGIN_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'FORGOT_PASSWORD_REQ':
            return {
                ...state,
                fp_loading: true,
            }
        case 'FORGOT_PASSWORD_SUC':
            return {
                ...state,
                fp_loading: false,
            }
        case 'FORGOT_PASSWORD_ERR':
            return {
                ...state,
                fp_loading: false,
                fp_error: action.payload,
            }
        case 'LOGOUT':
            return {
                ...state,
                logged_in: false,
            };
        default:
            return state
    }
};

export default AuthReducer;
