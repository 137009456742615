import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Input, Modal, notification, Pagination, Row, Spin, Tabs, Typography, Upload } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { toJson } from "unsplash-js/lib/unsplash";
import axios from "axios";
import { auth_headers } from "../helpers/headers";
import useCurrentWidth from "../helpers/useCurrentWidth";
import Spinner from "./Spinner";
import i18next from 'i18next';

const { TabPane } = Tabs;
const { Search } = Input;
const { Title } = Typography;

const Unsplash = require('unsplash-js').default;
const unsplash = new Unsplash({ accessKey: process.env.REACT_APP_UNSPLASH_CLIENT_ID })

const per_page = 50;

function UploadImageComponent({ selectImageEvent }) {

    const [show_modal, setShowModal] = useState(false);
    const [active_key, setActiveKey] = useState('my_files');
    const [user_images, setUserImages] = useState([]);
    const [searched_images, setSearchedImages] = useState({ results: [] });
    const [searched_keyword, setSearchedKeyword] = useState('');
    const [init_loader, setInitLoader] = useState(true);
    const [upload_loader, setUploadLoader] = useState(false);
    const [search_loader, setSearchLoader] = useState(false);
    const width = useCurrentWidth();

    // useEffect(() => getUserImages(), [])

    const openUploadImageModal = () => {
        setShowModal(true);
        // if (user_images === []) {
        getUserImages();
        // }
    }

    const closeUploadImageModal = () => {
        setShowModal(false);
    }

    const getUserImages = () => {
        setInitLoader(true);
        let url = process.env.REACT_APP_API_URL + 'media';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setUserImages(res.data.data);
                setInitLoader(false);
            })
            .catch(error => {
                setInitLoader(false);
                handleError(error);
            })
    }

    const searchKeyword = (keyword, page_number) => {
        if (keyword !== '') {
            setSearchedKeyword(keyword);
            setSearchLoader(true);
            unsplash.search.photos(keyword, page_number, per_page)
                .then(toJson)
                .then(res => {
                    res['current_page'] = page_number;
                    setSearchLoader(false);
                    setSearchedImages(res);
                })
                .catch(error => {
                    console.log(error);
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                })
        }
    }

    const uploadImage = ({ onSuccess, onError, file, onProgress }) => {
        try {
            setUploadLoader(true);
            let ext = file['name'].substr(file['name'].lastIndexOf('.') + 1);
            let url = process.env.REACT_APP_API_URL + `aws_token?ext=${ext}`;
            axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                .then(res => {
                    let presigned_url = res.data.data.url;
                    let file_name = res.data.data.file_name;
                    let new_file = new File([file], file_name, { type: file.type });
                    axios.put(presigned_url, new_file, {
                        headers: {
                            'Accept': '*/*',
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': file.type
                        }
                    })
                        .then(res => {
                            setUploadLoader(false);
                            // let image_url = 'https://mobilepages-assets.s3.amazonaws.com/' + new_file.name;
                            let og_url = process.env.REACT_APP_S3_URL + new_file.name;
                            addImageToGallery(og_url);
                            selectImageEvent(og_url);
                            closeUploadImageModal();
                            notification.success({
                                message: i18next.t("successfully_uploaded"),
                                duration: 2
                            })
                        })
                        .catch(error => {
                            setUploadLoader(false);
                            handleError(error);
                        })
                })
                .catch(error => {
                    setUploadLoader(false);
                    handleError(error);
                })
        } catch (e) {
            setUploadLoader(false);
            handleError(e);
        }
    }

    const addImageToGallery = (image_url) => {
        let url = process.env.REACT_APP_API_URL + 'media';
        let data = {
            url: image_url
        }
        axios.post(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                notification.success({
                    message: i18next.t("image_added_to_gallery"),
                    duration: 2
                })
            })
            .catch(error => {
                console.log(error.response);
            })

    }

    const handleError = (error) => {
        if (error.response.status === 422 && error.response.data['errors']) {
            let field = Object.keys(error.response.data['errors'])[0];
            notification.error({
                message: error.response.data['errors'][field],
                duration: 2
            })
        } else {
            notification.error({
                message: error.response.data.message,
                duration: 2
            })
        }
    }

    const selectImage = (image_url) => {
        selectImageEvent(image_url);
        setShowModal(false);
    }

    return (
        <>
            <Button
                className="btn"
                icon={<UploadOutlined />}
                onClick={openUploadImageModal}
            >
                {i18next.t("upload")}
            </Button>
            <Modal title={'Upload Image'} destroyOnClose visible={show_modal} width={'100vw'} style={{ top: 0, height: '100vh' }} onCancel={closeUploadImageModal}
                footer={active_key === 'my_files' ?
                    [<Row>
                        <Col span={24} align={'left'}>
                            <Upload showUploadList={false} customRequest={uploadImage}>
                                <Button
                                    type={'primary'}
                                    loading={upload_loader}
                                    icon={<UploadOutlined />}
                                >
                                    {i18next.t("upload")}
                                </Button>
                            </Upload>
                        </Col>
                    </Row>, false]
                    : false
                }>
                <Row>
                    <Col span={24}>
                        <Tabs activeKey={active_key} onChange={(key) => setActiveKey(key)}>
                            <TabPane tab={i18next.t("my_files")} key={'my_files'}>
                                <Row style={{ height: 'calc(100vh - 217px)', overflow: 'hidden auto' }}>
                                    <Col span={24}>
                                        {init_loader &&
                                            <Spinner />
                                        }
                                        {!init_loader && user_images &&
                                            <Row gutter={30} className={'image-gallery-wrapper row'}>
                                                {user_images.map((image, index) => (
                                                    <Col key={image.id} className={'image-item'} xs={12} sm={12} md={3} lg={3} xl={3}>
                                                        <div className={'thumb gallery-thumb'}>
                                                            <img src={image.url} alt={image.id} />
                                                            <div className={'action-btns'}>
                                                                <Button
                                                                    size={'small'}
                                                                    type={'primary'}
                                                                    icon={<PlusOutlined />}
                                                                    onClick={() => selectImage(image.url)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab={i18next.t("search_online")} key={'search_online'}>
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        <Row justify={'center'}>
                                            <Col span={24}>
                                                <Search placeholder={i18next.t("search_keyword")} loading={search_loader} onSearch={(event) => searchKeyword(event, 1)}
                                                    enterButton allowClear />
                                            </Col>
                                        </Row>
                                        <Divider style={{ margin: width <= 500 ? '8px 0' : '24px 0' }} />
                                        <Row style={{ height: width <= 500 ? 'calc(100vh - 263px)' : 'calc(100vh - 285px)', overflow: 'hidden auto' }}>
                                            <Col span={24}>
                                                {search_loader &&
                                                    <Spinner />
                                                }
                                                {!search_loader && searched_images['results'].length !== 0 &&
                                                    <Row justify={'center'}>
                                                        <Col span={24}>
                                                            <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}
                                                                current={searched_images['current_page']} total={searched_images['total']}
                                                                pageSize={per_page} showSizeChanger={false}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} Images`}
                                                                onChange={(page) => searchKeyword(searched_keyword, page)} responsive={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                                {!search_loader &&
                                                    <Row gutter={30} className={'image-gallery-wrapper'}>
                                                        {searched_images['results'].map((s_image, s_index) => (
                                                            <Col key={s_image['id']} className={'image-item'} xs={12} sm={12} md={3} lg={3} xl={3}>
                                                                <div className={'thumb search-thumb'}>
                                                                    <img src={s_image['urls']['small']} alt={s_image['id']} />
                                                                    <div className={'action-btns'}>
                                                                        <Button size={'small'} type={'primary'} icon={<PlusOutlined />}
                                                                            onClick={() => selectImage(s_image['urls']['full'])} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default UploadImageComponent;