import React, { useState, useEffect } from "react";
import { Avatar, Dropdown, Menu, Typography, Divider, Row, Button } from "antd";
import { AppstoreOutlined, QuestionCircleOutlined, UsergroupAddOutlined, UserOutlined, LogoutOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { history } from "../helpers/history";
import { routes } from '../helpers/routes';
import axios from 'axios';
import gravatarUrl from "gravatar-url";
import { auth_headers } from "../helpers/headers";
import logo from "../assets/img/logo/logo.svg";
import i18next from 'i18next';
import GlobalSearch from "./GlobalSearch";

// const { Header } = Layout;
const { Title } = Typography;

function NavBarComponent() {

    // const [selected_key, setSelectedKey] = useState([]);
    const dispatch = useDispatch();
    // const nav_menu = useRef();
    const [permissions, setPermissions] = useState([]);
    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])

    const logoutUser = () => {
        let url = process.env.REACT_APP_API_URL + 'logout';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('id');
                localStorage.removeItem('email');
                localStorage.removeItem('name');
                localStorage.removeItem('permissions');
                localStorage.removeItem('updated_at');
                localStorage.removeItem('is_owner');
                localStorage.removeItem('company_name');
                localStorage.removeItem('token_type');
                localStorage.removeItem('created_at');
                localStorage.removeItem('api_key');
                dispatch({ type: 'LOGOUT' });
            })
            .catch(error => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('id');
                localStorage.removeItem('email');
                localStorage.removeItem('name');
                localStorage.removeItem('permissions');
                localStorage.removeItem('updated_at');
                localStorage.removeItem('is_owner');
                localStorage.removeItem('company_name');
                localStorage.removeItem('token_type');
                localStorage.removeItem('created_at');
                localStorage.removeItem('api_key');
                dispatch({ type: 'LOGOUT' });
                console.log(error.response);
            })
    };

    history.listen((location, action) => {
        getDefaultSelectedKey(location.pathname)
    })

    const getDefaultSelectedKey = (path) => {
        for (let i = 0, len = routes.length; i < len; i++) {
            if (routes[i].path === path) {
                // setSelectedKey(routes[i].key);
                return routes[i].key;
            }
        }
        // setSelectedKey([]);
        return [];
    }

    const menu = (
        <Menu className="menu-dropdown">
            <Menu.Item key={'account'} className="menu-item">
                <Link to={'/account'}><UserOutlined />{i18next.t("my_account")}</Link>
            </Menu.Item>
            <Divider className="divider-horizontal" />
            {permissions.includes('VIEW_TEMPLATES') &&
                <Menu.Item key="templates" className="mobile-menu-item menu-item">
                    <Link to='/templates'><AppstoreOutlined />{i18next.t("templates")}</Link>
                </Menu.Item>
            }
            {permissions.includes('ADD_SUBUSERS') && localStorage.getItem('is_owner') === '1' &&
                <Menu.Item key={'sub_user'} className="menu-item">
                    <Link to={'/sub-user'}><UsergroupAddOutlined />{i18next.t("team_management")}</Link>
                </Menu.Item>
            }
            {permissions.includes('ADD_TRACKING_PIXEL') &&
                < Menu.Item key={'tracking_pixel'} className="menu-item">
                    <Link to={'/tracking-pixel'}><FileDoneOutlined />{i18next.t("tracking_pixel")}</Link>
                </Menu.Item>
            }
            <Divider className="divider-horizontal" />
            <Menu.Item key={'help'} className="menu-item">
                <a href="https://support.bluemint.io/" rel="noopener noreferrer" target="_blank"><QuestionCircleOutlined />{i18next.t("help")}</a>
            </Menu.Item>
            <Menu.Item key="sing_in" onClick={logoutUser} className="menu-item"><LogoutOutlined /><span>{i18next.t("sign_out")}</span>
            </Menu.Item>
        </Menu >
    );
    return (
        <Row className="container navbar w-100" type="flex" justify="space-between">
            <div className="logoBox">
                <Link to="/" className="logo-link">
                    <img className="logo" src={logo} alt="MobilePages logo" />
                </Link>
            </div>

            <GlobalSearch />

            <React.Fragment>
                <div className="fx fx--ai-c fx--jc-sb navbar-right">
                    {permissions.includes('VIEW_TEMPLATES') &&
                        <div className="mobile-navbar">
                            <Button type="link">
                                <Link to='/templates'>
                                    <AppstoreOutlined />
                                    {i18next.t("templates")}</Link>
                            </Button>
                        </div>
                    }
                    <div className="nav-right-style">
                        <Dropdown
                            overlay={menu}
                            placement="bottomRight"
                            trigger={"click"}
                        >
                            <div>
                                <Avatar
                                    className="headerAvatar"
                                    size="large"
                                    src={gravatarUrl(localStorage.getItem('email'), {
                                        size: 200,
                                        default: "retro",
                                        rating: "g"
                                    })}
                                />
                                <div className="arrow down"></div>
                                {/* <DownOutlined /> */}
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </React.Fragment>
        </Row>
    )
}

export default NavBarComponent;
