import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import parse from 'html-react-parser';
import { TiSortNumerically } from 'react-icons/ti';

const PreviewComponent = ({ children, ...props }) => {
    const [contentRef, setContentRef] = useState(null)
    const { page_details } = props;
    const mountNode = contentRef && contentRef.contentWindow.document.body
    const mountHead = contentRef && contentRef.contentWindow.document.head
    const contentArea = contentRef && contentRef.contentWindow.document.getElementById('block-content')
    mountNode && page_details && page_details['text_font'] && (mountNode.className = `${page_details['text_font']}`)
    mountNode && page_details && (!page_details['text_font']) && (mountNode.className = '')
    mountNode && page_details && page_details['background_color'] && (mountNode.style.background = `${page_details['background_color']}`)
    mountNode && page_details && (!page_details['background_color']) && (mountNode.style.background = null)
    mountNode && page_details && page_details['background_image'] && (mountNode.style.backgroundImage = `url(${page_details['background_image']})`)
    mountNode && page_details && (!page_details['background_image']) && (mountNode.style.backgroundImage = null)

    return (
        <iframe className="preview-iframe-component" title="preview" ref={setContentRef}>
            {mountHead &&
                createPortal(
                    [
                        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.8.2/css/bulma.min.css" />,
                        <link rel="stylesheet" href="https://assets.mobilepages.io/essentials/style.css" />,
                        <script src="https://assets.mobilepages.io/essentials/scripts.js"></script>,

                        (page_details['text_font'] === "merriweather" && <link href="https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "nunito" && <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "alegreya" && <link href="https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "muli" && <link href="https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "titillium" && <link href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "varela" && <link href="https://fonts.googleapis.com/css2?family=Varela&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "vollkorn" && <link href="https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "ibm" && <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "cairo" && <link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "biorhyme" && <link href="https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "frank" && <link href="https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;700;900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "archivo" && <link href="https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "spectral" && <link href="https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "fjallaone" && <link href="https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "roboto" && <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "montserrat" && <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "rubik" && <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "sourcesans" && <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "cardo" && <link href="https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "concert" && <link href="https://fonts.googleapis.com/css2?family=Concert+One&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "arvo" && <link href="https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "lato" && <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "ubuntu" && <link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "oswald" && <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet" />),

                        (page_details['text_font'] === "poppins" && <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />),

                        (page_details && page_details['content_area_color'] && parse(`<style>.main-content { background: ${page_details['content_area_color']} }</style>`)),
                        
                        // font awesome
                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.1/css/all.css" />,
                    ],
                    mountHead
                )
            }
            {mountNode &&
                createPortal(
                    React.Children.only(children),
                    mountNode
                )
            }
        </iframe>
    )
}

export default PreviewComponent