import React from "react";
import { Menu } from "antd";

function TextAlignPicker({textAlign, alignChange, getTextAlign}) {
    return (
        <Menu>
        {textAlign.map(item => (
            <Menu.Item onClick={() => alignChange(item)} key={item.type} className={'align-list-item ' + (getTextAlign() === item.icon ? 'selected' : null)}>
                {item.icon}
            </Menu.Item>
        ))}
    </Menu>
    )

}

export default TextAlignPicker;