import React, {useState} from "react";
import {Button, Col, Modal, Row} from "antd";

function HelpPageComponent() {

    const [show_modal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    return (
        <Row justify={'center'} style={{margin: '2rem 0'}}>

            <Col span={20}>
                <Row justify={'left'}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 18}} lg={{span: 14}}>
                        <div className={'h-block'}>
                            <h2 className={'h-block--title'}>
                                Official Facebook User Group
                            </h2>
                            <p className={'h-block--subtitle'}>
                                Get help from the thousands of other PhoneSites beginners and experts alike.
                            </p>
                            <Button type={'primary'} block>
                                <a href={'https://www.facebook.com/groups/486265881788837/'} target={'_blank'} rel={'noopener noreferrer'}>
                                    Visit User Group
                                </a>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col span={20}>
                <Row justify={'left'}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 18}} lg={{span: 14}}>
                        <div className={'h-block'}>
                            <h2 className={'h-block--title'}>
                                Break Free Academy
                            </h2>
                            <p className={'h-block--subtitle'}>
                                Here is where you access your training materials that come with your PhoneSites account.
                            </p>
                            <Button type={'primary'} block>
                                <a href={'https://www.breakfreeacademy.com/login'} target={'_blank'} rel={'noopener noreferrer'}>
                                    Access Training
                                </a>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col span={20}>
                <Row justify={'left'}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 18}} lg={{span: 14}}>
                        <div className={'h-block'}>
                            <h2 className={'h-block--title'}>
                                File a support ticket
                            </h2>
                            <p className={'h-block--subtitle'}>
                                Have a billing or technical issue? File an official support ticket here.
                            </p>
                            <Button type={'primary'} block onClick={openModal}>
                                Contact Support
                            </Button>
                            <Modal title="Help & Support" visible={show_modal} onCancel={closeModal} footer={null}>
                                <p>Contact Support Form</p>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col span={20}>
                <Row justify={'left'}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 18}} lg={{span: 14}}>
                        <div className={'h-block'}>
                            <h2 className={'h-block--title'}>
                                Schedule a telephone consultation
                            </h2>
                            <p className={'h-block--subtitle'}>
                                Want to have a meeting with a human?
                            </p>
                            <Button type={'primary'} block>
                                <a href={'https://calendly.com/phonesites-support/consultation'} target={'_blank'} rel={'noopener noreferrer'}>
                                    Schedule A Meeting
                                </a>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
}

export default HelpPageComponent;
