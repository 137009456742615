import React, { useState } from "react";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { block_icons } from "../helpers/blockTypes";
import cheerio from "cheerio";
import parse from 'html-react-parser';
import i18next from 'i18next';
var _ = require("lodash");

function VideoBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, link } = block;
    const [videoUrl, setVideoUrl] = useState(link);
    const $ = cheerio.load(html);
    const youtube = $('[data-block-type=youtube]');
    const vimeo = $('[data-block-type=vimeo]')
    let iframe;
    if (type === 'YOUTUBE') {
        iframe = $('[data-block-type=youtube] iframe');
    }
    if (type === 'VIMEO') {
        iframe = $('[data-block-type=vimeo] iframe');
    }
    const youtube_embed = 'https://www.youtube-nocookie.com/embed/';
    const vimeo_embed = 'https://player.vimeo.com/video/';
    const youtube_embed_after = '?autoplay=0&modestbranding=1&controls=1&showinfo=0&rel=0&hd=1&wmode=transparent';

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    var debouncedFn = null

    const debounceOnChange = event => {
        event.persist();
        let searchString = event.target.value;
        setVideoUrl(searchString);
        if (!debouncedFn) {
            debouncedFn = _.debounce(() => {
                urlChange(videoUrl);
            }, 300);
        }
        debouncedFn();
    };

    const urlChange = (event_url) => {
        let temp = block;
        let url = event_url;

        temp.link = url;

        if (type === 'VIMEO') {
            let match = url.match(/https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/);
            if (match) {
                iframe.attr('src', vimeo_embed + match[3]);
            } else {
                iframe.attr('src', '');
            }
            temp.html = cheerio.html(vimeo);
        }

        if (type === 'YOUTUBE') {
            let match = url.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/);
            if (match) {
                iframe.attr('src', (youtube_embed + match[2] + youtube_embed_after));
            } else {
                iframe.attr('src', youtube_embed + 'error' + youtube_embed_after);
            }
            temp.html = cheerio.html(youtube);
        }
        updateBlock(index, temp);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        className="textarea"
                        placeholder={type.toLocaleString()}
                        allowClear
                        autoSize={{minRows: 2}}
                        value={videoUrl}
                        onChange={debounceOnChange} />
                </Col>
                <Col span={24}>
                    <div className={'player-wrapper'}>
                        {parse(cheerio.html(iframe))}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default VideoBlockComponent;