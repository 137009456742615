import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { auth_headers } from "../helpers/headers";
import { Alert, Button, Col, Card, Form, Input, Modal, notification, Row, Select, Spin, Table, Tooltip, Typography } from "antd";
import { LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import useCurrentWidth from "../helpers/useCurrentWidth";
import { tracking_codes } from "../helpers/trackingCodes";
import TextArea from "antd/es/input/TextArea";
import BreadcrumbsComponent from './BreadcrumbsComponent';
import EmptyState from './EmptyState';
import Spinner from "./Spinner";
import i18next from 'i18next';

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

function TrackingPixelComponent() {

    const [init_loader, setInitLoader] = useState(false);
    const [tracking_pixels_list, setTrackingPixelsList] = useState([]);
    const [show_form_modal, setShowFormModal] = useState(false);
    const [show_delete_modal, setShowDeleteModal] = useState(false);
    const [delete_loader, setDeleteLoader] = useState(false);
    const [form_loader, setFormLoader] = useState(false);
    const [error_message, setErrorMessage] = useState(null);
    const [dropdown_selected_tc, setDropdownSelectedTC] = useState(null);
    const [selected_code, setSelectedCode] = useState(null);
    const [is_edit, setIsEdit] = useState(false);
    const [tc_types, setTCTypes] = useState(tracking_codes);
    const [tracking_pixel_form] = Form.useForm();
    const code_type_ref = useRef();
    const width = useCurrentWidth();

    useEffect(() => getTrackingPixels(), [])

    const getTrackingPixels = () => {
        setInitLoader(true);
        let url = process.env.REACT_APP_API_URL + 'tracking-pixels';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                let new_tc_types = tracking_codes;
                setTCTypes(new_tc_types);
                setDropdownSelectedTC(new_tc_types[0]);
                setTrackingPixelsList(res.data.data);
                setInitLoader(false);
            })
            .catch(error => {
                setInitLoader(false);
                console.log(error.response);
            })
    }

    const columns = [
        {
            title: i18next.t("name"),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
        },
        {
            title: i18next.t("head_code"),
            dataIndex: 'pixel_head',
            key: 'pixel_head',
            width: '40%',
        },
        {
            title: i18next.t("body_code"),
            dataIndex: 'pixel_body',
            key: 'pixel_body',
            width: '40%',
        },
        {
            title: i18next.t("actions"),
            key: 'actions',
            fixed: 'right',
            render: item => (
                <div className="action-button">
                    <Button className="btn-icon info" type="link" onClick={() => openEditTrackingPixelModal(item)}>
                        <Tooltip placement='top' title='Edit'>
                            <EditOutlined />
                        </Tooltip>
                    </Button>
                    <Button className="btn-icon danger" type="link" onClick={() => openDeleteTrackingCodeModal(item)}>
                        <Tooltip placement='top' title='Delete'>
                            <DeleteOutlined className='website-icons remove-icon' />
                        </Tooltip>
                    </Button>
                </div>
            )
        },
    ]

    const openCreateTrackingPixelModal = () => {
        setErrorMessage(null);
        tracking_pixel_form.resetFields();
        setSelectedCode(null);
        setIsEdit(false);
        setFormLoader(false);
        setShowFormModal(true);
    }

    const openEditTrackingPixelModal = (item) => {
        setErrorMessage(null);
        setDropdownSelectedTC(item.type);
        tracking_pixel_form.setFieldsValue({ name: item.name, pixel_head: item.pixel_head, pixel_body: item.pixel_body });
        setSelectedCode(item);
        setIsEdit(true);
        setFormLoader(false);
        setShowFormModal(true);
    }

    const openDeleteTrackingCodeModal = (item) => {
        setSelectedCode(item);
        setDeleteLoader(false);
        setShowDeleteModal(true);
    }

    const closeDeleteTrackingCodeModal = () => {
        setShowDeleteModal(false)
    }

    const closeTrackingPixelFormModal = () => {
        setShowFormModal(false);
    }

    const addTrackingCode = (data) => {
        setFormLoader(true);
        let url = process.env.REACT_APP_API_URL + 'tracking-pixels';
        axios.post(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setFormLoader(false);
                notification.success({
                    message: i18next.t("tracking_code_added_successfully"),
                    duration: 2
                })
                closeTrackingPixelFormModal();
                getTrackingPixels();
            })
            .catch(error => {
                setFormLoader(false);
                if (error.response.status === 422 && error.response.data.errors) {
                    let field = Object.keys(error.response.data['errors'])[0];
                    tracking_pixel_form.setFields([{ name: field, errors: error.response.data['errors'][field] }])
                } else {
                    setErrorMessage(error.response.data.message);
                }
            })
    }

    const editTrackingCode = (data) => {
        setFormLoader(true);
        let url = process.env.REACT_APP_API_URL + 'tracking-pixels/' + selected_code.id;
        axios.patch(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setFormLoader(false);
                notification.success({
                    message: i18next.t("saved_successfully"),
                    duration: 2
                })
                closeTrackingPixelFormModal();
                getTrackingPixels();
            })
            .catch(error => {
                setFormLoader(false);
                if (error.response.status === 422 && error.response.data.errors) {
                    let field = Object.keys(error.response.data['errors'])[0];
                    tracking_pixel_form.setFields([{ name: field, errors: error.response.data['errors'][field] }])
                    // setErrorMessage(error.response.data['errors'][Object.keys(error.response.data['errors'])[0]]);
                } else {
                    setErrorMessage(error.response.data.message);
                }
            })
    }

    const deleteTrackingCode = () => {
        setDeleteLoader(true);
        let url = process.env.REACT_APP_API_URL + 'tracking-pixels/' + selected_code.id;
        axios.delete(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDeleteLoader(false);
                notification.success({
                    message: i18next.t("successfully_deleted"),
                    duration: 2
                })
                closeDeleteTrackingCodeModal();
                getTrackingPixels();
            })
            .catch(error => {
                setDeleteLoader(false);
                setShowDeleteModal(false);
                if (error.response.status === 422 && error.response.data.errors) {
                    notification.error({
                        message: error.response.data['errors'][Object.keys(error.response.data['errors'])[0]],
                        duration: 2
                    })
                } else {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
            })
    }

    return (
        <div className="bodyContent-wrapper">
            <div className="container">
                <div className="breadcrumb-row">
                    <Row align={'middle'}>
                        <Col flex={'auto'} align={'left'} className="title-badge">
                            <BreadcrumbsComponent tracking_pixel={true} />
                        </Col>
                        <Col>
                            <Button
                                type={'primary'}
                                icon={<PlusOutlined />}
                                size={width > 768 ? 'large' : 'middle'}
                                onClick={openCreateTrackingPixelModal}
                            >
                                {i18next.t("add_tracking_pixel")}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="content-row">
                    <Card className="animated slideInUp white-blue-bg container-card ">

                        {init_loader ?
                            <Row>
                                <Col span={24}>
                                    <Spinner />
                                </Col>
                            </Row>
                            :
                            tracking_pixels_list && tracking_pixels_list.length !== 0 ?
                                <Table dataSource={tracking_pixels_list}
                                    columns={columns}
                                    className={'table-list'}
                                    rowkey={tr_code => tr_code.id}
                                    pagination={false}
                                    scroll={{ x: true }} />
                                :
                                <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                    <EmptyState message={i18next.t("tracking_pixel_not_found")} />
                                </div>
                        }

                        <Modal
                            title={!is_edit ? i18next.t("add_tracking_pixel_code") : i18next.t("edit_tracking_pixel_code")}
                            visible={show_form_modal} footer={null}
                            onCancel={closeTrackingPixelFormModal}
                            centered
                            width={width > 768 ? '60vw' : '100vw'}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form hideRequiredMark={'false'} name='create_website' layout="vertical" size='middle' className='website-create-form'
                                        onFinish={!is_edit ? addTrackingCode : editTrackingCode} form={tracking_pixel_form} scrollToFirstError>
                                        {error_message && <Alert type={'error'} message={error_message} style={{ marginBottom: '0.5rem' }} />}
                                        <Form.Item label={i18next.t("name")} id='name' name='name'
                                            rules={[{ required: true, message: 'Please enter tracking pixel name' }]}>
                                            <Input
                                                allowClear
                                                placeholder={i18next.t("enter_pixel_name")} />
                                        </Form.Item>
                                        <Form.Item label={i18next.t("pixel_head_code")} id='pixel_head' name='pixel_head'>
                                            <TextArea
                                                className="textarea"
                                                placeholder={i18next.t("enter_pixel_head_code")}
                                                allowClear
                                                autoSize={{ minRows: 2 }}
                                            />
                                        </Form.Item>
                                        <Form.Item label={i18next.t("pixel_body_code")} id='pixel_body' name='pixel_body'>
                                            <TextArea
                                                className="textarea"
                                                placeholder={i18next.t("enter_pixel_body_code")}
                                                allowClear
                                                autoSize={{ minRows: 2 }} />
                                        </Form.Item>

                                        <Form.Item style={{ marginTop: '1.5rem', marginBottom: '0' }}>
                                            <div className='action-buttons'>
                                                <Button
                                                    className="mr--10"
                                                    type='default'
                                                    size='middle'
                                                    htmlType='reset'
                                                    onClick={closeTrackingPixelFormModal}
                                                >
                                                    {i18next.t("cancel")}
                                                </Button>
                                                <Button
                                                    type='primary'
                                                    size='middle'
                                                    htmlType='submit'
                                                >
                                                    {form_loader && <LoadingOutlined />}
                                                    {!is_edit ? i18next.t("add_tracking_code") : i18next.t("save")}
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal>
                        {selected_code !== null &&
                            <Modal visible={show_delete_modal} title={i18next.t("confirm_delete_tracking_code")} onCancel={closeDeleteTrackingCodeModal}
                                footer={[
                                    <Button
                                        key={'delete_website'}
                                        type={'danger'}
                                        loading={delete_loader}
                                        onClick={deleteTrackingCode}
                                    >
                                        {i18next.t("delete_tracking_code")}
                                    </Button>,
                                    <Button
                                        key={'cancel'}
                                        type={'default'}
                                        onClick={closeDeleteTrackingCodeModal}
                                    >
                                        {i18next.t("cancel")}
                                    </Button>
                                ]}>
                                <Row>
                                    <Col span={24}>
                                        <p>
                                            {i18next.t("are_you_sure")}?<br />
                                            {i18next.t("the_tracking_code_will_be_deleted")}.<br />
                                            {i18next.t("this_can't_be_undone")}!
                                        </p>
                                    </Col>
                                </Row>
                            </Modal>
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default TrackingPixelComponent;
