import React, { useEffect, useState } from "react";
import { Button, Card, Col, Typography, notification, Row, Modal, Pagination } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { auth_headers } from "../helpers/headers";
import { useParams } from 'react-router';
import axios from 'axios';
import { history } from "../helpers/history";
import { v4 as uuidv4 } from 'uuid';
import defaultTemplate from '../assets/templates/Template1_black.png';
import EmptyState from './EmptyState';
import Spinner from "./Spinner";
import PageTemplateDisplayComponent from './PageTemplateDisplayComponent';
import default_template from "../assets/images/default-template.jpg";
import i18next from 'i18next';

const { Title } = Typography;

function AddNewPage({ addPage }) {
    const { website_id } = useParams();
    const [pt_loader, setPTLoader] = useState(false);
    const [page_templates, setPageTemplates] = useState(null);
    const [disable_buttons, setDisableButtons] = useState(false);
    const [page_details, setPageDetails] = useState(null);
    const [nav_block, setNavBlock] = useState(null);
    const [nav_footer_block, setNavFooterBlock] = useState(null);
    const [page_blocks, setPageBlocks] = useState(null);
    const [page_preview, setPagePreview] = useState(false);
    const [meta_info, setMetaInfo] = useState(null);

    useEffect(() => getPageTemplates(), [])

    const getPageTemplates = (page = 1) => {
        setPTLoader(true);
        let url = `${process.env.REACT_APP_API_URL}templates/pages?page=${page}`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setPTLoader(false);
                setMetaInfo(res.data.meta['pagination']);
                let data = { ...res.data.data };
                setPageTemplates(data);
            })
            .catch(error => {
                setPTLoader(false);
            })
    }

    const showPreview = (template) => {
        setNavBlock(template.nav)
        setNavFooterBlock(template.nav_footer)
        setPageBlocks(template.blocks)
        setPageDetails(template)
        setPagePreview(true)
    }

    const hidePreview = () => {
        setPagePreview(false)
        setNavBlock(null)
        setNavFooterBlock(null)
        setPageBlocks(null)
        setPageDetails(null)
    }

    const handleError = (error) => {
        setDisableButtons(false);
        if (error.response.status === 422 && error.response.data.errors) {
            let field = Object.keys(error.response.data['errors'])[0];
            notification.error({
                message: error.response.data['errors'][field],
                duration: 2
            });
        } else {
            notification.error({
                message: error.response.data.message,
                duration: 2
            })
        }
    }

    const addPageToWebsite = (template_name) => {
        setDisableButtons(true);
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages';
        let data = {
            name: template_name,
            url: null,
        };
        let key = uuidv4();
        notification.info({
            key: key,
            message: i18next.t("please_wait"),
            description: 'Adding page to your website',
            icon: <Spinner />,
            duration: 0
        })
        axios.post(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDisableButtons(false);
                notification.close(key);
                notification.success({
                    message: i18next.t("page_added_successfully"),
                    duration: 2
                })
                history.push('/' + website_id + '/edit/' + res.data.data.id);
            })
            .catch(error => {
                notification.close(key);
                handleError(error)
            })
    }

    const addTemplatePageToWebsite = (template_id) => {
        setDisableButtons(true);
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/install_template';
        let data = {
            template_id: template_id
        };
        let key = uuidv4();
        notification.info({
            key: key,
            message: i18next.t("please_wait"),
            description: 'Adding page to your website',
            icon: <Spinner />,
            duration: 0
        })
        axios.post(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDisableButtons(false);
                notification.close(key);
                notification.success({
                    message: i18next.t("page_added_successfully"),
                    duration: 2
                })
                history.push('/' + website_id + '/edit/' + res.data.data.id);
            })
            .catch(error => {
                notification.close(key);
                handleError(error)
            })
    }

    return (
        <>
            <div className="bodyContent-wrapper">
                <div className="container">
                    <Row type="flex" className="container-row" justify="space-between" align="left">
                        <Col>
                            <Title level={4} style={{ margin: 0 }}>{i18next.t("add_new_page")}</Title>
                        </Col>
                    </Row>

                    {pt_loader &&
                        <Row>
                            <Col span={24}>
                                <Spinner />
                            </Col>
                        </Row>
                    }
                    {!pt_loader &&
                        <>
                            {page_templates !== null &&
                                <div className="newPageTemplate-box">
                                    <Row className="row" gutter="30">
                                        <Col className="column" xs={24} sm={12} md={12} lg={6} key={uuidv4()}>
                                            <Card className="template-item">
                                                <div className="img-box">
                                                    <div className="img" style={{ backgroundImage: `url(${defaultTemplate})` }}></div>
                                                </div>
                                                <div className={'template-item--detail'}>
                                                    <p className={'item-name'}>
                                                        {i18next.t("default_template")}
                                                    </p>
                                                    <div className="button-box fx fx--ai-c fx--jc-sb">
                                                        <Button
                                                            className="btn"
                                                            type={'default'}
                                                            size={'middle'}
                                                            onClick={() => addPageToWebsite("Default Template")}
                                                            disabled={disable_buttons}
                                                        >
                                                            <DownloadOutlined /> {i18next.t("install")}
                                                        </Button>
                                                        <Button
                                                            className="btn btn-primary"
                                                            type={'primary'}
                                                            size={'middle'}
                                                        >
                                                            <EyeOutlined /> {i18next.t("preview")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        {Object.keys(page_templates).map(key => (
                                            <Col className="column" xs={24} sm={12} md={12} lg={6} key={page_templates[key].id}>
                                                <Card className="template-item">
                                                    <div className="img-box">
                                                        <div className="img" style={{ backgroundImage: `url(${page_templates[key] && page_templates[key].image ? page_templates[key].image : default_template})` }}></div>
                                                    </div>
                                                    <div className={'template-item--detail'}>
                                                        <p className={'item-name'}>
                                                            {page_templates[key].name}
                                                        </p>
                                                        <div className="button-box fx fx--ai-c fx--jc-sb">
                                                            <Button
                                                                className="btn"
                                                                type={'default'}
                                                                size={'middle'}
                                                                onClick={() => addTemplatePageToWebsite(page_templates[key].id)}
                                                                disabled={disable_buttons}
                                                            >
                                                                <DownloadOutlined /> {i18next.t("install")}
                                                            </Button>
                                                            <Button
                                                                className="btn btn-primary"
                                                                type={'primary'}
                                                                size={'middle'}
                                                                onClick={() => showPreview(page_templates[key])}
                                                            >
                                                                <EyeOutlined /> {i18next.t("preview")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            }
                            {page_details &&
                                <Modal
                                    visible={page_preview}
                                    onCancel={hidePreview}
                                    title={page_details.name}
                                    key={'template-detail' + page_details.id}
                                    className="previewTemplate-modal"
                                    footer={null}
                                >
                                    <div className="modalTemplate-content">
                                        <div className="templateView-container">
                                            <PageTemplateDisplayComponent nav_block={nav_block} nav_footer_block={nav_footer_block} page_details={page_details} blocks_data={page_blocks} />
                                        </div>
                                    </div>
                                </Modal>
                            }
                            {meta_info && meta_info.total > meta_info.per_page &&
                                <Row>
                                    <Col className="column" xs={24} sm={12} md={12} lg={6}>
                                        <Pagination
                                            current={meta_info.current_page ? meta_info.current_page : 1}
                                            total={meta_info.total ? meta_info.total : 0}
                                            pageSize={meta_info.per_page ? meta_info.per_page : 1}
                                            onChange={getPageTemplates} />
                                    </Col>
                                </Row>
                            }
                            {page_templates === null &&
                                <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                    <EmptyState message={i18next.t("page_not_found")} />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default AddNewPage;
