const text_sizes = [
    { name: 'Size: 1', value: '1' },
    { name: 'Size: 2', value: '2' },
    { name: 'Size: 3', value: '3' },
    { name: 'Size: 4', value: '4' },
    { name: 'Size: 5', value: '5' },
    { name: 'Size: 6', value: '6' },
]
export const header_tsizes = [
    ...text_sizes
]
export const icon_tsizes = [
    ...text_sizes
]
export const sub_header_tsizes = [
    ...text_sizes
]
export const text_tsizes = [
    { name: 'Small', value: 'small' },
    { name: 'Medium', value: 'medium' },
    { name: 'Large', value: 'large' },
]
export const image_sizes = [
    { name: 'X-Small', value: '10' },
    { name: 'Small', value: '25' },
    { name: 'Medium', value: '50' },
    { name: 'Large', value: '75' },
    { name: 'X-Large', value: '100' },
];
export const button_tsizes = [
    { name: 'Small', value: 'small' },
    { name: 'Normal', value: 'normal' },
    { name: 'Medium', value: 'medium' },
    { name: 'Large', value: 'large' },
];
export const logo_sizes = [
    { name: 'Small', value: '4rem' },
    { name: 'Medium', value: '8rem' },
    { name: 'Large', value: '16rem' },
    { name: 'X-Large', value: '32rem' },
]
export const separator_types = [
    { name: 'Solid', value: 'small' },
    { name: 'Dashed', value: 'dashed' },
    { name: 'Dotted', value: 'dotted' },
]
export const image_types = [
    { name: 'Default', value: 'default' },
    { name: 'Round', value: 'is-rounded' }
]