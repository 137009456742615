import React from 'react';
import './app.scss';
import { Route, Router, Switch, Redirect } from "react-router-dom";
import LoginComponent from "./components/LoginComponent";
import PrivateRoute from "./components/PrivateRoute";
import { history } from "./helpers/history";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";
import TemplateListComponent from "./components/TemplateListComponent";
import WebSiteDetailComponent from "./components/WebSiteDetailComponent";
import PageDetailComponent from "./components/PageDetailComponent";
import HomeComponent from "./components/HomeComponent";
import PrivacyPolicyPageComponent from "./components/PrivacyPolicyPageComponent";
import SharePageComponent from "./components/SharePageComponent";
import TermOfServicePageComponent from "./components/TermOfServicePageComponent";
import HelpPageComponent from "./components/HelpPageComponent";
import TrackingPixelComponent from "./components/TrackingPixelComponent";
import SubUserComponent from "./components/SubUserComponent";
import AccountsComponent from "./components/AccountsComponent";
import AddNewPage from "./components/AddNewPage";
import i18next from 'i18next';
import './utilities/i18n';

function App() {

    let language = localStorage.getItem('language');
    if (language) {
        i18next.changeLanguage(language);
    }

    return (
        <div className="App">

            <Router history={history}>
                <div className="mainContainer main-section height-primary">
                    <Switch>
                        <PrivateRoute exact path={'/'} component={HomeComponent} />
                        <PrivateRoute path={'/sub-user'} component={SubUserComponent} />
                        <PrivateRoute path={'/templates'} component={TemplateListComponent} />
                        <PrivateRoute path={'/help'} component={HelpPageComponent} />
                        <PrivateRoute path={'/tracking-pixel'} component={TrackingPixelComponent} />
                        <PrivateRoute path={'/account'} component={AccountsComponent} />
                        <PrivateRoute path={'/websites/:website_id/:tab_key?'} component={WebSiteDetailComponent} />
                        <PrivateRoute path={'/:website_id/edit/:page_id/:tab_key?'} component={PageDetailComponent} />
                        <PrivateRoute path={'/legal'} component={PrivacyPolicyPageComponent} />
                        <PrivateRoute path={'/tos'} component={TermOfServicePageComponent} />
                        <PrivateRoute path={'/:website_id/add-new-page'} component={AddNewPage} />
                        <PrivateRoute path={'/share/:website_id'} component={SharePageComponent} />
                        <Route path={'/forgot-password'} component={ForgotPasswordComponent} />
                        <Route path={'/login'} component={LoginComponent} />
                        <Redirect exact from='*' to='/'/>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;
