import React, { useState, useEffect } from "react";
import { Modal, Select, notification } from "antd";
import useCurrentWidth from "../helpers/useCurrentWidth";
import { auth_headers } from "../helpers/headers";
import axios from 'axios';
import sanitizeHtml from 'sanitize-html';
import WebsitePreviewComponent from './WebsitePreviewComponent';
import i18next from 'i18next';

const { Option } = Select;

const allowed_tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre']

function TemplateDetailComponent({ show_modal, closeModal, template_id, modal_loading }) {

    const width = useCurrentWidth();

    const [template_details, setTemplateDetails] = useState(null);
    const [page_details, setPageDetails] = useState(null);

    const getTemplateDetails = () => {
        modal_loading(true);
        let url = `${process.env.REACT_APP_API_URL}templates/${template_id}/pages`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    if (res.data.data.pages[0]) {
                        setPageDetails(res.data.data.pages[0])
                    }
                    setTemplateDetails(res.data.data);
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
                modal_loading(false);

            })
            .catch(error => {
                modal_loading(false);
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    useEffect(() => getTemplateDetails(), [])

    return (
        <>
            {template_details &&
                <Modal
                    visible={show_modal}
                    onCancel={closeModal}
                    title={template_details.name}
                    key={'template-detail' + template_details.id}
                    width={width > 768 ? '100vw' : '90vw'}
                    className="previewTemplate-modal"
                    footer={null}
                >
                    <WebsitePreviewComponent 
                    website={template_details}
                    page_details = {page_details}
                    setPageDetails = {setPageDetails}/>
                </Modal>
            }
        </>
    )
}

export default TemplateDetailComponent;
