import React, { useEffect, useState } from "react";
import { Select, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import i18next from 'i18next';
import axios from 'axios';
import { auth_headers } from "../helpers/headers";

const { Option, OptGroup } = Select;

function GlobalSearch() {

    const [search_query, setSearchQuery] = useState(null);
    const [search_results, setSearchResults] = useState({});
    const [loading, setSearchLoading] = useState(false);

    const search = (text) => {
        setSearchLoading(true);
        text = text.trim()
        setSearchQuery(text)
        if(text && text !== search_query){
            let url = `${process.env.REACT_APP_API_URL}search?q=${text}`;
            axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                .then(res => {
                    if (res && res.data && res.data.data) {
                        setSearchResults(res.data.data);
                    }
                    else {
                        notification.error({
                            message: i18next.t("something_went_wrong"),
                            duration: 2
                        })
                    }
                    setSearchLoading(false);
                })
                .catch(error => {
                    setSearchLoading(false);
                    let error_message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Oops! Something went wrong.";
                    notification.error({
                        message: error_message,
                        duration: 2
                    })
                })
        }
        else {
            setSearchResults([])
        }
    }

    const clearSearch = () => {
        setSearchResults({});
    }

    return (
        <>
            <div className="searchBox">
                <Select
                    className="search"
                    showArrow={'false'}
                    showSearch={'true'}
                    allowClear
                    loading={loading}
                    value={[]}
                    onSearch={search}
                    suffixIcon={<SearchOutlined />}
                    placeholder={i18next.t("search")}
                    onBlur={clearSearch}
                    notFoundContent={null}
                >
                {search_results && 
                    Object.keys(search_results).map(type => (
                        <OptGroup label={type.toUpperCase()}>
                            {search_results[type].map((data,key) => (
                                <Option value={"websites"+data.name} key={data.id}>{data.name}</Option>
                            ))}
                        </OptGroup>
                    ))
                }
                </Select>
            </div>
        </>
    )
}

export default GlobalSearch;