import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Dropdown, Form, Input, notification, Row, Select, Switch, Typography, Tooltip } from "antd";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import transparent_bg from '../assets/images/transparent-bg.jpg';
import TextArea from "antd/es/input/TextArea";
import { SketchPicker } from "react-color";
import { FaRegSave } from "react-icons/all";
import axios from "axios";
import { auth_headers } from "../helpers/headers";
import { useParams } from "react-router";
import UploadImageComponent from "./UploadImageComponent";
import Spinner from "./Spinner";
import i18next from 'i18next';

const { Title } = Typography;
const { Option } = Select;

const PageSettingsComponent = ({ page_details, website_detail, website_pages, updatePageDetailsEvent, saveDetailEvent, error }) => {

    const { website_id, page_id } = useParams();
    const [tc_loader, setTCLoader] = useState(false);
    const [saving_meta_info, setSavingMetaInfo] = useState(false);
    const { primary_loading } = useSelector(state => state.page_details);
    const [tracking_codes, setTrackingCodes] = useState(null);
    const [page_detail_form] = Form.useForm();
    const [permissions, setPermissions] = useState([]);
    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])

    const label_layout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 4 }, lg: { span: 4 } };

    useEffect(() => getTrackingCodes(), [])

    const getTrackingCodes = () => {
        setTCLoader(true);
        let url = process.env.REACT_APP_API_URL + 'tracking-pixels';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setTrackingCodes(res.data.data)
                setTCLoader(false);
            })
            .catch(error => {
                setTCLoader(false);
                console.log(error.response);
            })
    }

    const updateDetails = (field, value) => {
        updatePageDetailsEvent(field, value);
    }

    const handleError = (error) => {
        if (error.response.status === 422 && error.response.data['errors']) {
            let field = Object.keys(error.response.data['errors'])[0];
            page_detail_form.setFields([{
                name: field,
                errors: error.response.data['errors'][field]
            }])
        } else {
            notification.error({
                message: error.response.data.message,
                duration: 2
            })
        }
    }

    useEffect(() => {
        if (!tc_loader && page_details !== null) {
            page_detail_form.setFieldsValue({
                url: page_details['url'],
                meta_title: page_details['meta_title'],
                meta_description: page_details['meta_description'],
                og_image: page_details['og_image'],
                custom_html: page_details['custom_html'],
                tracking_pixel: page_details['tracking_pixel'],
                text_font: page_details['text_font'],
                forward_data: page_details['forward_data'],
                redirect_url: page_details['redirect_url'],
            })
        }
    })

    const savePageMetaInfo = () => {
        setSavingMetaInfo(true);
        const meta_info_url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id + '/metainfo';
        let meta_info = {
            name: page_details['name'],
            url: page_details['url'],
            meta_title: page_details['meta_title'],
            meta_description: page_details['meta_description'],
            og_image: page_details['og_image'],
            custom_html: page_details['custom_html'],
            background_color: page_details['background_color'],
            content_area_color: page_details['content_area_color'],
            background_image: page_details['background_image'],
            text_color: page_details['text_color'],
            heading_font: page_details['heading_font'],
            text_font: page_details['text_font'],
            tracking_pixel: page_details['tracking_pixel'],
            forward_data: page_details['forward_data'],
            redirect_url: page_details['redirect_url'],
        }
        axios.patch(meta_info_url, meta_info, {
            headers: {
                ...auth_headers,
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        })
            .then(res => {
                setSavingMetaInfo(false);
                notification.success({
                    message: i18next.t("page_settings_saved_successfully"),
                    duration: 2
                })
                // saveDetailEvent()
            })
            .catch(error => {
                setSavingMetaInfo(false);
                handleError(error);
            })
    }

    const onRedirectChange = (event) => {
        if (event === 'external') {
            page_detail_form.setFieldsValue({
                redirect_url: ''
            })
            updateDetails('redirect_url', '')
        } else {
            page_detail_form.setFieldsValue({
                redirect_url: 'https://' + website_detail['name'] + '.' + process.env.REACT_APP_SITE_URL + '/' + event
            })
            updateDetails('redirect_url', 'https://' + website_detail['name'] + '.' + process.env.REACT_APP_SITE_URL + '/' + event)
        }
    }

    return (
        <>
            {primary_loading &&
                <Row>
                    <Col span={24}>
                        <Spinner />
                    </Col>
                </Row>
            }
            {!primary_loading && page_details &&
                <>
                    <div className="settingsForm--content">
                        <Form labelCol={label_layout} wrapperCol={'auto'} name='page_detail' size='middle' className='website-create-form form-content'
                            onFinish={savePageMetaInfo} form={page_detail_form} scrollToFirstError labelAlign={'left'} colon={false}>
                            <Title className="form-title" level={4}>{i18next.t("main_options")}</Title>

                            <div className="form-group">
                                <Row className="row">
                                    <Col className="col w-100">
                                        <div className="block-item">
                                            <Row className={'block-item--header'}>
                                                <Col align={'left'} flex={'auto'}>
                                                    <label className="heading">{i18next.t("background")}</label>
                                                </Col>
                                                <Col align={'right'}>
                                                    <div className="action-box fx fx--ai-c">
                                                        <Tooltip placement="top" title={i18next.t("background_color")}>
                                                            <div className="backgroundColor-plates">

                                                                <Dropdown overlay={
                                                                    <SketchPicker
                                                                        color={page_details['background_color'] !== null ? page_details['background_color'] : '#f2f2f2'}
                                                                        onChange={(color) => updateDetails('background_color', 'rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                                                    />
                                                                } trigger={['click']} placement={'bottomCenter'}>
                                                                    <Button className="btn" style={{ padding: 4 }}>
                                                                        <span style={{
                                                                            background: page_details['background_color'] !== null ? page_details['background_color'] : '#f2f2f2',
                                                                            width: '1.5rem',
                                                                            height: '100%',
                                                                            borderRadius: '2px'
                                                                        }} />
                                                                    </Button>
                                                                </Dropdown>
                                                            </div>
                                                        </Tooltip>
                                                        <div className="contentArea-plates mr--10 ml--10">
                                                            <Tooltip placement="top" title={i18next.t("content_area_color")}>
                                                                <Dropdown overlay={
                                                                    <SketchPicker
                                                                        color={page_details['content_area_color'] !== null ? page_details['content_area_color'] : '#f5f9ff'}
                                                                        onChange={(color) => updateDetails('content_area_color', 'rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                                                    />
                                                                } trigger={['click']} placement={'bottomCenter'}>
                                                                    <Button className="btn" style={{ padding: 4 }}>
                                                                        <span style={{
                                                                            background: page_details['content_area_color'] !== null ? page_details['content_area_color'] : '#f5f9ff',
                                                                            width: '1.5rem',
                                                                            height: '100%',
                                                                            borderRadius: '2px'
                                                                        }} />
                                                                    </Button>
                                                                </Dropdown>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="uploadImg-btn">
                                                            <UploadImageComponent selectImageEvent={(url) => updateDetails('background_image', url)} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={'block-item--tbox'}>
                                                <Col span={24}>
                                                    <TextArea
                                                        className="textarea"
                                                        autoSize={{ minRows: 2 }}
                                                        value={page_details['background_image']}
                                                        onChange={(event) => updateDetails('background_image', event.target.value)}
                                                        placeholder={i18next.t("image(optional)")} prefix={<LinkOutlined />} allowClear
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{
                                                        padding: '2rem',
                                                        textAlign: 'center',
                                                        backgroundImage: `url(${transparent_bg})`,
                                                        border: '1px solid #d9d9d9'
                                                    }}>
                                                        <img src={page_details['background_image']} alt={page_details['background_image']}
                                                            style={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all', }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {permissions.includes('CHANGE_FONT') &&
                                <div className="form-group">
                                    <Row className="row">
                                        <Col className="col w-100">
                                            <div className="block-item">
                                                <Row className={'block-item--header'}>
                                                    <Col align={'left'} flex={'auto'}>
                                                        <label className="heading">{i18next.t("fonts")}</label>
                                                    </Col>
                                                </Row>
                                                <Row className={'block-item--tbox'}>
                                                    <Col span={24} className="w-100">
                                                        <div className="font-selectbox selectbox-box w-100">
                                                            <Form.Item id={'text_font'} name={'text_font'}>
                                                                <Select
                                                                    className="w-100 selectbox"
                                                                    value={[page_details["text_font"]]}
                                                                    // placeholder={'Select Font'}
                                                                    // allowClear
                                                                    onChange={(event) => updateDetails('text_font', event)}
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                >
                                                                    <Option className="alegreya" key={'Alegreya'} value={'alegreya'}>Alegreya</Option>
                                                                    <Option className="archivo" key={'Archivo'} value={'archivo'}>Archivo</Option>
                                                                    <Option className="arvo" key={'Arvo'} value={'arvo'}>Arvo</Option>
                                                                    <Option className="biorhyme" key={'BioRhyme'} value={'biorhyme'}>BioRhyme</Option>
                                                                    <Option className="cairo" key={'Cairo'} value={'cairo'}>Cairo</Option>
                                                                    <Option className="cardo" key={'Cardo'} value={'cardo'}>Cardo</Option>
                                                                    <Option className="concert" key={'Concert'} value={'concert'}>Concert One</Option>
                                                                    <Option className="fjallaone" key={'FjallaOne'} value={'fjallaone'}>Fjalla One</Option>
                                                                    <Option className="frank" key={'Frank'} value={'frank'}>Frank Ruhl Libre</Option>
                                                                    <Option className="ibm" key={'IBM'} value={'ibm'}>IBM Plex</Option>
                                                                    <Option className="lato" key={'Lato'} value={'lato'}>Lato</Option>
                                                                    <Option className="merriweather" key={'Merriweather'} value={'merriweather'}>Merriweather</Option>
                                                                    <Option className="montserrat" key={'Montserrat'} value={'montserrat'}>Montserrat</Option>
                                                                    <Option className="muli" key={'Muli'} value={'muli'}>Muli</Option>
                                                                    <Option className="nunito" key={'Nunito'} value={'nunito'}>Nunito</Option>
                                                                    <Option className="oswald" key={'Oswald'} value={'oswald'}>Oswald</Option>
                                                                    <Option className="poppins" key={'Poppins'} value={'poppins'}>Poppins</Option>
                                                                    <Option className="roboto" key={'Roboto'} value={'roboto'}>Roboto</Option>
                                                                    <Option className="rubik" key={'Rubik'} value={'rubik'}>Rubik</Option>
                                                                    <Option className="sourcesans" key={'SourceSans'} value={'sourcesans'}>Source Sans</Option>
                                                                    <Option className="spectral" key={'Spectral'} value={'spectral'}>Spectral</Option>
                                                                    <Option className="titillium" key={'Titillium'} value={'titillium'}>Titillium Web</Option>
                                                                    <Option className="ubuntu" key={'Ubuntu'} value={'ubuntu'}>Ubuntu</Option>
                                                                    <Option className="varela" key={'Varela'} value={'varela'}>Varela</Option>
                                                                    <Option className="vollkorn" key={'Vollkorn'} value={'vollkorn'}>Vollkorn</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }

                            <div className="form-group">
                                <Row className="row">
                                    <Col className="col w-100">
                                        <div className="block-item">
                                            <Row className={'block-item--header'}>
                                                <Col align={'left'} flex={'auto'}>
                                                    <label className="heading">{i18next.t("page_url")}</label>
                                                </Col>
                                            </Row>
                                            <Row className={'block-item--tbox'}>
                                                <Col span={24}>
                                                    <Form.Item id={'url'} name={'url'}>
                                                        <Input placeholder={i18next.t("page_name_leave_blank_for_home_page")} allowClear
                                                            addonBefore={<small>{website_detail ? website_detail.subdomain : ""}.{process.env.REACT_APP_SITE_URL}/</small>}
                                                            onKeyPress={(e) => {
                                                                const regex = new RegExp('[a-zA-Z0-9-]')
                                                                const pressed_key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                                                                if (!regex.test(pressed_key)) {
                                                                    e.preventDefault();
                                                                    return false;
                                                                }
                                                            }}
                                                            onChange={(event => updateDetails('url', event.target.value))}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {permissions.includes('ADD_CUSTOM_CSS_JS') &&
                                <div className="form-group">
                                    <Row className="row">
                                        <Col className="col w-100">
                                            <div className="block-item">
                                                <Row className={'block-item--header'}>
                                                    <Col align={'left'} flex={'auto'}>
                                                        <label className="heading">{i18next.t("custom_css_js")}</label>
                                                    </Col>
                                                </Row>
                                                <Row className={'block-item--tbox'}>
                                                    <Col span={24}>
                                                        <Form.Item id={'custom_html'} name={'custom_html'}>
                                                            <TextArea
                                                                className="textarea"
                                                                placeholder={i18next.t("custom_css_js")}
                                                                allowClear
                                                                autoSize={{ minRows: 2 }}
                                                                onChange={(event => updateDetails('custom_html', event.target.value))}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }

                            <div className="form-group">
                                <Row className="row">
                                    <Col className="col w-100">
                                        <div className="block-item">
                                            <Row className={'block-item--header'}>
                                                <Col align={'left'} flex={'auto'}>
                                                    <label className="heading">{i18next.t("title")} <span className="small">({i18next.t("meta_tag")})</span></label>
                                                </Col>
                                            </Row>
                                            <Row className={'block-item--tbox'}>
                                                <Col span={24}>
                                                    <Form.Item id={'meta_title'} name={'meta_title'}>
                                                        <Input
                                                            placeholder={i18next.t("page_title")}
                                                            allowClear
                                                            onChange={(event) => updateDetails('meta_title', event.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-group">
                                <Row className="row">
                                    <Col className="col w-100">
                                        <div className="block-item">
                                            <Row className={'block-item--header'}>
                                                <Col align={'left'} flex={'auto'}>
                                                    <label className="heading">{i18next.t("description")} <span className="small">({i18next.t("meta_tag")})</span></label>
                                                </Col>
                                            </Row>
                                            <Row className={'block-item--tbox'}>
                                                <Col span={24}>
                                                    <Form.Item id={'meta_description'} name={'meta_description'}>
                                                        <TextArea
                                                            className="textarea"
                                                            placeholder={i18next.t("page_title")}
                                                            allowClear
                                                            autoSize={{ minRows: 2 }}
                                                            onChange={(event => updateDetails('meta_description', event.target.value))}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-group">
                                <Row className="row">
                                    <Col className="col w-100">
                                        <div className="block-item">
                                            <Row className={'block-item--header'}>
                                                <Col align={'left'} flex={'auto'}>
                                                    <label className="heading">{i18next.t("meta_image")}</label>
                                                </Col>
                                                <Col align={'right'}>
                                                    <div className="uploadImg-btn">
                                                        <UploadImageComponent selectImageEvent={(url) => updateDetails('og_image', url)} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={'block-item--tbox'}>
                                                <Col span={24}>
                                                    <TextArea
                                                        className="textarea"
                                                        placeholder={i18next.t("image(recommended)")}
                                                        allowClear
                                                        autoSize={{ minRows: 2 }}
                                                        value={page_details['og_image']}
                                                        onChange={(event => updateDetails('og_image', event.target.value))}
                                                    />
                                                    <div style={{
                                                        marginTop: "1rem",
                                                        padding: '2rem',
                                                        textAlign: 'center',
                                                        backgroundImage: `url(${transparent_bg})`,
                                                        border: '1px solid #d9d9d9'
                                                    }}>
                                                        {page_details['og_image'] !== null && page_details['og_image'] !== '' &&
                                                            <img
                                                                src={page_details['og_image']}
                                                                alt={page_details['og_image']} style={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all', }}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {permissions.includes('ADD_TRACKING_PIXEL') &&
                                <div className="form-group">
                                    <Row className="row">
                                        <Col className="col w-100">
                                            <div className="block-item">
                                                <Row className={'block-item--header'}>
                                                    <Col align={'left'} flex={'auto'}>
                                                        <label className="heading">{i18next.t("tracking_pixel")}</label>
                                                    </Col>
                                                </Row>
                                                <Row className={'block-item--tbox'}>
                                                    <Col span={24} className="w-100">
                                                        <div className="trackingPixel-selectbox selectbox-box w-100">
                                                            <Form.Item id={'tracking_pixel'} name={'tracking_pixel'}>
                                                                <Select className="w-100 selectbox" mode={'multiple'} allowClear showArrow={true}
                                                                    filterOption={(input, option) =>
                                                                        option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(event) => updateDetails('tracking_pixel', event)}>
                                                                    {tracking_codes && tracking_codes.map(tc => (
                                                                        <Option value={tc.id} key={tc.id}>{tc.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }


                            <Title className="form-title second" level={4}>{i18next.t("option_for_your_users")}</Title>

                            {permissions.includes('ADD_REDIRECT') &&
                                <div className="form-group">
                                    <Row className="row">
                                        <Col className="col w-100">
                                            <div className="block-item">
                                                <Row className={'block-item--header'}>
                                                    <Col align={'left'} flex={'auto'}>
                                                        <label className="heading">{i18next.t("redirect")}</label>
                                                    </Col>
                                                </Row>
                                                <Row className={'block-item--tbox'}>
                                                    <Col span={24} className="w-100">
                                                        <div className="redirect-selectbox selectbox-box w-100">
                                                            <Select
                                                                className="w-100"
                                                                defaultValue={page_details['redirect_url'] && page_details['redirect_url'].indexOf(website_detail['name'] + '.' + process.env.REACT_APP_SITE_URL) !== -1 ? page_details['redirect_url'].split('/')[1] : 'external'}
                                                                allowClear style={{ width: '100%' }} onChange={onRedirectChange}>
                                                                {website_pages && website_detail && website_pages.map(page => (
                                                                    <>
                                                                        {page.id !== page_id &&
                                                                            <Option key={page.id} value={page.url}>{website_detail['name']}.{process.env.REACT_APP_SITE_URL}/{page.url}</Option>
                                                                        }
                                                                    </>
                                                                ))}
                                                                <Option key={'external'} value={'external'}>{i18next.t("external_url")}</Option>
                                                            </Select>
                                                        </div>
                                                        <div className="redirect-inputField mt--20">
                                                            <Form.Item id={'redirect_url'} name={'redirect_url'}>
                                                                {website_detail && page_details && page_details['redirect_url'] !== undefined &&
                                                                    <Input onChange={(event) => updateDetails('redirect_url', event.target.value)}
                                                                        disabled={page_details['redirect_url'] && page_details['redirect_url'].indexOf(website_detail['name'] + '.' + process.env.REACT_APP_SITE_URL) !== -1} />
                                                                }
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {permissions.includes('ATTACH_DATA') &&
                                <div className="form-group">
                                    <Row className="row">
                                        <Col className="col w-100">
                                            <div className="block-item">
                                                <Row className={'block-item--header'}>
                                                    <Col align={'left'} flex={'auto'}>
                                                        <label className="heading">{i18next.t("attach_data")}</label>
                                                    </Col>
                                                    <Col align={'right'}>
                                                        <div className="attachData-switchBox">
                                                            <Form.Item className="formItem" id={'forward_data'} name={'forward_data'}>
                                                                <Switch defaultChecked={page_details['forward_data'] === 1} onChange={(checked) => {
                                                                    updateDetails('forward_data', checked)
                                                                }} />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row gutter={[24, 24]} justify={'center'} align={'middle'} style={{ marginTop: '2rem' }}>
                                <Col>
                                    <Button type={'primary'} htmlType={'submit'} size={'large'}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {saving_meta_info
                                                ?
                                                <LoadingOutlined style={{ marginRight: '0.5rem' }} />
                                                :
                                                <FaRegSave style={{ marginRight: '0.5rem' }} />
                                            }
                                            {saving_meta_info ? i18next.t("saving") : i18next.t("save")}
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </>
            }
        </>
    )
}

export default PageSettingsComponent;
