import React from "react";
import {Col, Divider, Row} from "antd";

function PrivacyPolicyPageComponent() {

    return (
        <Row justify={'center'} style={{margin: '1rem 0'}}>
            <Col xs={{span: 24}} sm={{span: 22}} md={{span: 20}} lg={{span: 20}} align={'left'}>
                <div className={'pp-card'}>
                    <div className={'pp-header'}>
                        <h1>User Terms and Conditions</h1>
                        <small>Last Updated on Apr 27th, 2018</small>
                    </div>
                    <Divider/>
                    <div className={'pp-body'}>
                        <p>This disclaimer applies to software from LeadTraps, LLC("LeadTraps") that is still in testing phase but utilizable by experienced users. This software has not been
                            released for sale, distribution or usage for the general public and will be referred to as beta software from here on.</p>

                        <p>By downloading and/or using beta software from LeadTraps, you are considered to have read this disclaimer and agreed to its terms.</p>

                        <p>The beta software is provided "as is" without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability
                            and fitness for a particular purpose. The entire risk as to the quality and performance of the beta software is with you. Should it prove defective, you assume the cost
                            of all necessary servicing, repair or correction.</p>

                        <p>It is therefore up to you to take adequate precaution against possible damages resulting from this beta software. Software in beta testing should for example not be used
                            on sensitive and/or valuable data.</p>

                        <p>Usage of the beta software found on this site is at your own risk and may void warranty on your product and/or device. If you are in any doubt of your capabilities to
                            make such a decision, please do not download and/or use beta software from LEADTRAPS.</p>

                        <p>In no event will LEADTRAPS be liable to you for damages, including any general, special, incidental or consequential damages arising out of the use or inability to use
                            the beta software (including but not limited to loss of data or data being rendered inaccurate or losses sustained by you or third parties or a failure of the software
                            to operate with any other programs), even if LEADTRAPS has been advised of the possibility of such damages.</p>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default PrivacyPolicyPageComponent;
