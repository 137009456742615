const initial_state = {
    is_loading: false,
    deleting_website: false,
    websites: [],
    pagination: null
};

const WebSiteReducer = (state = initial_state, action) => {
    switch (action.type) {
        case 'GET_WEBSITES_REQUEST':
            return {
                ...state,
                is_loading: true,
            }
        case 'GET_WEBSITES_SUC':
            return {
                ...state,
                is_loading: false,
                websites: action.payload,
                pagination: action.pagination
            }
        case 'GET_WEBSITES_ERR':
            return {
                ...state,
                is_loading: false,
            }
        case 'DELETE_WEBSITE_REQUEST':
            return {
                ...state,
                deleting_website: true
            };
        case 'DELETE_WEBSITE_SUCCESS':
            return {
                ...state,
                deleting_website: false
            }

        case 'DELETE_WEBSITE_ERROR':
            return {
                ...state,
                deleting_website: false
            }
        default:
            return state;
    }
};

export default WebSiteReducer;
