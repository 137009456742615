import React from "react";
import moment from "moment";
import {FaCogs, FaEdit, FaChartLine, FaTrashAlt} from "react-icons/fa";

const initial_state = {getting_loading: false, pages: [], adding_page_loader: false};
const actions = [
    {text: 'design', icon: <FaEdit/>},
    {text: 'settings', icon: <FaCogs/>},
    {text: 'stats', icon: <FaChartLine/>},
    {text: 'remove', icon: <FaTrashAlt/>},
];
const data = [
    {
        key: 'Fy2MmyZNdPrgzds4usDT',
        data: [
            {id: '1', name: 'login page', edited: moment("16-04-2020", "DD-MM-YYYY"), url: 'extension.phonesites.com/xgovvmimbsbs', actions: actions},
            {id: '2', name: 'dashboard page', edited: moment("15-04-2020", "DD-MM-YYYY"), url: 'eact-redux.js.org/api/hooks', actions: actions},
            {id: '3', name: 'error show page', edited: moment("15-03-2020", "DD-MM-YYYY"), url: 'anime/monogatari?page=1', actions: actions},
            {id: '4', name: 'temp forms', edited: moment("15-12-2019", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '5', name: 'temp forms', edited: moment("01-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '6', name: 'temp forms', edited: moment("01-02-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
        ]
    },
    {
        key: '5GNMupSdiBg7lWzcVDYz',
        data: [
            {id: '7', name: 'temp forms', edited: moment("30-01-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '8', name: 'temp forms', edited: moment("27-02-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '9', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '10', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
        ]
    },
    {
        key: 3,
        data: [
            {id: '11', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '12', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '13', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '14', name: 'login page', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'extension.phonesites.com/xgovvmimbsbs', actions: actions},
            {id: '15', name: 'dashboard page', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'eact-redux.js.org/api/hooks', actions: actions},
            {id: '16', name: 'error show page', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'anime/monogatari?page=1', actions: actions},
            {id: '17', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
            {id: '18', name: 'temp forms', edited: moment("30-03-2020", "DD-MM-YYYY"), url: 'KbGrbq?editors=0010', actions: actions},
        ]
    },
    {
        key: 4,
        data: [
            {id: '19', name: 'login page', edited: moment("01-04-2020", "DD-MM-YYYY"), url: 'extension.phonesites.com/xgovvmimbsbs', actions: actions},
            {id: '20', name: 'dashboard page', edited: moment("02-04-2020", "DD-MM-YYYY"), url: 'eact-redux.js.org/api/hooks', actions: actions},
        ]
    }
];

const WebSitePagesReducer = (state = initial_state, action) => {
    switch (action.type) {
        case 'GET_WEBSITE_DETAILS_REQ':
            return {
                ...state,
                getting_loading: true
            }
        case 'GET_WEBSITE_DETAILS_ERR':
            return {
                ...state,
                getting_loading: false,
                pages: []
            }
        case 'GET_WEBSITE_PAGES_REQUEST':
            return {
                ...state,
                getting_loading: true,
            }
        case 'GET_WEBSITE_PAGES_SUCCESS':
            return {
                ...state,
                getting_loading: false,
                pages: action.payload
            }
        case 'GET_WEBSITE_PAGES_ERROR':
            return {
                ...state,
                getting_loading: false,
                pages: []
            }
        case 'GET_WEBSITE_PAGES':
            let pages = [];
            for (let i = 0, len = data.length; i < len; i++) {
                if (data[i].key === action.payload) {
                    pages = data[i].data;
                    break;
                }
            }
            return pages;
        default:
            return state;
    }
};

export default WebSitePagesReducer;
