import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { FaChartLine, FaCogs, FaFile, FaUser, FaShareAlt } from "react-icons/fa";
import { Col, Card, notification, Row, Tabs, Input } from "antd";
import WebsiteConfigComponent from "./WebsiteConfigComponent";
import ShareDetailsComponent from "./ShareDetailsComponent";
import { useDispatch, useSelector } from "react-redux";
import PageListComponent from "./PageListComponent";
import { auth_headers } from "../helpers/headers";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from 'axios';
import LeadsComponent from "./LeadsComponent";
import StatsComponent from "./StatsComponent";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import i18next from 'i18next';

const { TabPane } = Tabs;
const { Search } = Input;

function WebSiteDetailComponent() {

    const { website_id, tab_key } = useParams();
    const dispatch = useDispatch();
    const [website_detail, setWebsiteDetail] = useState(null);
    const { getting_loading, pages } = useSelector(state => state.website_pages);
    const [active_key, setActiveKey] = useState('pages');
    const [saving_loader, setSavingLoader] = useState(false);
    const [config_error, setConfigError] = useState(null);

    const getWebsiteDetails = () => {
        dispatch({ type: 'GET_WEBSITE_DETAILS_REQ' })
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setWebsiteDetail(res.data.data);
                getWebsitePages();
            })
            .catch(error => {
                dispatch({ type: 'GET_WEBSITE_DETAILS_ERR' })
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    const getWebsitePages = () => {
        // dispatch({type: 'GET_WEBSITE_PAGES_REQUEST'})
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                dispatch({ type: 'GET_WEBSITE_PAGES_SUCCESS', payload: res.data.data })
            })
            .catch(error => {
                dispatch({ type: 'GET_WEBSITE_PAGES_ERROR' })
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    const onPageDelete = () => {
        getWebsiteDetails();
    }

    const updateDetailEvent = (field, value) => {
        let new_details = { ...website_detail };
        new_details[field] = value;
        setWebsiteDetail(new_details);
    }

    const saveConfig = () => {
        setSavingLoader(true);
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id;
        let data = {
            name: website_detail['name'],
            subdomain: website_detail['subdomain'],
            meta_title: website_detail['meta_title'],
            meta_description: website_detail['meta_description'],
            tracking_pixel: website_detail['tracking_pixel'],
            webhook_url: website_detail['webhook_url'],
            custom_domain: website_detail['custom_domain'],
            og_image: website_detail['og_image'],
            favicon: website_detail['favicon'],
        }
        axios.patch(url, data, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setSavingLoader(false);
                notification.success({
                    message: i18next.t("saved_successfully"),
                    duration: 2
                })
            })
            .catch(error => {
                setSavingLoader(false);
                if (error.response.status === 422 && error.response.data['errors']) {
                    let field = Object.keys(error.response.data['errors'])[0];
                    setConfigError({ field: field, error: error.response.data['errors'][field] })
                    // notification.error({
                    //     message: error.response.data['errors'][field],
                    //     duration: 2
                    // })
                } else {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
            })
    }

    useEffect(() => {
        setActiveKey(['pages', 'leads', 'config', 'stats'].indexOf(tab_key) === -1 ? 'pages' : tab_key);
        getWebsiteDetails();
    }, [])

    const onTabChange = (active_key) => {
        setActiveKey(active_key);
    }

    return (
        <div className="bodyContent-wrapper">
            <div className="container">
                <div className="breadcrumb-row">
                    <Row align={'middle'}>
                        <Col flex={'auto'} align={'left'} className="title-badge">
                            {website_detail &&
                                <BreadcrumbsComponent website_id={website_detail.id} />
                            }
                        </Col>
                        <Col>
                            {active_key === 'pages' &&
                                <Link className="ant-btn ant-btn-primary ant-btn-lg" to={`/${website_id}/add-new-page`}>
                                    <PlusOutlined /> {i18next.t("add_page")}
                                </Link>
                            }
                        </Col>
                    </Row>
                </div>
                <div className="content-row">
                    <Card
                        bordered={false}
                        className="animated slideInUp container-card websiteCard-container "
                    >
                        <Tabs className={'w-details-tabs-bar'} activeKey={active_key} onChange={onTabChange}>
                            <TabPane key={'pages'} tab={
                                <span className={'w-details-tabs'}>
                                    <FaFile className={'tab-icon'} />{i18next.t("pages")}
                                </span>}
                            >
                                <PageListComponent website_id={website_id} pages={pages} getting_loading={getting_loading}
                                    details={website_detail}
                                    onPageDelete={onPageDelete} />
                            </TabPane>
                            <TabPane key={'config'} tab={
                                <span className={'w-details-tabs'}>
                                    <FaCogs className={'tab-icon'} />{i18next.t("settings")}
                                </span>
                            }>
                                <WebsiteConfigComponent website_detail={website_detail} updateDetailEvent={updateDetailEvent}
                                    saveConfigEvent={saveConfig}
                                    saving_loader={saving_loader} error={config_error} />
                            </TabPane>
                            <TabPane key={'leads'} tab={
                                <span className={'w-details-tabs'}>
                                    <FaUser className={'tab-icon'} />{i18next.t("leads")}
                                </span>
                            }>
                                <LeadsComponent website_id={website_id} />
                            </TabPane>
                            <TabPane key={'stats'} tab={
                                <span className={'w-details-tabs'}>
                                    <FaChartLine className={'tab-icon'} />{i18next.t("trend")}
                                </span>
                            }>
                                <StatsComponent website_id={website_id} type={'website'} />
                            </TabPane>
                            <TabPane key={'share_details'} tab={
                                <span className={'w-details-tabs'}>
                                    <FaShareAlt className={'tab-icon'} />{i18next.t("share_stats")}
                                </span>
                            }>
                                <ShareDetailsComponent website_id={website_id} />
                            </TabPane>
                        </Tabs>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default WebSiteDetailComponent;
