import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Input, notification, Row } from "antd";
import $ from "jquery";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { headers } from "../helpers/headers";
import { history } from "../helpers/history";
import i18next from 'i18next';

const ForgotPasswordComponent = () => {

    const { fp_error, fp_loading } = useSelector(state => state.auth);
    const [err, setError] = useState(fp_error);
    const dispatch = useDispatch();

    useEffect(() => {
        setError(fp_error);
    }, [fp_error])

    useEffect(() => {
        if (err != null) {
            $('#fp_error_field').show('drop', null, 500);
        } else {
            $('#fp_error_field').hide();
        }
    }, [err])

    const resetPassword = (data) => {
        dispatch({ type: 'FORGOT_PASSWORD_REQ' })
        let url = process.env.REACT_APP_API_URL + 'forgot-password';
        axios.post(url, data, headers)
            .then((res => {
                dispatch({ type: 'FORGOT_PASSWORD_SUC' })
                notification.success({
                    message: i18next.t("email_sent_for_reset_password"),
                    duration: 2
                })
                history.push('/login');
            }))
            .catch(error => {
                if (error.response.status === 422 && error.response.data.errors) {
                    dispatch({ type: 'FORGOT_PASSWORD_ERR', payload: error.response.data['errors'][Object.keys(error.response.data['errors'])[0]] })
                } else {
                    dispatch({ type: 'FORGOT_PASSWORD_ERR', payload: error.response.data.message });
                }
            })
    }

    return (
        <>
            <div style={{ paddingTop: '3rem' }}>
                <Row justify='center' align='center'>
                    <Col span={24}>
                        <h1>Phone Sites</h1>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} align='left'>
                    <Form name='forgot_password_form' layout='vertical' size='large' className='login-form'
                        onFinish={resetPassword}>
                        <Form.Item label='Email' id='email' name='email'>
                            <Input type={'email'} placeholder={'Your email'} allowClear />
                        </Form.Item>
                        <Form.Item style={{ marginTop: '3rem' }}>
                            <Button type='primary' block size='large' htmlType='submit' loading={fp_loading}>
                                Reset Password
                            </Button>
                        </Form.Item>
                        <div id='fp_error_field' style={{ marginBottom: '1rem' }}>
                            <Alert message={err} type="error" showIcon />
                        </div>
                    </Form>
                </Col>
            </Row>
            <Row justify='center' align='center' className='form-link'>
                <Link to='/login'>Sign In</Link>
            </Row>
        </>
    )
}

export default ForgotPasswordComponent;
