export const tracking_codes = [
    'Facebook',
    'Twitter',
    'Google tags manager',
    'Google analytics',
    'Adwords',
    'Bing',
    'Pinterest',
    'Linkedin',
    'Quora',
    'Adroll',
    'Nexus segment',
    'AdSightPro',
    'Custom'
]
