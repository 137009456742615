import React from 'react'
import lottie from 'lottie-web';
import emptyState from "../assets/animationJson/empty-state.json";
import i18next from 'i18next';

// for lottie
let animObj = null;

export default class EmptyState extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false };
    }

    // for lottie
    componentDidMount() {
        //call the loadAnimation to start the animation
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: emptyState // the path to the animation json
        });
    }

    render() {
        let { message } = this.props
        return (
            <>
                <div className="lottie-aminationContainer">
                    <div className="lottie-amination" ref={ref => this.animBox = ref}></div>
                    <div className="message">{message ? message : i18next.t("no_data_found")}</div>
                </div>
            </>
        )
    }
}