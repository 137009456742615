import React from "react";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { block_icons } from "../helpers/blockTypes";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import sanitizeHtml from "sanitize-html";
import i18next from 'i18next';

const allowed_tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'];

function CustomHtmlBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html } = block;

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const valueChange = (event) => {
        let temp = block;
        temp.html = event.target.value;
        updateBlock(temp, index);
    }

    const parseProperHtml = () => {
        let temp = block;
        let html = sanitizeHtml(block.html, {
            allowedTags: allowed_tags,
            allowedAttributes: false,
            allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
            transformTags: {
                'div': (tagName, attribs) => divTransform(tagName, attribs)
            }
        })
        temp.html = html;
        updateBlock(temp, block);
    }

    const divTransform = (tagName, attribs) => {
        for (let attr in attribs) {
            if (attribs.hasOwnProperty(attr) && attr === 'data-block-type') {
                delete attribs[attr];
            }
        }
        return {
            tagName: tagName,
            attribs: attribs
        }
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col align={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        className="textarea"
                        placeholder={i18next.t("enter_your_html_here")}
                        allowClear
                        autoSize={{minRows: 2}}
                        value={html} onChange={valueChange} onBlur={parseProperHtml} />
                </Col>
            </Row>
        </>
    )
}

export default CustomHtmlBlockComponent;
