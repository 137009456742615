import React, { useEffect, useState } from "react";
import { Breadcrumb, Dropdown, Menu, notification } from "antd";
import axios from "axios";
import { auth_headers } from "../helpers/headers";
import useCurrentWidth from "../helpers/useCurrentWidth";
import { HomeOutlined, DownOutlined } from "@ant-design/icons";
import i18next from 'i18next';

function BreadcrumbsComponent({ website_id, page_id, tracking_pixel, team_management, my_account }) {
    const width = useCurrentWidth();
    const [breadcrumbs_codes, setBreadcrumbsCodes] = useState(null);
    const [website, setWebsite] = useState(null);
    const [page, setPage] = useState(null);

    const getBreadcrumbWebsites = () => {
        // setLoader(true);
        let breadcrumbs_url = `${process.env.REACT_APP_API_URL}breadcrumbs`;
        axios.get(breadcrumbs_url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setBreadcrumbsCodes(res.data.data);
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
            })
    }

    useEffect(() => getBreadcrumbWebsites(), [])
    useEffect(() => getWebsite())

    const WebsiteMenu = (
        <Menu key="website_menu">
            {breadcrumbs_codes &&
                breadcrumbs_codes.map((website, index) =>
                    <a href={`/websites/${website.id}`}>
                        <Menu.Item key={website.id}>
                            {website_id === website.id ? <b>{website.name}</b> : website.name}
                        </Menu.Item>
                    </a>
                )
            }
        </Menu>
    );

    const PageMenu = (
        <Menu key="page_menu">
            {website && website.pages &&
                website.pages.map((page, index) =>
                    <a href={`/${website_id}/edit/${page.id}`}>
                        <Menu.Item key={page.id}>
                            {page_id === page.id ? <b>{page.name}</b> : page.name}
                        </Menu.Item>
                    </a>
                )
            }
        </Menu>
    );

    const getWebsite = () => {
        let website = null
        if (website_id)
            website = breadcrumbs_codes ? breadcrumbs_codes.find(website => website.id === website_id) : null;
        setWebsite(website)
        if (website) {
            getPage(website.pages)
        }
    }

    const getPage = (website) => {
        let page = null
        if (page_id)
            page = website ? website.find(page => page.id === page_id) : null;
        setPage(page)
    }

    const getWebsiteName = () => {
        if (website)
            return width > 576 ? (website.name.length > 15 ? website.name.substring(0, 14) + '...' : website.name) : website.name.length > 5 ? website.name.substring(0, 6) + '...' : website.name
        return ""
    }

    const getPageName = () => {
        if (page)
            return width > 576 ? (page.name.length > 15 ? page.name.substring(0, 14) + '...' : page.name) : page.name.length > 5 ? page.name.substring(0, 6) + '...' : page.name
        return ""
    }

    return (
        <Breadcrumb className={'breadcrumb'} separator={'/'}>
            <Breadcrumb.Item key="Dashboard">
                <a href='/'>
                    {width > 576 ? i18next.t("dashboard") : <HomeOutlined />}
                </a>
            </Breadcrumb.Item>
            {website &&
                <Breadcrumb.Item key="Website">
                    <Dropdown
                        trigger={['click']}
                        overlay={WebsiteMenu}
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                        <span>
                            {getWebsiteName() &&
                                <React.Fragment>
                                    {getWebsiteName()} <DownOutlined />
                                </React.Fragment>
                            }
                        </span>
                    </Dropdown>
                </Breadcrumb.Item>
            }

            {page &&
                <Breadcrumb.Item Key="Page">
                    <Dropdown
                        trigger={['click']}
                        overlay={PageMenu}
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                        <span>
                            {getPageName() &&
                                <React.Fragment>
                                    {getPageName()} <DownOutlined />
                                </React.Fragment>
                            }
                        </span>
                    </Dropdown>
                </Breadcrumb.Item>
            }

            {tracking_pixel &&
                <Breadcrumb.Item key="Tracking_Pixel">
                    {i18next.t("tracking_pixel")}
                </Breadcrumb.Item>
            }

            {team_management &&
                <Breadcrumb.Item key="Team_Management">
                    {i18next.t("team_management")}
                </Breadcrumb.Item>
            }

            {my_account &&
                <Breadcrumb.Item key="My_Account">
                    {i18next.t("my_account")}
                </Breadcrumb.Item>
            }

        </Breadcrumb>
    )
}

export default BreadcrumbsComponent;
