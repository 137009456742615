import React, { useState } from "react";
import { Button, Col, Dropdown, Input, Menu, Row } from "antd";
import { SketchPicker } from "react-color";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { FaLink } from "react-icons/all";
import { button_tsizes } from "../helpers/textSizes";
import { block_icons } from "../helpers/blockTypes";
import cheerio from 'cheerio';
import TextSizePicker from './BlockHelper/TextSizePicker';
import i18next from 'i18next';

function ButtonBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, link, other } = block;
    let other_json = other ? JSON.parse(other) : {}

    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }
    
    let text = other_json ? other_json.text : null;
    let text_size = other_json ? other_json.text_size : null;
    let text_color = other_json ? other_json.text_color : null;
    let button_color = other_json ? other_json.button_color : null;

    const $ = cheerio.load(html);
    const button_wrapper = $('[data-block-type=button]');
    const button = $('[data-block-type=button] a');

    const getLinkText = () => {

        return text;
    }

    const [view_secondary_field, setViewSecondaryField] = useState(getLinkText() && getLinkText().length !== 0);

    const getButtonTextSize = () => {
        if(text_size)
            return text_size;
        textSizeChange(button_tsizes[0]);
    }

    const getColor = () => {
        if(text_color)
            return text_color
        changeColor('rgba(255,255,255,255)');
    }

    const getBackgroundColor = () => {
        if(button_color)
            return button_color
        changeBackgroundColor('rgba(0,0,255,255)');
    }

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const textSizeChange = (size) => {
        let temp = block;
        button_tsizes.map(item => (
            button.removeClass(`is-${item.value}`)
        ))
        button.addClass(`is-${size.value}`)
        let new_other = other_json;
        new_other.text_size = size.value
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(button_wrapper);
        updateBlock(index, temp);
    }

    const changeColor = (color) => {
        let temp = block;
        button.css('color', color);
        temp.html = cheerio.html(button_wrapper);
        let new_other = other_json;
        new_other.text_color = color
        temp.other = JSON.stringify(new_other);
        updateBlock(index, temp);
    }

    const changeBackgroundColor = (color) => {
        let temp = block;
        button.css('background', color);
        let new_other = other_json;
        new_other.button_color = color
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(button_wrapper);
        updateBlock(index, temp);
    }

    const textChange = (event) => {
        let temp = block;
        let text = event.target.value;
        button.text(text)
        let new_other = other_json;
        new_other.text = text
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(button_wrapper);
        text.length >= 1 ? setViewSecondaryField(true) : setViewSecondaryField(false);
        updateBlock(index, temp);
    }

    const linkValueChange = (event) => {
        let temp = block;
        temp.link = event.target.value;
        button.attr('href', event.target.value);
        temp.html = cheerio.html(button_wrapper);
        updateBlock(index, temp);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={<TextSizePicker text_tsizes={button_tsizes} sizeChange={textSizeChange} />} trigger={['click']}>
                            <Button className="btn">
                                {getButtonTextSize()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={
                            <SketchPicker color={getColor()}
                                onChange={(color) => changeColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                            />
                        } trigger={['click']} placement={'bottomCenter'}>
                            <Button className="btn" style={{ padding: 4 }}>
                                <span style={{ background: getColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={
                            <SketchPicker color={getBackgroundColor()}
                                onChange={(color) => changeBackgroundColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                            />
                        } trigger={['click']} placement={'bottomCenter'}>
                            <Button className="btn" style={{ padding: 4 }}>
                                <span style={{ background: getBackgroundColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        className="textarea"
                        placeholder={i18next.t("text")}
                        allowClear
                        autoSize={{minRows: 2}}
                        value={getLinkText()}
                        onChange={textChange} />
                </Col>
                {view_secondary_field && <Col span={24}>
                    <Input placeholder={i18next.t("link")} className={'link-input'} prefix={<FaLink />} allowClear
                        value={link} onChange={linkValueChange} />
                </Col>
                }
            </Row>
        </>
    )
}

export default ButtonBlockComponent;
