import React, { useState } from "react";
import { Button, Checkbox, Col, Dropdown, Menu, Row, Switch, Input, Select } from "antd";
import { SettingOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { SketchPicker } from "react-color";
import { block_icons } from "../helpers/blockTypes";
import { button_tsizes } from "../helpers/textSizes";
import cheerio from 'cheerio';
import { v4 as uuidv4 } from 'uuid';
import useCurrentWidth from "../helpers/useCurrentWidth";
import TextSizePicker from './BlockHelper/TextSizePicker';
import i18next from 'i18next';

const { Option } = Select;

function FormBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, other } = block;
    let other_json = other ? JSON.parse(other) : {}

    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }

    let form_fields = other_json ? other_json.form_fields : [];
    let other_form_fields = other_json ? other_json.other_form_fields : [];
    let button_text = other_json ? other_json.button_text : null;
    let button_text_size = other_json ? other_json.button_text_size : null;
    let button_text_size_name = other_json ? other_json.button_text_size_name : null;
    let button_text_color = other_json ? other_json.button_text_color : null;
    let button_color = other_json ? other_json.button_color : null;

    const [field_length, setFieldLength] = useState(other_form_fields ? other_form_fields.length : 0);

    const $ = cheerio.load(html);
    const form = $('[data-block-type=form]');
    const fields = form.find($('[data-field=true] input'));
    const form_fields_wrapper = $('[data-field=form-fields]');
    const button = $('[data-block-type=form] button');
    const width = useCurrentWidth();

    // const size_menu = (
    //     <Menu>
    //         {button_tsizes.map(size => (
    //             <Menu.Item onClick={() => buttonTextSizeChange(size)} key={size.name + size.value}>
    //                 <span>
    //                     {size.name}
    //                 </span>
    //             </Menu.Item>
    //         ))}
    //     </Menu>
    // );

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const getButtonTextSize = () => {
        if (button_text_size_name)
            return button_text_size_name;
        buttonTextSizeChange(button_tsizes[0]);
    }

    const getButtonColor = () => {
        if (button_text_color)
            return button_text_color;
        changeButtonColor('rgba(255,255,255,255)');
    }

    const getButtonBackgroundColor = () => {
        if (button_color)
            return button_color;
        changeBackgroundColor('rgba(0,0,255,255)');
    }

    const valueChange = (event, field_index) => {
        let temp = block;
        let text = event.target.value;
        let new_other = other_json;
        new_other.form_fields[field_index].placeholder = text
        temp.other = JSON.stringify(new_other);
        fields[field_index].attribs.placeholder = text;
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    };

    const buttonTextChange = (event) => {
        let temp = block;
        let text = event.target.value;
        button.text(text);
        let new_other = other_json;
        new_other.button_text = text
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const hiddenChange = (event, field_index) => {
        let temp = block;

        let new_other = other_json;

        if (event) {
            delete fields[field_index].parent.attribs['hidden'];
            new_other.form_fields[field_index].visible = true;
        } else {
            fields[field_index].parent.attribs['hidden'] = '';
            new_other.form_fields[field_index].visible = false;
        }
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const buttonTextSizeChange = (size) => {
        let temp = block;
        button_tsizes.map(item => (
            button.removeClass(`is-${item.value}`)
        ))
        button.addClass(`is-${size.value}`)
        let new_other = other_json;
        new_other.button_text_size = size.value;
        new_other.button_text_size_name = size.name;
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const changeButtonColor = (color) => {
        let temp = block;
        button.css('color', color);
        let new_other = other_json;
        new_other.button_text_color = color;
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const changeBackgroundColor = (color) => {
        let temp = block;
        button.css('background', color);
        let new_other = other_json;
        new_other.button_color = color;
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const changeFieldRequired = (event, field_index) => {
        let temp = block;
        let new_other = other_json;
        if (event.target.checked) {
            fields[field_index].attribs['required'] = '';
            new_other.form_fields[field_index].required = true;
        } else {
            delete fields[field_index].attribs['required'];
            new_other.form_fields[field_index].required = false;
        }
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const addCustomField = () => {
        let new_data = block;
        let new_other = other_json;
        let index = uuidv4();
        new_other.other_form_fields.push({
            placeholder: "Enter Value",
            visible: true,
            required: true,
            field_name: "field" + field_length,
            field_label: "Field " + field_length,
            type: "TEXT",
            index: index
        });
        new_data.other = JSON.stringify(new_other);
        form_fields_wrapper.append(`<div data-field-index="${index}" data-field="true" id="field${field_length}_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;"> <label class="form-label" for="field${field_length}">Field ${field_length}</label> <input class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2" placeholder="Enter Value" type="TEXT" name="field${field_length}" id="field${field_length}" maxlength="200" required=""> </div>`)
        new_data.html = cheerio.html(form);
        setFieldLength(field_length + 1)
        updateBlock(index, new_data);
    }

    const removeCustomField = (field_index) => {
        let new_data = block;
        let new_other = other_json;
        form_fields_wrapper.children($('[data-field-index=' + other_form_fields[field_index].index + ']')).remove();
        new_other.other_form_fields.splice(field_index, 1);
        new_data.other = JSON.stringify(new_other);
        new_data.html = cheerio.html(form);
        updateBlock(index, new_data);
    }

    const getFieldName = (field_index) => {
        let words = form_fields[field_index].field_name.split('_');
        let field_name = '';
        for (let i = 0, len = words.length; i < len; i++) {
            if (i === 0) {
                field_name += words[i].charAt(0).toUpperCase() + words[i].slice(1);
            } else {
                field_name += ' ' + words[i].charAt(0).toUpperCase() + words[i].slice(1);
            }
        }
        return field_name;
    }

    const getFieldPlaceholder = (field_index) => {
        return form_fields && form_fields[field_index] && form_fields[field_index].placeholder;
    }

    const getOtherFieldPlaceholder = (field_index) => {
        return other_form_fields && other_form_fields[field_index] && other_form_fields[field_index].placeholder;
    }

    const getOtherFieldName = (field_index) => {
        return other_form_fields && other_form_fields[field_index] && other_form_fields[field_index].field_name;
    }

    const getOtherFieldType = (field_index) => {
        return other_form_fields && other_form_fields[field_index] && other_form_fields[field_index].type;
    }

    const changeOtherFieldName = (event, field_index) => {
        let temp = block;
        let text = event.target.value.replace(" ", "");
        let new_other = other_json;
        new_other.other_form_fields[field_index].field_name = text
        temp.other = JSON.stringify(new_other);
        form_fields_wrapper.children($('[data-field-index=' + getOtherFieldIndex(field_index) + ']')).replaceWith(`<div data-field-index="${getOtherFieldIndex(field_index)}" data-field="true" id="${text}_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;"> <label class="form-label" for="${text}">${getOtherFieldLabel(field_index)}</label>${getInputField(getOtherFieldType(field_index), text, getOtherFieldPlaceholder(field_index), getOtherFieldRequired(field_index))}</div>`);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    };

    const getOtherFieldLabel = (field_index) => {
        return other_form_fields && other_form_fields[field_index] && other_form_fields[field_index].field_label;
    }

    const getOtherFieldRequired = (field_index) => {
        return other_form_fields && other_form_fields[field_index] && other_form_fields[field_index].required;
    }

    const getOtherFieldIndex = (field_index) => {
        return other_form_fields && other_form_fields[field_index] && other_form_fields[field_index].index;
    }
    const changeOtherFieldLabel = (event, field_index) => {
        let temp = block;
        let text = event.target.value;
        let new_other = other_json;
        new_other.other_form_fields[field_index].field_label = text
        temp.other = JSON.stringify(new_other);
        form_fields_wrapper.children($('[data-field-index=' + getOtherFieldIndex(field_index) + ']')).replaceWith(`<div data-field-index="${getOtherFieldIndex(field_index)}" data-field="true" id="${getOtherFieldName(field_index)}_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;"> <label class="form-label" for="${getOtherFieldName(field_index)}">${text}</label>${getInputField(getOtherFieldType(field_index), getOtherFieldName(field_index), getOtherFieldPlaceholder(field_index), getOtherFieldRequired(field_index))}</div>`);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    };

    const getInputField = (type, field_name, fields, required) => {
        if (['TEXT', "EMAIL", "NUMBER"].includes(type)) {
            return (`<input class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2" placeholder="${fields}" type="${type}" name="${field_name}" id="${field_name}" ${required ? "required" : ""} maxlength="200">`)
        }
        else {
            let fields_html = ""
            if (type === "DROPDOWN") {
                let fields_html = `<select class="form-input input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2" id="${field_name}" name="${field_name}">`
                fields.forEach(field_value => {
                    fields_html = fields_html + `<option value="${field_value}">${field_value}</option>`
                });
                fields_html = fields_html + "</select>"
                return fields_html;
            }
            fields.forEach(field_value => {
                fields_html = fields_html + `<input type="${type}" class="form-input-${type.toLowerCase()} input-reset ba b--black-20 fl bg-white pa3 lh-copy w-100 br2 mb2" id="${field_name}" name="${field_name}" value="${field_value}"><label style="word-break:break-all;" for="${field_value}">&nbsp;${field_value}</label><br>`
            });
            return fields_html
        }


    }

    const changeOtherFieldRequired = (event, field_index) => {
        let temp = block;
        let new_other = other_json;
        new_other.other_form_fields[field_index].required = event.target.checked;
        temp.other = JSON.stringify(new_other);
        form_fields_wrapper.children($('[data-field-index=' + getOtherFieldIndex(field_index) + ']')).replaceWith(`<div data-field-index="${getOtherFieldIndex(field_index)}" data-field="true" id="${getOtherFieldName(field_index)}_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;"> <label class="form-label" for="${getOtherFieldName(field_index)}">${getOtherFieldLabel(field_index)}</label>${getInputField(getOtherFieldType(field_index), getOtherFieldName(field_index), getOtherFieldPlaceholder(field_index), event.target.checked)}</div>`);
        temp.html = cheerio.html(form);
        updateBlock(index, temp);
    }

    const changeOtherFieldType = (value, field_index) => {
        let temp = block;
        let new_other = other_json;
        new_other.other_form_fields[field_index].type = value;
        let placeholder = ['TEXT', "EMAIL", "NUMBER"].includes(value) ? "Enter Value" : []
        new_other.other_form_fields[field_index].placeholder = placeholder;
        form_fields_wrapper.children($('[data-field-index=' + getOtherFieldIndex(field_index) + ']')).replaceWith(`<div data-field-index="${getOtherFieldIndex(field_index)}" data-field="true" id="${getOtherFieldName(field_index)}_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;"> <label class="form-label" for="${getOtherFieldName(field_index)}">${getOtherFieldLabel(field_index)}</label>${getInputField(value, getOtherFieldName(field_index), placeholder, getOtherFieldRequired(field_index))}</div>`);
        temp.html = cheerio.html(form);
        temp.other = JSON.stringify(new_other);
        updateBlock(index, temp);
    }

    const valueOtherChange = (value, field_index) => {
        let temp = block;
        let new_other = other_json;
        new_other.other_form_fields[field_index].placeholder = value;
        form_fields_wrapper.children($('[data-field-index=' + getOtherFieldIndex(field_index) + ']')).replaceWith(`<div data-field-index="${getOtherFieldIndex(field_index)}" data-field="true" id="${getOtherFieldName(field_index)}_wrap" class="relative db cf active" style="margin-bottom: 1.5rem;"> <label class="form-label" for="${getOtherFieldName(field_index)}">${getOtherFieldLabel(field_index)}</label>${getInputField(getOtherFieldType(field_index), getOtherFieldName(field_index), value, getOtherFieldRequired(field_index))}</div>`);
        temp.html = cheerio.html(form);
        temp.other = JSON.stringify(new_other);
        updateBlock(index, temp);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    {form_fields && form_fields.map((field, field_index) => (
                        <Row align={'middle'} className={'form-item'} key={'field' + field_index}>
                            <Col className="form-column">
                                <div className="form-head">
                                    <div className="label-box">
                                        <label className={'form-item--label'}>{getFieldName(field_index)}</label>
                                    </div>
                                    <div className="action-box fx fx--ai-c">
                                        <div className={'form-item--checkbox'}>
                                            {i18next.t("required")}
                                        </div>
                                        <div className={'form-item--checkbox'}>
                                            <Checkbox
                                                className="checkbox"
                                                defaultChecked={field.required}
                                                onChange={(event) => changeFieldRequired(event, field_index)} />
                                        </div>
                                        <div className={'form-item--switch'}>
                                            <Switch
                                                className="switch"
                                                defaultChecked={field.visible}
                                                size={width < 1024 ? 'small' : 'default'}
                                                onChange={(event) => hiddenChange(event, field_index)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="formField-content">
                                    <div className={'form-item--input'}>
                                        <Input
                                            className="textarea"
                                            allowClear
                                            value={getFieldPlaceholder(field_index)}
                                            onChange={(event) => valueChange(event, field_index)} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ))}
                    {other_form_fields && other_form_fields.map((field, field_index) => (
                        <Row align={'middle'} className={'form-item'} key={'field' + field_index}>
                            <Col className="form-column">
                                <div className="customField-container">
                                    <div className="deleteBtn-box">
                                        <Button className="btn delete-btn" type="primary" danger icon={<DeleteOutlined />} onClick={() => removeCustomField(field_index)}></Button>
                                    </div>
                                    <Row className="row" gutter={20}>
                                        <Col className="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="customField-box mb--20">
                                                <label className={'form-item--label'}>Field Name</label>
                                                <Input
                                                    placeholder={i18next.t("field_name")}
                                                    allowClear
                                                    value={getOtherFieldName(field_index)}
                                                    onChange={(event) => changeOtherFieldName(event, field_index)}
                                                />
                                            </div>

                                            <div className="customField-box">
                                                <Select
                                                    className="w-100 selectbox"
                                                    value={getOtherFieldType(field_index)}
                                                    onChange={(event) => changeOtherFieldType(event, field_index)}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    <Option value="TEXT" key="text">Text</Option>
                                                    <Option value="EMAIL" key="email">Email</Option>
                                                    <Option value="NUMBER" key="number">Number</Option>
                                                    <Option value="RADIO" key="radio">Radio</Option>
                                                    <Option value="CHECKBOX" key="checkbox">Checkbox</Option>
                                                    <Option value="DROPDOWN" key="dropdown">Dropdown</Option>
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col className="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="customField-box mb--20">
                                                <label className={'form-item--label'}>Field Label</label>
                                                <Input
                                                    placeholder={i18next.t("field_label")}
                                                    allowClear
                                                    value={getOtherFieldLabel(field_index)}
                                                    onChange={(event) => changeOtherFieldLabel(event, field_index)}
                                                />
                                            </div>

                                            {['TEXT', "EMAIL", "NUMBER"].includes(getOtherFieldType(field_index)) &&
                                                <div className="action-box fx fx--ai-c fx--jc-fs">
                                                    <div className={'form-item--checkbox mr--10'}>
                                                        {i18next.t("required")}
                                                    </div>
                                                    <div className={'form-item--checkbox'}>
                                                        <Checkbox
                                                            className="checkbox"
                                                            defaultChecked={getOtherFieldRequired(field_index)}
                                                            onChange={(event) => changeOtherFieldRequired(event, field_index)} />
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="row" gutter={20}>
                                        <Col className="column" xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="formField-content">
                                                <div className={'form-item--input'}>
                                                    {['TEXT', "EMAIL", "NUMBER"].includes(getOtherFieldType(field_index)) ?
                                                        (
                                                            <Input
                                                                className="textarea"
                                                                allowClear
                                                                value={getOtherFieldPlaceholder(field_index)}
                                                                onChange={(event) => valueOtherChange(event.target.value, field_index)}
                                                            />
                                                        )
                                                        :
                                                        (
                                                            <Select mode="tags" notFoundContent={null} style={{ width: '100%' }} dropdownStyle={{ display: 'none' }} placeholder="Enter options" value={getOtherFieldPlaceholder(field_index)} onChange={(value) => valueOtherChange(value, field_index)} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    ))}
                    <Row className={'block-item--tbox'}>
                        <Col span={24}>
                            <Button
                                block type={'dashed'}
                                icon={<PlusOutlined />}
                                onClick={addCustomField}
                            >
                                {i18next.t("add_field")}
                            </Button>
                        </Col>
                    </Row>
                    <Row className={'form-item'}>
                        <Col className="form-column">
                            <div className="form-head">
                                <div className="label-box">
                                    <label className={'form-item--label'}>Button</label>
                                </div>
                                <div className="action-box fx fx--ai-c">
                                    <Button.Group style={{ display: 'flex' }}>
                                        <Dropdown overlay={<TextSizePicker text_tsizes={button_tsizes} sizeChange={buttonTextSizeChange} />} trigger={['click']}>
                                            <Button style={{ width: '5rem' }}>
                                                {getButtonTextSize()}
                                            </Button>
                                        </Dropdown>
                                        <Dropdown overlay={
                                            <SketchPicker color={getButtonColor()}
                                                onChange={(color) => changeButtonColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                            />
                                        } trigger={['click']} placement={'bottomCenter'}>
                                            <Button style={{ padding: 4 }}>
                                                <span style={{ background: getButtonColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                                            </Button>
                                        </Dropdown>
                                        <Dropdown overlay={
                                            <SketchPicker color={getButtonBackgroundColor()}
                                                onChange={(color) => changeBackgroundColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                            />
                                        } trigger={['click']} placement={'bottomCenter'}>
                                            <Button style={{ padding: 4 }}>
                                                <span style={{ background: getButtonBackgroundColor(), width: '1.5rem', height: '100%', borderRadius: '2px' }} />
                                            </Button>
                                        </Dropdown>
                                    </Button.Group>
                                </div>
                            </div>
                            <div className="formField-content">
                                <TextArea
                                    className="textarea"
                                    allowClear
                                    autoSize={{ minRows: 2 }}
                                    value={button_text}
                                    onChange={buttonTextChange} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormBlockComponent;
