import React, { useEffect, useState } from "react";
import { Col, notification, Row, Table, Tooltip } from "antd";
import { auth_headers } from "../helpers/headers";
import moment from "moment";
import axios from 'axios';
import EmptyState from './EmptyState';
import Spinner from "./Spinner";
import i18next from 'i18next';

const ShareDetailsComponent = ({ website_id }) => {
    const [init_loading, setInitLoading] = useState(true);
    const [share_details, setShareDetails] = useState(null);

    useEffect(() => getSubUsers(), [])

    const getSubUsers = () => {
        setInitLoading(true);
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/sharing-details';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setShareDetails(res.data.data);
                setInitLoading(false);
            })
            .catch(error => {
                setInitLoading(false);
                notification.error({
                    message: i18next.t("oops_something_went_wrong"),
                    description: 'Please try again',
                    duration: 3
                })
            })
    }

    const columns = [
        {
            title: i18next.t("company"),
            dataIndex: 'company_name',
            key: 'company',
            width: 400
        },
        {
            title: i18next.t("user"),
            dataIndex: 'user_name',
            key: 'user',
            width: 300,
        },
        {
            title: i18next.t("installed_on"),
            dataIndex: 'created',
            key: 'created_at',
            width: 500,
            render: created_at => (
                <Tooltip
                    placement="top"
                    title={moment(created_at).format('MMMM Do YYYY, h:mm:ss a')}>
                    {moment(created_at).fromNow().charAt(0).toUpperCase() + moment(created_at).fromNow().slice(1)}
                </Tooltip>
            )
        }
    ];

    return (
        <>
            <div className="tab-content shareStats">
                <Row justify={'center'}>
                    <Col span={24}>
                        {init_loading &&
                            <Row>
                                <Col span={24}>
                                    <Spinner />
                                </Col>
                            </Row>
                        }
                        {!init_loading &&
                            <>
                                {share_details && share_details.length === 0 ?
                                    <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                        <EmptyState message={i18next.t("share_details_not_found")} />
                                    </div>
                                    :
                                    <Table dataSource={share_details}
                                        columns={columns}
                                        className={'table-list'}
                                        rowkey={detail => detail.id}
                                        pagination={false} />
                                }
                            </>
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ShareDetailsComponent;
