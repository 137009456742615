import { FaAlignCenter, FaAmilia, FaCode, FaEdit, FaExpand, FaHeading, FaLaptopCode, FaRegCalendarAlt, FaRegHandPointer, FaRegImage, FaYoutube, FiWatch, FaVimeo, FaRegLaugh, FaGripLines, FaArrowsAltV } from "react-icons/all";
import React from "react";

const blockTypes = {
    HEADLINE: { id: 1, name: 'HEADLINE', icon: <FaHeading /> },
    SUBHEADLINE: { id: 2, name: 'SUBHEADLINE', icon: <FaAmilia /> },
    TEXT: { id: 3, name: 'TEXT', icon: <FaAlignCenter /> },
    IMAGE: { id: 4, name: 'IMAGE', icon: <FaRegImage /> },
    ICONS: { id: 5, name: 'ICONS', icon: <FaRegLaugh /> },
    YOUTUBE: { id: 6, name: 'YOUTUBE', icon: <FaYoutube /> },
    VIMEO: { id: 7, name: 'VIMEO', icon: <FaVimeo /> },
    FORM: { id: 8, name: 'FORM', icon: <FaEdit /> },
    SPACER: { id: 9, name: 'SPACER', icon: <FaArrowsAltV /> },
    SEPARATOR: { id: 10, name: 'SEPARATOR', icon: <FaGripLines /> },
    BUTTON: { id: 11, name: 'BUTTON', icon: <FaRegHandPointer /> },
}

export const block_icons = {
    HEADLINE: <FaHeading />,
    SUBHEADLINE: <FaAmilia />,
    TEXT: <FaAlignCenter />,
    IMAGE: <FaRegImage />,
    YOUTUBE: <FaYoutube />,
    ICONS: <FaRegLaugh />,
    VIMEO: <FaVimeo />,
    FORM: <FaEdit />,
    SPACER: <FaArrowsAltV />,
    SEPARATOR: <FaGripLines />,
    BUTTON: <FaRegHandPointer />,
    TIMER: <FiWatch />,
    // IFRAME: <FaLaptopCode/>,
    HTML: <FaCode />,
    CALENDLY: <FaRegCalendarAlt />,
}

export default blockTypes;