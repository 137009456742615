import React, { useEffect, useState } from "react";
import { notification, Row, Col, Card, Typography, Button, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import axios from 'axios';
import { auth_headers } from "../helpers/headers";
import { useParams } from 'react-router';
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import EmptyState from './EmptyState';
import default_template from "../assets/images/default-template.jpg";
import WebsitePreviewComponent from './WebsitePreviewComponent';
import i18next from 'i18next';

const { Title } = Typography;

function SharePageComponent() {

    const { website_id } = useParams();
    const [loading, setLoadingValue] = useState(false);
    const [installing, setInstallingValue] = useState(false);
    const [website_details, setWebsiteDetails] = useState(null);
    const [page_details, setPageDetails] = useState(null);


    useEffect(() => getSharedWebsiteDetails(), [])

    const installSharedWebsite = () => {
        setInstallingValue(true);
        let url = `${process.env.REACT_APP_API_URL}install-website/${website_id}`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    notification.success({
                        message: i18next.t("successfully_installed_the_shared_site"),
                        duration: 1,
                        onClose: () => { window.location.replace(`/websites/${res.data.data.id}`) }
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 1,
                        onClose: () => { window.location.replace(`/`) }
                    })
                }
            })
            .catch(error => {
                let error_message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Oops! Something went wrong.";
                notification.error({
                    message: error_message,
                    duration: 2,
                    onClose: () => { window.location.replace(`/`) }
                })
            })
    }

    const getSharedWebsiteDetails = () => {
        setLoadingValue(true);
        let url = `${process.env.REACT_APP_API_URL}get-details/${website_id}`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setWebsiteDetails(res.data.data)
                    res.data.data.pages[0] && setPageDetails(res.data.data.pages[0]);
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2,
                    })
                }
                setLoadingValue(false);
            })
            .catch(error => {
                setLoadingValue(false);
            })
    }

    return (
        <>
            <div className="bodyContent-wrapper">
                <div className="container">
                    {loading &&
                        <Row>
                            <Col span={24}>
                                <Spinner />
                            </Col>
                        </Row>
                    }
                    {!loading &&
                        <>

                            {website_details &&
                                <>
                                    <div className="sharePageTemplate-box">
                                        <Row type="flex" className="container-row" justify="space-between" align="left">
                                            <Col>
                                                <Title level={4}>{website_details.name}</Title>
                                            </Col>
                                        </Row>
                                        <Row className="row" gutter="30">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <WebsitePreviewComponent
                                                    website={website_details}
                                                    page_details={page_details}
                                                    setPageDetails={setPageDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="row" gutter="30">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div className="actionButton-box align-center">
                                                    <Button
                                                        className="btn btn-primary install-btn text-uppercase"
                                                        type={'primary'}
                                                        size={'large'}
                                                        onClick={installSharedWebsite}
                                                        disabled={installing}
                                                    >
                                                        {i18next.t("install")}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            }
                            {!website_details &&
                                <div>
                                    <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                        <EmptyState message={i18next.t("website_not_found")} />
                                    </div>
                                    <div>
                                        <Link to="/">
                                            <Button
                                                className="btn btn-primary"
                                                type={'primary'}
                                                size={'middle'}
                                            >
                                                {i18next.t("home")}
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default SharePageComponent;
