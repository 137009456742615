import React from "react";
import {Col, Divider, Row} from "antd";

function TermOfServicePageComponent() {

    return (
        <Row justify={'center'} style={{margin: '1rem 0'}}>
            <Col xs={{span: 24}} sm={{span: 22}} md={{span: 20}} lg={{span: 20}} align={'left'}>
                <div className={'tos-card'}>
                    <div className={'tos-header'}>
                        <h1>TERMS OF SERVICE</h1>
                        <small>This document sets out your rights and responsibilities for accessing or using the PhoneSites website at www.PhoneSites.com, Break Free Academy website at
                            breakfreeacademy.com and other associated WebSites linked hereto and operated or controlled by LeadTraps, LLC or Break Free Academy, LLC. (“WebSites”)</small>
                    </div>
                    <Divider/>
                    <div className={'tos-body'}>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                Background
                            </p>
                            <p>Please read these terms carefully because all uses of the WebSites are subject to these terms of service (“Terms” or “TOS”). By using the WebSites, you are acknowledging
                                that you have read the TOS and agree to each and every term.</p>
                            <p>BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE. There are no exceptions.</p>
                            <p>You must also be at least eighteen years old to use any of the WebSites without adult supervision. If you are under 18 years of age, you may not use the WebSites or
                                purchase any product or service from us without the supervision of your parent or guardian.</p>
                            <p>We reserve the right to amend or change all or parts of this TOS from time to time. Any such amendments or changes will be posted here and shall constitute the new terms
                                and conditions for the WebSites from the time they are posted. You are tasked with the responsibility to read the TOS each time you use the WebSites.</p>
                            <p>Users of the PhoneSites software and services are subject to their respective End User License Agreement, (“EULA”) and Acceptable Use Policy, which control the use of
                                those services. To the greatest extent applicable, those agreements and policies, together with our Privacy Policy (see below), form a part of these Terms of Service
                                and apply to your use of the WebSites.</p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                A. Privacy Policy, and Other Documents
                            </p>
                            <p>Briefly, we generally collect only as much personally identifying information as is required to provide you with services for the WebSites. Our general policy is not to
                                disclose any personal nonpublic information of any visitor, customer, member, or client to any third-party, except under very limited circumstances.</p>
                            <p>We only disclose such information to improve the services or your user experience, or when we are required to disclose. We will disclose only as much as information as
                                is needed to provide the enhanced user experience or improved service. We will disclose where strictly required by law – e.g., if we are under a subpoena or court
                                order, and/or if our attorneys advise us to provide the information. While we are very protective of our user’s personal data, you agree that LeadTraps or our assignees
                                may disclose your personal information to a third-party if we, in our sole discretion, believe that the law or legal process requires it, or to protect the rights,
                                property, or safety of LeadTraps or others, or as otherwise described in our posted Privacy Policy.</p>
                            <p>Users of the Site(s) should refer to our Privacy Policy for more detailed information about how we use and collect information.</p>
                            <p>You also understand that the WebSites or portions of the WebSites are publicly available and that if you post on a public site or via social media your post (including
                                any personal information therein) may be publicly accessible via search engines and other means on the public Internet. LeadTraps, LLC and Break Free Academy, LLC
                                assume no responsibility for information that YOU make public, and we will not be liable for any harm or damages that may arise from disclosures of your personal
                                identifying information made by you or others not in our control. You should be careful and avoid posting information you do not wish to disclose on a public site or
                                via social media. If you post via social media, we do not have control over the content of your post.</p>
                            <p>For site management functions, information is collected for analysis and statistical purposes. This information is not reported or used in any manner that would reveal
                                personally identifiable information. We use log analysis tools to create summary statistics, which are used for purposes such as assessing what information is of most
                                interest, determining technical design specifications, and identifying system performance or problem areas. </p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                B. Authorized Uses of The Site(s)
                            </p>
                            <p>Your Use of the WebSites is Subject to This Limited License</p>
                            <p>PhoneSites, and all content thereon (the “Content”), is the exclusive and private property of its owner(s) unless otherwise stated. Use of the WebSites is permitted
                                under a “limited license” that provides you limited access under these Terms.</p>
                            <p>Your access can be revoked without warning if you violate these Terms or for any other reason the WebSites administrator wishes. Except as otherwise expressly provided,
                                your right to access and to use PhoneSites is personal to you. You may not share your access, username, login, or password to PhoneSites, or to any software, products,
                                or services offered via LeadTraps or Break Free Academy, LLC. You agree to keep your login credential secure, and to comply with all password security policies,
                                including password change and complexity requirements. You understand that if you are prompted to change your password, you may lose access to the WebSites, software,
                                products, or services if you fail do so.</p>
                            <p>You may use a standard web-browser (e.g. on a desktop, laptop, or similar computer, or on a portable device such as a tablet, smartphone, or other mobile device) to
                                access the WebSites, software, products, and services. All other software to access the WebSites, the Content, or our database(s) is in violation of these TOS.</p>
                            <p>Permissible uses of the WebSites include all the uses which would normally be associated with a site of this nature, and which are not expressly prohibited herein. For
                                example, our WebSites may provide videos or posts – you can view them and replay them. Some Content may include an invitation or opportunity to comment, discuss, or
                                post questions or feedback for us via the WebSites or via social media. You may also have opportunities to participate in our polls, surveys, discussion threads,
                                forums, or the like. You may have the ability to interact with and share experiences with us or other users.</p>
                            <p>You may also provide feedback or input to us directly in certain areas of the WebSites, such as where comments are permitted. You can share the WebSites with others via
                                social media; however, you may not provide any link to an internal page on the WebSites that is not public, whether or not you have a URL for such page. You may have
                                access through the WebSites to software, products, or services that you have licensed or purchased from us or subscribed to. In most cases you will have to provide your
                                login credentials to gain access.</p>
                            <p>Copying, Downloading, & Sharing</p>
                            <p>You understand and agree that the Content on the WebSites is subject to copyright laws in the US and internationally. You may only copy or download content or
                                information on the WebSites that is expressly provided for that purpose. You understand and agree that we, in our sole discretion can decide which content you may
                                download, copy, and/or share and that you will abide by our decisions and policies with regard to our content. You understand that if you do copy or reproduce any
                                information without permission, we may immediately terminate your access to the WebSites and/or to the products or services.</p>
                            <p>If a file is made available for and intended for downloading, there will be downloading instructions, which tell you that you can download it and will also indicate
                                whether you may share it. If a downloadable file does not expressly state that you may share it, then you may not do so without written permission.</p>
                            <p>Unauthorized use of the WebSites or the content is strictly prohibited.</p>
                            <p>While there are many permitted uses of the WebSites as detailed above, not all uses are permitted.</p>
                            <p>Except as expressly provided, no commercial uses are allowed of the Site(s) or any of the Content without written permission. The foregoing in no way limits the use of
                                the PhoneSites or Break Free Academy software by registered Users or licensees whose use is controlled by their respective End User License Agreement. Any use of the
                                WebSites that could in any way damage or impair the functionality for other users to any extent is prohibited.</p>
                            <p>You agree to respect our intellectual property rights and understand that you may not download or copy ANY of our Content in ANY area of the Site, except where
                                permission to do so expressly provided, or unless such information is provided on specific parts or portions of the WebSites, such as a “Downloads” or “Free Resources”
                                section.</p>
                            <p>You may not download or copy our information yourself and you may not employ any automated device, software tools, harvester, extractor, scraper, spider, robot, program,
                                code, script, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, or copy the WebSites or any portion thereof, or any Content.
                                You may not reproduce the WebSites or portions thereof in any way, nor ‘mirror’ the WebSites at a separate location or server. You may not obtain or attempt to obtain
                                any Content through any means not purposely made available through the normal use of the WebSites (e.g. using a standard web browser).</p>
                            <p>You also expressly agree not to attempt to reverse engineer, replicate, or circumvent any software, product, or service feature of the WebSites, or that is marketed
                                through the WebSites.</p>
                            <p>You also agree not to attempt to divert traffic from the WebSites by use of a confusingly similar domain name. You agree not to claim or suggest ownership or control of
                                the WebSites, nor to imply or suggest any non-existent affiliation with the WebSites. You agree not to use comment or feedback threads to post support issues,
                                complaints, or issues related to sales, or problems with access to software, products, or services including PhoneSites, Break Free Academy or others. You also agree
                                not to use such opportunities for posting comment spam, links to other software, products, or services, or attacking other users. You understand and agree that any such
                                prohibited uses may result in the loss of access to the WebSites, software, products, or services without warning or refund.</p>
                            <p>You also may not attempt to gain unauthorized access of any kind to the WebSites, or to any software, product, or service offered through the WebSites via any means
                                including hacking, password guessing, backdoors, code injection, or any other means. You also agree that you will not engage in any activities using the WebSites that
                                violate applicable laws or regulations in your locale. Such uses are strictly prohibited and include invasion of privacy laws, laws pertaining to defamation or libel,
                                or the like.</p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                C. Intellectual Property
                            </p>
                            <p>All Content (including e.g. text, graphics, video, video scripts, music, artwork, sounds and sound tracks, visual components, photographs, and computer code, as well as
                                branding, logos, and such), including but not limited to the individual design elements, selection, layout, coordination, structure, expression, and sequencing, user
                                interfaces, “look and feel,” and arrangement on the WebSites, or in the software, products, or services offered through the WebSites is owned or controlled by, or under
                                license to LeadTraps, and is protected by US and international copyright, trademark, trade dress, and various other applicable intellectual property rights, including
                                unfair competition laws.</p>
                            <p>You agree that you will not remove, replace, or obscure any copyright, trademark, service mark or other indicia of source or ownership, nor any notices or legends used
                                in connection with any Content provided on the WebSites or software, products, or services offered through the WebSites, nor with respect to anything that you may post
                                or upload to the WebSites (if any uploading is permitted) or via any software or service. Any effort to remove, replace, or obscure such marks is a violation of our
                                Terms of Service. Any copying of material not authorized by Phonesites will terminate your existing and futures use of the WebSites.</p>
                            <p>You also agree not to make any derivatives of material on the WebSites in any way. This includes removal of Trademark or Copyright insignias, rearrangement of words or
                                graphics, retitling of materials, and/or any other derivate creations of material on the Websites. Such conduct is violative of Intellectual Property laws.</p>
                            <p>The prohibition on removing copyright and similar notices applies to any downloadable files that may be provided on the WebSites, even where we give you permission to
                                share those files with others. You must share the file as we provide it and not remove our marks, notices, or legends. Third party content, if any, provided by users
                                may be copyrighted by such third parties and its use on the WebSites is with permission, or solely for discussion, commentary, and/or educational purposes, and is
                                believed to be a fair use within the meaning of federal and state law.</p>
                            <p>Trademarks</p>
                            <p>LeadTraps, PhoneSites, Break Free Academy, and other trademarks and service marks referenced herein are trademarks and/or service marks of Hardcore Closer,LLC.</p>
                            <p>Hardcore Closers marks (whether or not registered) may not be used for any reason(s) without written permission, regardless of purpose or intent. You agree not to
                                register, operate, or lease any domain with a confusingly similar name to any such mark(s) without permission from Hardcore Closer. Any other trademarks or service
                                marks used or mentioned herein, whether or not registered, are the property of their respective owners. Permission for the use of any third-party mark should be
                                obtained from the owner.</p>
                            <p>For purposes herein, discussion or commentary that may involve federally registered trademark names, or names protected under the common law is believed to be nominative
                                use that is permissible and not subject to restriction.</p>
                            <p>Product Improvements/Ideas/Feedback/Suggestions</p>
                            <p>We are always looking to improve our software, products, and services. We welcome your ideas, suggestions and comments for improvements, or new products or services. We
                                accept all unsolicited ideas, suggestions, and feedback solely on an unrestricted basis, meaning that we are not limited in how we utilize, implement, or commercialize
                                the ideas, suggestions, or feedback. Our acceptance of your voluntarily submitted ideas, suggestions, or feedback is not an acknowledgement or admission that anything
                                in the idea, suggestion, or feedback is original to you. Unless we have agreed otherwise in writing prior to your submission, you understand that we own all rights to
                                any improvement(s) or new product(s) we make, whether or not they incorporate or appear to incorporate (in whole or in part) any idea, feedback or suggestion you have
                                provided. You agree to relinquish and/or assign any right, title, or interest in such suggestion that you might otherwise retain. You agree that our review and
                                consideration of your idea, suggestion, or feedback, and/or our implementation or development thereof (in whole or part), as well as your access or continued access to
                                the software, product, or service, are adequate compensation for your assignment thereof. Do not submit any ideas, suggestions, or comments unless you understand and
                                accept the foregoing.</p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                D. External Links/Affiliate Relationships
                            </p>
                            <p>Third Party WebSites</p>
                            <p>We may provide various links to external WebSites that provide useful resources, products, or services that we believe may be useful to you, valuable for your business,
                                or just worth exploring. In some cases, we may recommend a product or service, while in other cases we may merely suggest that you check it out or consider whether that
                                product or service is useful for you.</p>
                            <p>Sometimes we’ll provide hyperlinks (or “links”) that will direct you to or connect you with other webWebSites that are not related to the WebSites.</p>
                            <p>Where such webWebSites are third party owned and operated, they are not prepared by, offered by, or controlled by us. We are not responsible in any way for the quality,
                                content, nature, applicability, or reliability of third party WebSites accessible by hyperlink from the WebSites. If you choose to leave our WebSites you should be
                                aware that our Terms of Service, Privacy Policy, and other policies no longer apply. You should review the applicable terms and policies, including privacy and
                                data-gathering practices, of whatever site you go to, whether or not you followed a link from one of our WebSites to get there.</p>
                            <p>Unless we say otherwise, our decision to provide links to external or third-party WebSites does not necessarily imply affiliation, endorsement, or adoption of those
                                WebSites, any information contained therein, or any products or services offered on the WebSites. We do not and cannot guarantee that the information or other material
                                linked is accurate, current, or available. You understand that we assume no responsibility or liability for any external links or any content on such third-party
                                WebSites, and you agree that we are not responsible whatsoever for any actions you take there, including any purchases. We likewise make no claims regarding and accept
                                no responsibility for third party WebSites that link to our WebSites.</p>
                            <p>Even where we expressly endorse a product or service (which endorsement may be based on the personal or professional opinion of one or more persons), you understand that
                                our position or opinion on the product or service is based on those factors we have considered, and that we do not have the ability to evaluate or opine on the quality,
                                price, advisability, suitability, need, or satisfaction that you may experience with such products or services. You should always do your own due diligence on any
                                product or service prior to purchasing, to determine if it is right for you, whether or not we recommend it.</p>
                            <p>Affiliate Offerings</p>
                            <p>In some cases, LeadTraps or its owners may have an affiliate relationship with a third-party site or service provider mentioned on the WebSites and may be compensated
                                (e.g. receive a valuable benefit, or even a commission) if you visit and/or purchase a product or service from the affiliated site. We will generally endeavor to
                                identify any such relationships so that you understand that even though we endorse a particular product or service, we also may be compensated in some way if you choose
                                to do business with the recommended business or site. However, you should assume that we do have such a relationship – and that we will be compensated if you purchase a
                                product or service. Where we have an affiliate relationship with such third-party WebSites, our receipt of compensation does not increase your cost to purchase the
                                product or service, and if you purchase through an affiliate link, you will generally receive valuable bonuses that you will not otherwise be entitled to receive.
                                Moreover, while many people would like us to endorse or recommend their products, we will never endorse or recommend any product or service we do not believe in, that
                                is not of good quality, or which we do not think can provide benefits for you, regardless of potential compensation. However, if the possibility of us receiving
                                compensation is not agreeable to you, please do not buy through any link on the WebSites. Our software, products and services, including those that are available
                                through the WebSites may also be offered by or through third party affiliates who are compensated by us, for example if you purchase a product or service from us. While
                                we are responsible for claims we have made regarding our software, products or services, we cannot be held responsible for any claims made by third parties. Moreover,
                                those third parties may promise the inclusion of various bonuses or additional products or services.</p>
                            <p>If those bonuses, products, or services are a part of our offer, we are responsible for delivering or providing them and will do so. If the third-party affiliate has
                                offered additional bonuses, products, or services that are not a part of our offer, you should contact them regarding delivery, or support issues for those bonuses,
                                products, or services.</p>
                            <p>We endeavor to be selective in the people or companies we allow to promote our products or services. If you believe that you have been promised bonuses, products, or
                                services from a third party claiming to be our affiliate who does not follow through or deliver the promised bonuses, products, or services, and if you have been
                                unsuccessful in resolving the issue, we would appreciate hearing about it. Problems can arise, and while we cannot take responsibility for such problems, if we see a
                                pattern of problems with an affiliate we will determine if we should allow them to promote our products or services in the future.</p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                E. Information You Provide To Us Must Be Truthful
                            </p>
                            <p>When enrolling in, subscribing to, or purchasing any service, option, or product through the WebSites, you must provide only true and accurate information, which is
                                current and complete. Your entry of any information is your promise that any name, mailing address, e-mail account, and/or credit card information you provide to us is
                                registered to you and/or your use of such information is with permission. You understand that we can and generally will bar your access to and use of the WebSites if we
                                believe that you have provided untrue, inaccurate, not current, or incomplete information. You also promise that if you are ordering or purchasing products or services
                                on behalf of a company or other entity, that you have proper authority to commit that company in such a transaction. If you are using a pseudonym, nickname, assumed
                                name or the like (where permitted), you agree that you will nonetheless provide accurate information to our processing company where required (e.g. your real name in
                                connection with a credit card account) so that you can be billed for one-time or recurring fees.</p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                F. Service and/or Product Descriptions
                            </p>
                            <p>LeadTraps attempts to be as accurate as possible in its descriptions including descriptions of services, options, and products offered or available on the Site(s). We
                                make every reasonable effort to ensure accuracy, however, we cannot guarantee that every product or service description on the Site is 100% accurate, complete,
                                reliable, and/or error-free. We do not assume any responsibility for the accuracy of any descriptions for any product or service sold by third parties using PhoneSites
                                or Break Free Academy, nor the accuracy of the description of any third-party product or service purchased via affiliate link. Except as expressly provided, your sole
                                recourse is from the third party providing the product or service, or from whom you purchased.</p>
                        </div>
                        <div className={'section'}>
                            <p className={'section-header'}>
                                G. Additional Terms
                            </p>
                            <p>This Terms of Service agreement is also governed by the provisions below. You should seek to understand these provisions and you must agree and abide by them.</p>
                            <p>No Warranties are Made</p>
                            <p>We make no warranties, expressed or implied, regarding the Content on the WebSites. No warranties or guarantees are made as to the accuracy, factual basis, timeliness,
                                applicability, or suitability of any information on the WebSites for any purpose, including your particular needs. While we have many years of high-level marketing and
                                product development experience that we share, nothing written, discussed, presented, or communicated in any way or form on the WebSites is intended as professional
                                advice of any other type and should not be considered or used as such. Consult your attorney, CPA, or other professional for help should you require it. Your use of the
                                WebSites is subject to your acceptance of any liability that may result because of actions you take or fail to take based on the Content provided here.</p>
                            <p>The WebSites, and the Content, including any information, data, case studies, and personal experiences shared are all provided on an "AS IS," and "AS AVAILABLE"
                                basis.</p>
                            <p>The WebSites, software, products, or services may not always be available to you when you would like access for reasons beyond our control. And from time to time we may
                                suspend and/or deny access to the WebSites for updates, maintenance (scheduled or unscheduled), enhancements, upgrades, improvements, or corrections, or to maintain or
                                improve security.</p>
                            <p>In addition, some information and offers provided on the WebSites are time-limited and will be removed at our discretion. We also believe some Content has a limited
                                useful life and we reserve the right to take down, remove, or archive Content at our discretion.</p>
                            <p>We also do not make any guarantees that the WebSites will be updated, changed, or amended on a particular schedule or with regularity. Despite our desire and sincere
                                efforts to keep the WebSites and Content up to date and free of errors, the Content may contain typographical or factual errors or inaccuracies or become outdated.</p>
                            <p>Our WebSites do not offer refunds. There are no contracts and you may join on a month to month or on an annual basis. There are NO warranties or guarantees made related
                                to the use of the WebSites or the Content, or for any products or services offered through the WebSites. Any guarantees or warranties that might be implied by law are
                                specifically disclaimed including but not limited to, warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>
                            <p>While we attempt to be as accurate as possible in the descriptions on the WebSites including descriptions of software, products, services, options, and bonuses offered
                                or available on the WebSites, we cannot and do not guarantee that every product or service description on the WebSites is 100% accurate, complete, reliable, and/or
                                error-free.</p>
                            <p>LeadTrap’s Liability is Strictly Limited</p>
                            <p>You understand and agree that we are not and will not be responsible for any loss or damage that you might incur as a result of using the WebSites, or (any software,
                                products, or services offered through the WebSites) whether it results from an act or an omission by us or any other party, including another user.</p>
                            <p>You agree that you cannot and are not entitled to recover, whether in contract or tort, for any direct, special, indirect, punitive, consequential or incidental damages,
                                attorney fees, or any other damages of any kind even if we knew or were advised of the possibility of such damages. The limitation on liability includes damages from
                                all causes including lost time, damage caused by viruses, spyware, adware, or other malware which may infect a user's equipment, unauthorized access, theft, operator
                                errors, strikes or other labor problems or any force majeure.</p>
                            <p>In all cases, our total liability shall be limited to liquidated damages of no more than (i) the amount you spent on any product or service offered through the WebSites
                                in the last calendar year, or (ii) $50.00, whichever is greater. Moreover, you agree that all claims must be brought within 1 year of the date on which you first knew
                                or should have known of your alleged claim, notwithstanding any state law to the contrary.</p>
                            <p>Accountability.</p>
                            <p>You agree to be held accountable for your behavior on the Site.</p>
                            <p>You agree to only post information or comments on the WebSites that you own or have proper rights to publish or post. You may not publish or post and agree not to
                                publish or post any proprietary or confidential information, trade secrets, insider information, or similar information on the WebSites, or through the use of the
                                products or services offered through the WebSites. If you are not the copyright owner of any image or other information or do not have permission from the copyright
                                owner, you agree not to publish or post that image or information on the WebSites and will indemnify us for any harm caused to us by your actions. You agree not to
                                promote any goods or services that infringe another person’s trademarks using the WebSites or via the software, products, or services offered through the WebSites.</p>
                            <p>To ensure we are not held responsible for your actions, you agree to indemnify and hold harmless Hardcore Closer, LLC, LeadTraps, LLC and Break Free Academy, LLC and its
                                subsidiaries, officers, owners, directors, employees, agents, and suppliers from any claim, action, demand, loss, or damages (including attorneys' fees) made or
                                incurred by any third-party arising out of and/or relating to your use of the WebSites, the products or services, your violation of our Terms of Service, and/or your
                                violation of any rights of a third party.</p>
                            <p>No Conflict of Interests</p>
                            <p>If you have a personal or financial interest in any site, product, or service that may influence a comment or post, or that a reasonable person would want to know when
                                reading your comment or post, you agree not comment or post information about that site, product, or service without disclosing your interest. You also agree to never
                                to post under an assumed name or use a false identity on the Site(s) for personal gain, or to avoid disclosing your interest. You agree not to post unfavorable
                                information about any competing business or service without disclosing your business interests. You also will not have another person make such comments or posts for
                                your benefit or gain.</p>
                            <p>Jurisdiction and Binding Arbitration</p>
                            <p>These Terms, as well as the Privacy Policy for the WebSites shall be governed by and construed in accordance with the laws of the State of Texas, U.S.A., without regard
                                to conflict of laws principles. You consent to the exclusive jurisdiction and venue in the courts of Dallas County, Texas, U.S.A. for any and all disputes arising out
                                of or relating to the Privacy Policy, Terms of Service and/or the WebSites. These Terms and the Privacy Policy constitute written agreements between you and LeadTraps,
                                LLC with respect to the WebSites, your access, and your conduct. The Terms and our Privacy Policy as published on the WebSites supersede any other communications and/or
                                proposals (whether oral, written, or electronic) with respect to the WebSites. A printed version of the Terms and Privacy Policy shall be admissible in a judicial or
                                administrative proceeding to the same extent and subject only to the same restrictions, as any other contract, document or record originally in printed form. Any
                                disputes arising under these Terms, LeadTrap’s Privacy Policy, or from your use of the Site(s) shall be resolved solely by confidential binding arbitration conducted in
                                accordance with the American Arbitration Association commercial arbitration rules. All arbitration shall be held in Dallas, Texas, USA, unless otherwise agreed in a
                                signed writing. Each party shall bear one half of the arbitration fees and costs incurred, and each party is responsible for its own lawyer fees, unless the
                                arbitrator(s) agree that the case was without reasonable basis in law or fact, in which case costs and attorney’s fees may be awarded to the prevailing party. All your
                                claims must be arbitrated on an individual complainant basis and cannot be consolidated in any arbitration with any claim or complaint of any other party or parties,
                                except as agreed upon in a writing signed by LeadTraps. If any provision of these Terms shall be deemed unlawful, void, or for any reason unenforceable by a Court
                                having authority to opine on the matter, then that provision shall be severable from these Terms and the validity and enforceability of any remaining provisions shall
                                remain unaffected. The provisions of this section survive any termination of the Terms.</p>
                            <p>INTERNATIONAL USE</p>
                            <p>The PhoneSites and Break Free Academy WebSites are controlled and operated by Hardcore Closer, LLC from its headquarters in the Dallas area in Texas, in the United
                                States of America. Because the WebSites are hosted on the worldwide web, access may be available in other countries. We are happy to make the Content, software,
                                products, and/or services available to people in any country where legal to do so. And while we are not currently aware of any country that bans information of this
                                type, nor specifically the information we provide, we make no representation that materials on the WebSites are appropriate or legal for use in any locations outside
                                the United States. If you have access to the WebSites from a location outside the United States of America, you and you alone are responsible for compliance with all
                                applicable laws of your jurisdiction. Accessing the WebSites from any country where the contents are deemed illegal or where they are contrary to regulations is
                                prohibited and definitely not recommended by us, as we do not fancy prisons, foreign or domestic. If you choose to access the WebSites from other locations, you do so
                                on your own initiative and at your own risk. You are responsible for compliance with local laws and/or consequences of violating such laws or regulations.</p>
                            <p>Your continued access or use of the WebSites is your acceptance of the foregoing Terms of Service in their entirety.</p>
                            <p>Please enjoy the WebSites!</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default TermOfServicePageComponent;
