import React from "react";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { block_icons } from "../helpers/blockTypes";
import { SettingOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import TextArea from "antd/es/input/TextArea";
import cheerio from 'cheerio';
import i18next from 'i18next';

function CalendlyBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, link } = block;
    const $ = cheerio.load(html);
    const calendly_wrapper = $('[data-block-type=calendly]');
    const calendly = $('[data-block-type=calendly] iframe');

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const linkChange = (event) => {
        let temp = block;
        temp.link = event.target.value;
        if (event.target.value.indexOf('https://calendly.com/') !== 0) {
            temp.html = cheerio.html('');
            updateBlock(temp, index)
        } else {
            const $ = cheerio.load('<div data-block-type="calendly" style="text-align: center"><div style="position: relative; padding-top: 125%;"><iframe style="position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0" allowfullscreen src=""></iframe></div></div>');
            const basic_calendly_wrapper = $('[data-block-type=calendly]');
            const basic_calendly = $('[data-block-type=calendly] iframe');
            basic_calendly.attr('src', event.target.value);
            temp.html = cheerio.html(basic_calendly_wrapper);
            updateBlock(temp, index);
        }
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col align={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        className="textarea"
                        placeholder={i18next.t("enter_calendly_embed_link_here")}
                        allowClear
                        autoSize={{minRows: 2}}
                        value={link} onChange={linkChange} />
                </Col>
                <Col span={24}>
                    {parse(html)}
                </Col>
            </Row>
        </>
    )
}

export default CalendlyBlockComponent;
