import React, { useEffect, useState } from "react";
import { notification, Button, Col, Form, Input, Row } from 'antd';
// import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import i18next from "i18next";
import axios from 'axios';
import { headers } from "../helpers/headers";
import { history } from "../helpers/history";

function LoginComponent() {

    const { logged_in, error, loading } = useSelector(state => state.auth);
    const [err, setError] = useState(error);

    const dispatch = useDispatch();

    useEffect(() => {
        if (logged_in) {
            history.push('/');
        }
    }, [logged_in]);

    useEffect(() => {
        setError(error);
    }, [error])

    useEffect(() => {
        if (err != null) {
            notification.error({
                message: err,
                duration: 2
            })
        }
    }, [err])

    const login = (data) => {
        let url = process.env.REACT_APP_API_URL + 'login';
        dispatch({ type: 'LOGIN_REQUEST' });
        axios.post(url, data, headers)
            .then(res => {
                for (let item in res.data.data) {
                    if (res.data.data.hasOwnProperty(item) 
                        && item !== 'api_key'
                        && item !== 'mobile_number'
                        && item !== 'language') {
                        localStorage.setItem(item, res.data.data[item])
                    }
                }
                const language = res.data.data.language ? res.data.data.language : "en";
                i18next.changeLanguage(language);
                localStorage.setItem("language", language);
                dispatch({ type: 'LOGIN_SUCCESS' })
            })
            .catch(err => {
                if (err.response.status === 422 && err.response.data.errors) {
                    dispatch({ type: 'LOGIN_FAILURE', payload: err.response.data['errors'][Object.keys(err.response.data['errors'])[0]] });
                } else {
                    dispatch({ type: 'LOGIN_FAILURE', payload: err.response.data.message });
                }
            })
    }

    return (
        <div className="login-section overflow-hidden">
            <div className="container">
                <Row
                    type="flex"
                    justify="end"
                    align="middle"
                    className="height-primary"
                >
                    <Col md={9} xs={24}>
                        <div className="card-login primary-center-shadow">
                            <h4 className="title text-uppercase mb--30">
                                {i18next.t("login")}
                            </h4>
                            <Form name='login_form' layout='vertical' size='large' onFinish={login} className='login-form'>
                                <Form.Item label={i18next.t("email")} id='email' name='email'
                                    rules={[
                                        { required: true, message: 'Please enter email' },
                                        { type: 'email', message: "Please enter valid email" }
                                    ]}>
                                    <Input allowClear />
                                </Form.Item>
                                <Form.Item label={i18next.t("password")} id='password' name='password'
                                    rules={[
                                        { required: true, message: 'Please enter password' }
                                    ]}>
                                    <Input.Password allowClear />
                                </Form.Item>
                                <Form.Item style={{ marginTop: '3rem' }}>
                                    <Button type='primary' block size='large' htmlType='submit' loading={loading}>
                                        {i18next.t("login")}
                                    </Button>
                                </Form.Item>
                                {/* <div id='error_field' style={{marginBottom: '1rem'}}>
                            <Alert message={err} type="error" showIcon/>
                        </div> */}
                            </Form>
                            <div className="underline mt--20 branding">
                                A product of Bluemint
                        {/* A product of <a href="https://bluemint.io" target="_blank">Bluemint</a> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        // <div style={{paddingTop: '3rem'}}>
        //     <Row justify='center' align='center'>
        //         <Col span={24}>
        //             <h1>Phone Sites</h1>
        //         </Col>
        //     </Row>
        // </div>

        // <Row justify='center' align='center' className='form-link'>
        //     <Link to={'/forgot-password'}>Forgot Password?</Link>
        // </Row>
    )
}

export default LoginComponent;
