import React from "react";
import { Button, Col, Dropdown, Input, Menu, Row, Tooltip } from "antd";
import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { imageAlign } from "../helpers/textAlign";
import { FaLink } from "react-icons/all";
import TextArea from "antd/es/input/TextArea";
import cheerio from "cheerio";
import { image_sizes, image_types } from "../helpers/textSizes";
import { block_icons } from "../helpers/blockTypes";
import { image_block_html, image_block_html_with_link } from "../helpers/pageTemplates";
import UploadImageComponent from "./UploadImageComponent";
// import TextAlignPicker from './BlockHelper/TextAlignPicker';
import transparent_bg from '../assets/images/transparent-bg.jpg';
import i18next from 'i18next';
function ImageBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {
    const { type, html, link, other } = block;
    let other_json = other ? JSON.parse(other) : {}
    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }
    let image_src = other_json ? other_json.image_src : null;
    let image_size = other_json ? other_json.image_size : null;
    let image_size_name = other_json ? other_json.image_size_name : null;
    let image_alt = other_json ? other_json.image_alt : null;

    const $ = cheerio.load(html);
    const image_wrapper = $('[data-block-type=image]');
    const image = $('[data-block-type=image] img');

    const getImageLinkText = () => {
        return image_src;
    }
    
    const getSelectedSize = () => {
        if(image_size_name){
            return image_size_name
        }
        sizeChange(image_sizes[2]);
    }

    const getSelectedSizeValue = () => {
        if(image_size){
            return image_size
        }
        sizeChange(image_sizes[2]);
    }

    const getImageAlign = () => {
        for (let i = 0, len = imageAlign.length; i < len; i++) {
            if (image_wrapper.hasClass(`image-${imageAlign[i].type}`)) {
                return imageAlign[i];
            }
        }
        alignChange(imageAlign[1]);
        return imageAlign[1];
    }
    const getDescription = () => {
        return image_alt;
    }
    const size_menu = (
        <Menu>
            {image_sizes.map(size => (
                <Menu.Item onClick={() => sizeChange(size)} key={size.name + size.value}>
                    <span>
                        {/* {size.name} */}
                        {i18next.t(size.name.split('-').join('_').toLowerCase())}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    );
    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )
    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }
    const align_menu = (
        <Menu>
            {imageAlign.map(item => (
                <Menu.Item onClick={() => alignChange(item)} key={item.type}
                    className={'align-list-item ' + (getImageAlign().icon === item.icon ? 'selected' : null)}>
                    {item.icon}
                </Menu.Item>
            ))}
        </Menu>
    )
    const sizeChange = (size) => {
        let temp = block;
        image_sizes.map(item => (
            image_wrapper.removeClass(`is-${item.value}`)
        ))
        image_wrapper.addClass(`is-${size.value}`)
        let new_other = other_json;
        new_other.image_size = size.value
        new_other.image_size_name = size.name
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(image_wrapper);
        updateBlock(index, temp);
    }
    const alignChange = (align) => {
        let temp = block;
        imageAlign.map(item => (
            image_wrapper.removeClass(`image-${item.type}`)
        ))
        image_wrapper.addClass(`image-${align.type}`)
        let new_other = other_json;
        new_other.align = align.type
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(image_wrapper);
        updateBlock(index, temp);
    }
    const imageLinkTextChange = (text) => {
        let temp = block;
        image.attr('src', text);
        temp.html = cheerio.html(image_wrapper);
        let new_other = other_json;
        new_other.image_src = text;
        temp.other = JSON.stringify(new_other);
        updateBlock(index, temp);
    }
    const linkValueChange = (event) => {
        let temp = block;
        temp.link = event.target.value;
        if (event.target.value.length === 0) {
            // let html = '<div data-block-type="image" class="image-wrapper" style="text-align: center"><img style="overflow: hidden; max-width: 16rem; width: 100%; display: inline-block" src="" alt=""/></div>';
            const $ = cheerio.load(image_block_html);
            const image_wrapper = $('[data-block-type=image]');
            const image = $('[data-block-type=image] img');
            const description = $('[data-block-type=image] p');
            image_wrapper.css('text-align', getImageAlign().type);
            image.css('max-width', getSelectedSizeValue());
            image.attr('src', getImageLinkText());
            image.attr('alt', getDescription());
            description.text(getDescription());
            temp.html = cheerio.html(image_wrapper);
            updateBlock(index, temp);
        } else {
            // let html = '<div data-block-type="image" class="image-wrapper" style="text-align: center"><a href=""><img style="overflow: hidden; max-width: 16rem; width: 100%; display: inline-block" src="" alt=""/></a></div>';
            const $ = cheerio.load(image_block_html_with_link);
            const image_wrapper = $('[data-block-type=image]');
            const image = $('[data-block-type=image] img');
            const image_link = $('[data-block-type=image] a');
            const description = $('[data-block-type=image] p');
            image_wrapper.css('text-align', getImageAlign().type);
            image.css('max-width', getSelectedSizeValue());
            image_link.attr('href', event.target.value);
            image.attr('src', getImageLinkText());
            image.attr('alt', getDescription());
            description.text(getDescription());
            temp.html = cheerio.html(image_wrapper);
            updateBlock(index, temp);
        }
    }
    const descriptionValueChange = (event) => {
        let temp = block;
        image.attr('alt', event.target.value);
        temp.html = cheerio.html(image_wrapper);
        updateBlock(index, temp);
    }
    const getSelectedType = () => {
        for (let i = 0, len = image_types.length; i < len; i++) {
            if (image.hasClass(`${image_types[i].value}`)) {
                return image_types[i];
            }
        }
        typeChange(image_types[0]);
        return image_types[0];
    }
    const typeChange = (type) => {
        let temp = block;
        let new_other = other_json;
        image_types.map(item => (
            image.removeClass(`${item.value}`)
        ))
        image.addClass(`${type.value}`)
        new_other.type = type.value;
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(image_wrapper);
        updateBlock(index, temp);
    }
    const type_menu = (
        <Menu>
            {image_types.map(item => (
                <Menu.Item onClick={() => typeChange(item)} key={item.type}>
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    )
    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'} className="fx fx--ai-c fx--jc-sb">
                    <div className="fx fx--ai-c">
                        <span className={'icon fx fx--ai-c'}>{block_icons[type]} </span>
                        <span className={'heading'}>{i18next.t(type)}</span>
                    </div>
                    <div className="uploadBtn-box">
                        <UploadImageComponent selectImageEvent={imageLinkTextChange} />
                    </div>
                </Col>
                <Col align={'right'} className="imageAction-column">
                    <div className="imageActions fx fx--ai-c ml--10">

                        <Button.Group>
                            <Dropdown overlay={type_menu} trigger={['click']}>
                                <Button className="btn">
                                    {getSelectedType().name}
                                </Button>
                            </Dropdown>
                            <Dropdown overlay={size_menu} trigger={['click']}>
                                <Button className="btn">
                                    {getSelectedSize() && i18next.t(getSelectedSize().split('-').join('_').toLowerCase())}
                                    {/* {getSelectedSize()} */}
                                </Button>
                            </Dropdown>
                            <Dropdown overlay={align_menu} trigger={['click']}>
                                <Button className="btn">
                                    {getImageAlign().icon}
                                </Button>
                            </Dropdown>
                            <Dropdown overlay={settings_menu} trigger={['click']}>
                                <Button className="btn" icon={<SettingOutlined />} />
                            </Dropdown>
                        </Button.Group>
                    </div>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        placeholder={i18next.t("link_to_your_image")}
                        className={'link-input, textarea'}
                        prefix={<FaLink />}
                        autoSize={{minRows: 2}}
                        allowClear
                        value={getImageLinkText()}
                        onChange={(event) => imageLinkTextChange(event.target.value)} />
                </Col>
                {/*{show_secondary_fields &&*/}
                <>
                    <Col span={24}>
                        <Input
                            placeholder={i18next.t("link(optional)")}
                            className={'link-input input'}
                            prefix={<FaLink />}
                            allowClear
                            value={link}
                            onChange={linkValueChange} />
                    </Col>
                    <Col span={24}>
                        <Input
                            placeholder={i18next.t("description(recommeded)")}
                            className={'link-input input'}
                            allowClear
                            suffix={
                                <Tooltip title={i18next.t("image_alt_text_recommend")}
                                    overlayClassName={'desc-tooltip'}>
                                    <ExclamationCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                                </Tooltip>
                            }
                            value={getDescription()} onChange={descriptionValueChange} />
                    </Col>
                    <Col span={24}>
                        <div className={'image-box-wrapper'}>
                            <div className={'image-box'} style={{ backgroundImage: `url(${transparent_bg})` }}>
                                {getImageLinkText() !== null && getImageLinkText() !== '' &&
                                    <img src={getImageLinkText()} alt={getImageLinkText()} />
                                }
                                {/* {(getImageLinkText() === null || getImageLinkText() === '') &&
                                    <UploadImageComponent selectImageEvent={imageLinkTextChange} />
                                } */}
                            </div>
                        </div>
                    </Col>
                </>
                {/*}*/}
            </Row>
        </>
    )
}
export default ImageBlockComponent;