import React, { useEffect, useState } from "react";
import { Button, Tooltip, Col, Modal, notification, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, LineChartOutlined, SettingOutlined, CopyOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import axios from "axios";
import { auth_headers } from "../helpers/headers";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { history } from "../helpers/history";
import EmptyState from './EmptyState';
import Spinner from "./Spinner";
import i18next from 'i18next';

function WebSitesListComponent({ sort_ascending, search_text }) {

    const dispatch = useDispatch();
    const { is_loading, deleting_website, websites, pagination } = useSelector(state => state.websites);
    const [show_modal, setShowModal] = useState(false);
    const [selected_website_id, setSelectedWebsiteId] = useState('');

    const getWebsites = (page_number = 1) => {
        dispatch({ type: 'GET_WEBSITES_REQUEST' })
        let url = process.env.REACT_APP_API_URL + `websites?page=${page_number}`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                dispatch({ type: 'GET_WEBSITES_SUC', payload: res.data.data, pagination: res.data.meta.pagination })
            })
            .catch(err => {
                dispatch({ type: 'GET_WEBSITES_ERR' })
            })
    }

    useEffect(() => {
        getWebsites()
    }, []);

    const openWebsiteDeleteModal = (id) => {
        setSelectedWebsiteId(id);
        setShowModal(true);
    }

    const closeWebsiteDeleteModal = () => {
        setSelectedWebsiteId('');
        setShowModal(false);
    }

    const deleteWebsite = () => {
        if (selected_website_id !== '') {
            dispatch({ type: 'DELETE_WEBSITE_REQUEST' })
            let url = process.env.REACT_APP_API_URL + 'websites/' + selected_website_id;
            axios.delete(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                .then(res => {
                    dispatch({ type: 'DELETE_WEBSITE_SUCCESS', payload: selected_website_id })
                    closeWebsiteDeleteModal();
                    getWebsites();
                })
                .catch(error => {
                    dispatch({ type: 'DELETE_WEBSITE_ERROR' })
                    closeWebsiteDeleteModal()
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                })
        }
    }

    const onCopy = (message) => {
        notification.success({
            message: message,
            duration: 2
        })
    }

    const columns = [
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{i18next.t("websites")}</span>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            width: 400,
            render: (name, item) => (
                <Link className={'p-detail-pages'} to={'/websites/' + item.id}>
                    {name}
                </Link>
            )
        },
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{i18next.t("link")}</span>
                </div>
            ),
            dataIndex: 'subdomain',
            key: 'subdomain',
            width: 400,
            render: (subdomain) => (
                <React.Fragment>
                    <div className="input-group">
                        <div className="share-link-box fx fx--ai-c">
                            <div className="share-link">{subdomain}.{process.env.REACT_APP_SITE_URL}</div>
                            <div className="copyBtn-box">
                                <CopyToClipboard text={`${subdomain}.${process.env.REACT_APP_SITE_URL}`}
                                    onCopy={text =>
                                        onCopy(`${text} copied to clipboard`)
                                    }>
                                    <Tooltip trigger={['click']}>
                                        <Button className="btn btn-primary copy-btn">
                                            <CopyOutlined />
                                        </Button>
                                    </Tooltip>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        },
        {
            title: i18next.t("updated"),
            dataIndex: 'updated',
            key: 'updated',
            width: 300,
            render: updated => (
                <Tooltip
                    placement="top"
                    title={moment(updated).format('MMMM Do YYYY, h:mm:ss a')}>
                    {moment(updated).fromNow()}
                </Tooltip>
            )
        },
        {
            title: i18next.t("actions"),
            key: "action",
            fixed: 'right',
            render: (data, item) => (
                <div className="action-button">
                    <Tooltip placement='top' title={i18next.t("settings")}>
                        <Button className="btn-icon warning" type="link" onClick={() => history.push('/websites/' + item.id + '/config')}>
                            <SettingOutlined className='website-icons' />
                        </Button>
                    </Tooltip>
                    <Tooltip placement='top' title={i18next.t("trend")}>
                        <Button className="btn-icon primary" type="link" onClick={() => history.push('/websites/' + item.id + '/stats')}>
                            <LineChartOutlined className='website-icons' />
                        </Button>
                    </Tooltip>
                    {show_modal &&
                        <Tooltip placement='top' title={i18next.t("delete")}>
                            <Button className="btn-icon danger" type="link">
                                <DeleteOutlined className='website-icons remove-icon' />
                            </Button>
                        </Tooltip>
                    }
                    {!show_modal &&
                        <Tooltip placement='top' title={i18next.t("delete")}>
                            <Button className="btn-icon danger" type="link" onClick={() => openWebsiteDeleteModal(item.id)}>
                                <DeleteOutlined className='website-icons remove-icon' />
                            </Button>
                        </Tooltip>
                    }
                </div>
            )
        }
    ];


    return (
        <div className="">
            <Row>
                {is_loading ?
                    <Col span={24}>
                        <Spinner />
                    </Col>
                    :
                    (
                        websites && websites.length !== 0 ?
                            <div className="website-table-content">
                                <Table
                                    style={{ fontSize: 13 }}
                                    dataSource={websites}
                                    columns={columns}
                                    className="table-list"
                                    rowKey={website => website.id}
                                    pagination={{
                                        onChange: getWebsites,
                                        current: pagination && pagination.current_page ? pagination.current_page : 1,
                                        pageSize: pagination && pagination.per_page ? pagination.per_page : 1,
                                        total: pagination && pagination.total ? pagination.total : 1
                                    }}
                                />
                            </div>
                            :
                            <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                <EmptyState message={i18next.t("websites_not_found")} />
                            </div>
                    )

                }
                <Modal
                    visible={show_modal}
                    title={i18next.t("confirm_delete_website")}
                    onCancel={closeWebsiteDeleteModal}
                    footer={[
                        <Button
                            key={'delete_website'}
                            type={'danger'}
                            loading={deleting_website}
                            onClick={deleteWebsite}
                        >
                            {i18next.t("delete_website")}
                        </Button>,
                        <Button
                            key={'cancel'}
                            type={'default'}
                            onClick={closeWebsiteDeleteModal}
                        >
                            {i18next.t("cancel")}
                        </Button>
                    ]}
                >
                    <Row>
                        <Col span={24}>
                            <p>
                                {i18next.t("are_you_sure")}?<br />
                                {i18next.t("the_webiste_leads_all_pages_will_be_deleted")}.<br />
                                {i18next.t("this_can't_be_undone")}!
                            </p>
                        </Col>
                    </Row>
                </Modal>
            </Row>
        </div>
    )
}

export default WebSitesListComponent;
