import {v4 as uuidv4} from 'uuid';
const initial_state = [
    {
        id: 1,
        title: 'Real Estate: Dream Home Template for Realtors',
        domain: 'humbly-proudly-soft',
        description: 'Realtors will love this template. 2 Page Funnel, first page gives away listings',
        user: 'Chris McCoy',
        pages: [
            {
                id: uuidv4(),
                title: '🏡Find Your Dream Home In Chestnut Hills With Our Insider\'s List of the Hottest Available Properties!!',
                is_home: true,
                url: '/',
                html: ''
            },
            {
                id: uuidv4(),
                title: '🏡Dream Home',
                is_home: false,
                url: '/dream-home',
                html: ''
            },
            {
                id: uuidv4(),
                title: 'Privacy Policy',
                is_home: false,
                url: '/privacy-policy',
                html: ''
            }
        ]
    },
    {
        id: 2,
        title: 'Finance: Credit Analysis Lander',
        domain: 'down-wire-warm',
        description: 'Simple 1 page lead capture page with example lead magnet. Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jake Wiles',
        pages: [
            {
                id: uuidv4(),
                title: 'Get My Free Credit Analysis & E-Guide',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    },
    {
        id: 3,
        title: 'Autos: Car buyers are saving thousands with these 3 simple tips',
        domain: 'boy-bird-towards',
        description: 'One page squeeze: "See These 3 Simple Things Dealers Don’t Want You To know That Can Save You Big Time". Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jake Wiles',
        pages: [
            {
                id: uuidv4(),
                title: 'Car buyers are saving thousands with these 3 simple tips',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    },
    {
        id: 4,
        title: 'Fitness: 1-Page Opt-In: 6-Week Fitness Challenge',
        domain: 'pro-below-pushed',
        description: 'Simple 1 page, medium length fitness challenge example. Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jonathan Lautermilch',
        pages: [
            {
                id: uuidv4(),
                title: '6-Week Fitness Challenge',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    },
    {
        id: 5,
        title: 'Fitness: 1-Page Opt-In: 6-Week Fitness Challenge',
        domain: 'pro-below-pushed',
        description: 'Simple 1 page, medium length fitness challenge example. Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jonathan Lautermilch',
        pages: [
            {
                id: uuidv4(),
                title: '6-Week Fitness Challenge',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    },
    {
        id: 6,
        title: 'Fitness: 1-Page Opt-In: 6-Week Fitness Challenge',
        domain: 'pro-below-pushed',
        description: 'Simple 1 page, medium length fitness challenge example. Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jonathan Lautermilch',
        pages: [
            {
                id: uuidv4(),
                title: '6-Week Fitness Challenge',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    },
    {
        id: 7,
        title: 'Fitness: 1-Page Opt-In: 6-Week Fitness Challenge',
        domain: 'pro-below-pushed',
        description: 'Simple 1 page, medium length fitness challenge example. Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jonathan Lautermilch',
        pages: [
            {
                id: uuidv4(),
                title: '6-Week Fitness Challenge',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    },
    {
        id: 8,
        title: 'Fitness: 1-Page Opt-In: 6-Week Fitness Challenge',
        domain: 'pro-below-pushed',
        description: 'Simple 1 page, medium length fitness challenge example. Add a thank you page (and optionally, a privacy policy page).',
        user: 'Jonathan Lautermilch',
        pages: [
            {
                id: uuidv4(),
                title: '6-Week Fitness Challenge',
                is_home: true,
                url: '/',
                html: ''
            },
        ]
    }
];

const WebsiteTemplatesReducer = (state = initial_state, action) => {
    return state;
}

export default WebsiteTemplatesReducer;
