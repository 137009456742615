import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import cheerio from 'cheerio';
import { SketchPicker } from 'react-color';
import { block_icons } from "../helpers/blockTypes";
import { v4 as uuidv4 } from 'uuid';
import { textAlign } from "../helpers/textAlign";
import { icon_tsizes } from "../helpers/textSizes";
import IconPickerComponent from "./IconPickerComponent";
import TextSizePicker from './BlockHelper/TextSizePicker';
import TextAlignPicker from './BlockHelper/TextAlignPicker';
import i18next from 'i18next';
function IconBlockComponent({ data, index, updateBlock, blocks_length, updateBlockSettings, updateData: updateDisplay }) {
    const [permissions, setPermissions] = useState([]);
    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])
    const { type, html, other } = data;
    let other_json = other ? JSON.parse(other) : {}

    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }
    let links = other_json && other_json.links;
    const $ = cheerio.load(html);
    const icon_wrapper = $('[data-block-type=icons]');
    const links_wrapper = $('[data-block-type=icons] [data-field=link]');
    const getSelectedSize = () => {
        for (let i = 0, len = icon_tsizes.length; i < len; i++) {
            if (links_wrapper.hasClass(`is-${icon_tsizes[i].value}`)) {
                return icon_tsizes[i].name;
            }
        }
        sizeChange(icon_tsizes[0]);
        return icon_tsizes[0].name;
    }
    const sizeChange = (size) => {
        let new_data = data;
        icon_tsizes.map(item => (
            links_wrapper.removeClass(`is-${item.value}`)
        ))
        links_wrapper.addClass(`is-${size.value}`)
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const getColor = () => {
        if (links_wrapper.children().length !== 0) {
            return links_wrapper.children().first().css('color') !== undefined ? links_wrapper.children().first().css('color') : changeColor('rgba(0,0,0,1)');
        } else {
            return 'rgba(0,0,0,1)';
        }
    }
    const getBackgroundColor = () => {
        return icon_wrapper.css('background') !== undefined ? icon_wrapper.css('background') : changeBackground('rgba(0, 0, 0, 255)')
    }
    const getLink = (index) => {
        let link = links[index]
        return link.href
    }
    const getLinkText = (index) => {
        let link = links[index]
        return link.text
    }
    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }
    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )
    const addLink = () => {
        let new_data = data;
        let new_other = other_json;
        let index = uuidv4();
        new_other.links.push({ text: "fab fa-airbnb", href: "https://", index: index });
        new_data.other = JSON.stringify(new_other);
        links_wrapper.append('<a data-field-index="' + index + '" class="social-link fab fa-airbnb" target="_blank" href="/" style="color: ' + getColor() + ';"></a>');
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const removeLink = (link_index) => {
        let new_data = data;
        let new_other = other_json
        links_wrapper.children($('[data-field-index=' + links[link_index].index + ']')).remove();
        new_other.links.splice(link_index, 1);
        new_data.other = JSON.stringify(new_other);
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const changeLinkText = (icon, index) => {
        let new_data = data;
        let new_other = other_json;
        links_wrapper.children($('[data-field-index=' + links[index].index + ']')).attr('class', `${icon} social-link`)
        new_other.links[index].text = icon
        new_data.other = JSON.stringify(new_other);
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const changeLink = (event, index) => {
        let new_data = data;
        let link_url = event.target.value;
        let new_other = other_json;
        new_other.links[index].href = link_url
        new_data.other = JSON.stringify(new_other);
        links_wrapper.children($('[data-field-index=' + links[index].index + ']')).attr('href', link_url)
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const changeColor = (color) => {
        let new_data = data;
        for (let i = 0, len = links.length; i < len; i++) {
            links_wrapper.children(($('[data-field-index=' + links[i].index + ']'))).css('color', color);
        }
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const changeBackground = (color) => {
        let new_data = data;
        icon_wrapper.css('background', color);
        new_data.html = cheerio.html(icon_wrapper);
        updateBlock(new_data);
    }
    const alignChange = (align) => {
        let temp = data;
        textAlign.map(item => (
            icon_wrapper.removeClass(`has-text-${item.type}`)
        ))
        icon_wrapper.addClass(`has-text-${align.type}`)
        temp.html = cheerio.html(icon_wrapper);
        updateBlock(index, temp);
    }
    const getTextAlign = () => {
        for (let i = 0, len = textAlign.length; i < len; i++) {
            if (icon_wrapper.hasClass(`has-text-${textAlign[i].type}`)) {
                return textAlign[i].icon;
            }
        }
        alignChange(textAlign[1]);
    }
    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={<TextSizePicker text_tsizes={icon_tsizes} sizeChange={sizeChange} />} trigger={['click']}>
                            <Button className="btn" style={{ width: '5rem' }}>
                                {getSelectedSize()}
                            </Button>
                        </Dropdown>
                        {/* <Tooltip
                                placement="top"
                                title={i18next.t("background_color")}
                            >
                                <Dropdown
                                    overlay={
                                        <SketchPicker
                                            onChange={(color) => changeBackground('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                            color={getBackgroundColor()} />
                                    }
                                    trigger={['click']}
                                    placement={'bottomCenter'}
                                >
                                    <Button className="btn colorPicker-btn" style={{ padding: 4 }}>
                                        <span style={{ background: getBackgroundColor() }} />
                                    </Button>
                                </Dropdown>
                            </Tooltip> */}
                        <Dropdown overlay={<TextAlignPicker textAlign={textAlign} alignChange={alignChange} getTextAlign={getTextAlign} />} trigger={['click']}>
                            <Button className="btn">
                                {getTextAlign()}
                            </Button>
                        </Dropdown>
                        <Tooltip
                            placement="top"
                            title={i18next.t("icon_color")}
                        >
                            <Dropdown
                                overlay={
                                    <SketchPicker
                                        onChange={(color) => changeColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                                        color={getColor()} />
                                }
                                trigger={['click']}
                                placement={'bottomCenter'}
                            >
                                <Button className="btn colorPicker-btn" style={{ padding: 4 }}>
                                    <span style={{ background: getColor() }} />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={i18next.t("settings")}
                        >
                            <Dropdown overlay={settings_menu} trigger={['click']}>
                                <Button className="btn" icon={<SettingOutlined />} />
                            </Dropdown>
                        </Tooltip>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                {/* <Col span={24}>
                    <label className="label">{i18next.t("add_icons")}</label>
                </Col> */}
                {links_wrapper.children().length !== 0 && links &&
                    <Col span={24}>
                        <>
                            {links.map((field, index) => (
                                <Row gutter={[10, 0]} key={'field' + index}>
                                    <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                                        <IconPickerComponent changeLinkText={changeLinkText} index={index} selected={getLinkText(index)}
                                            getPopupContainer={trigger => trigger.parentNode}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                                        <Input value={getLink(index)}
                                            placeholder={i18next.t("https://")} allowClear
                                            onChange={(event) => changeLink(event, index)} />
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2} xl={2} className="align-center">
                                        <Button icon={<MinusCircleOutlined />} danger type={'link'} onClick={() => removeLink(index)} />
                                    </Col>
                                </Row>
                            ))}
                        </>
                    </Col>
                }
                <Col span={24}>
                    <Button
                        block type={'dashed'}
                        icon={<PlusOutlined />}
                        onClick={addLink}
                        disabled={links && links.length > 9}
                    >
                        {i18next.t("add_icon")}
                    </Button>
                </Col>
            </Row>
        </>
    )
}
export default IconBlockComponent;