import React from "react";
import PageTemplateDisplayComponent from './PageTemplateDisplayComponent';
import { Select } from "antd";
const { Option } = Select;

const WebsitePreviewComponent = ({ website, page_details, setPageDetails }) => {

    const handlePageChange = (index) => {
        website && website.pages[index] && setPageDetails(website.pages[index]);
    }

    return (
        <div className="modalTemplate-content">
            <div className="selectbox-container">
                <Select
                    className="template-selectbox w-100"
                    defaultValue={[website.pages ? 0 : null]}
                    onChange={handlePageChange}
                >
                    {website.pages && website.pages.map((page, index) => (
                        <Option key={page.id} value={index}>{page.name}</Option>
                    ))}
                </Select>
            </div>
            <div className="templateView-container">
                {
                    page_details && <PageTemplateDisplayComponent nav_block={page_details.nav} nav_footer_block={page_details.nav_footer} page_details={page_details} blocks_data={page_details.blocks} />
                }
            </div>
        </div>
    );
}

export default WebsitePreviewComponent;