import React from "react";
import { Col, Card, Row, Typography, Spin } from "antd";
import lottie from 'lottie-web';
import users from "../assets/animationJson/users.json";
import user from "../assets/animationJson/user.json";
import leads from "../assets/animationJson/collected-leads.json";
import Spinner from "./Spinner";
import i18next from 'i18next';

const { Title } = Typography;
// for lottie
let animObj1 = null;
let animObj2 = null;
let animObj3 = null;


export default class WeeklyStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false };
    }

    // for lottie
    componentDidMount() {
        //call the loadAnimation to start the animation
        animObj1 = lottie.loadAnimation({
            container: this.animBox1, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: users // the path to the animation json
        });
        animObj2 = lottie.loadAnimation({
            container: this.animBox2, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: user // the path to the animation json
        });
        animObj3 = lottie.loadAnimation({
            container: this.animBox3, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: leads // the path to the animation json
        });
    }

    render() {
        let { stats_details, stats_loading } = this.props;
        return (
            <>
                <div className="weeklyStats-container">
                    <Title level={4}>{i18next.t("weekly_stats")}</Title>
                    <Row gutter={30} className="row">
                        <Col className="column" xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card className="stats-card">
                                <div className="icon-box">
                                    <div className="lottie-amination one" ref={ref => this.animBox1 = ref}></div>
                                </div>
                                <div className="stats-content">
                                    <div className="stats-title">{i18next.t("number_of_visits")}</div>
                                    <div className="stats-number">{stats_loading && <Spinner />}</div>
                                    <div className="stats-number">{!stats_loading && stats_details && stats_details['total']}</div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="column" xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card className="stats-card">
                                <div className="icon-box">
                                    <div className="lottie-amination two" ref={ref => this.animBox2 = ref}></div>
                                </div>
                                <div className="stats-content">
                                    <div className="stats-title">{i18next.t("number_of_unique_visits")}</div>
                                    <div className="stats-number">{stats_loading && <Spinner />}</div>
                                    <div className="stats-number">{!stats_loading && stats_details && stats_details['unique']}</div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="column" xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card className="stats-card">
                                <div className="icon-box">
                                    <div className="lottie-amination three" ref={ref => this.animBox3 = ref}></div>
                                </div>
                                <div className="stats-content">
                                    <div className="stats-title">{i18next.t("leads_collected")}</div>
                                    <div className="stats-number">{stats_loading && <Spinner />}</div>
                                    <div className="stats-number">{!stats_loading && stats_details && stats_details['leads']}</div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}