import React from "react";
import { Menu } from "antd";
import i18next from "i18next";

function TextSizePicker({ text_tsizes, sizeChange }) {
    return (
        <Menu>
            {console.log(text_tsizes)}
            {text_tsizes.map(size => (
                <Menu.Item onClick={() => (sizeChange(size))} key={size.name + size.value}>
                    <span>
                        {i18next.t(size.name.split(': ').join('_').toLowerCase())}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    )

}

export default TextSizePicker;