export const timezones = [
    {value: 'Etc/GMT+12',                       name: '(GMT -12:00) International Date Line West'},
    {value: 'Etc/GMT+11',                       name: '(GMT -11:00) Coordinated Universal Time'},
    {value: 'Etc/GMT+10',                       name: '(GMT -10:00) Hawaii Standard Time'},
    {value: 'Etc/GMT+9',                        name: '(GMT -09:00) Coordinated Universal Time'},
    {value: 'Etc/GMT+8',                        name: '(GMT -08:00) Alaska Daylight Time'},
    {value: 'America/Chihuahua',                name: '(GMT -07:00) Mountain Standard Daylight Time (Chihuahua)'},
    {value: 'Etc/GMT+7',                        name: '(GMT -07:00) Baja California'},
    {value: 'Etc/GMT+6',                        name: '(GMT -06:00) Mountain Daylight Time (US &amp; Canada)'},
    {value: 'America/Bogota',                   name: '(GMT -05:00) Bogota, Lima, Quito'},
    {value: 'Etc/GMT+5',                        name: '(GMT -05:00) Central Daylight Time (US &amp; Canada)'},
    {value: 'America/Monterrey',                name: '(GMT -05:00) Central Time (Mexico City, Monterrey)'},
    {value: 'Etc/GMT+4',                        name: '(GMT -04:00) Atlantic Standard Time'},
    {value: 'Etc/GMT+3',                        name: '(GMT -03:00) Newfoundland Daylight Time'},
    {value: 'America/Argentina/Buenos_Aires',   name: '(GMT -03:00) Buenos Aires'},
    {value: 'America/Santiago',                 name: '(GMT -03:00) Santiago'},
    {value: 'Etc/GMT+2',                        name: '(GMT -02:00) Western Greenland Dailyght Time'},
    {value: 'Atlantic/Azores',                  name: '(GMT -01:00) Azores'},
    {value: 'Etc/UTC',                          name: '(GMT +00:00) Greenwich Mean Time'},
    {value: 'Etc/GMT-1',                        name: '(GMT +01:00) Western European Daylight Time'},
    {value: 'Etc/GMT-2',                        name: '(GMT +02:00) Central Africa Time'},
    {value: 'Europe/Istanbul',                  name: '(GMT +02:00) Cairo, Istanbul'},
    {value: 'Etc/GMT-3',                        name: '(GMT +03:00) Arabia Standard Time'},
    {value: 'Asia/Jerusalem',                   name: '(GMT +03:00) Jerusalem'},
    {value: 'Europe/Moscow',                    name: '(GMT +03:00) Moscow, St. Petersburg, Minsk'},
    {value: 'Etc/GMT-4',                        name: '(GMT +04:00) Abu Dhabi'},
    {value: 'Asia/Kabul',                       name: '(GMT +04:30) Kabul'},
    {value: 'Asia/Tehran',                      name: '(GMT +04:30) Tehran'},
    {value: 'Asia/Tashkent',                    name: '(GMT +05:00) Tashkent'},
    {value: 'Asia/Kolkata',                     name: '(GMT +05:30) Chennai, Kolkata, Mumbai, New Delhi'},
    {value: 'Asia/Kathmandu',                   name: '(GMT +05:45) Kathmandu'},
    {value: 'Asia/Dhaka',                       name: '(GMT +06:00) Dhaka'},
    {value: 'Asia/Bangkok',                     name: '(GMT +07:00) Bangkok, Hanoi, Jakarta'},
    {value: 'Australia/Perth',                  name: '(GMT +08:00) Australian Western Time (Perth)'},
    {value: 'Asia/Hong_Kong',                   name: '(GMT +08:00) Hong Kong'},
    {value: 'Asia/Tokyo',                       name: '(GMT +09:00) Tokyo, Seoul'},
    {value: 'Australia/Adelaide',               name: '(GMT +09:30) Australian Central Daylight Time (Adelaide)'},
    {value: 'Australia/Darwin',                 name: '(GMT +09:30) Australian Central Time (Darwin)'},
    {value: 'Australia/Brisbane',               name: '(GMT +10:00) Australian Eastern Time (Brisbane)'},
    {value: 'Australia/Sydney',                 name: '(GMT +10:00) Australian Eastern Daylight Time (Sydney)'},
    {value: 'Pacific/Guam',                     name: '(GMT +10:00) Guam, Port Moresby'},
    {value: 'Asia/Magadan',                     name: '(GMT +11:00) Magadan, New Caledonia'},
    {value: 'Pacific/Tarawa',                   name: '(GMT +12:00) Tarawa'}
];
