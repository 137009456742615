import i18next from 'i18next';
import nl from '../locales/nl/common.json';
import en from '../locales/en/common.json';

i18next.init({
  resources: {
    nl: {
      translation: nl
    },
    en: {
      translation: en
    }
  },
  lng: 'en',
  fallbackLng: 'en' // language to fallback on if key is missing in the current language
});