import React, { useState } from "react";
import cheerio from "cheerio";
import { text_tsizes } from "../helpers/textSizes";
import { textAlign } from "../helpers/textAlign";
import { Button, Col, Dropdown, Input, Menu, Row } from "antd";
import { block_icons } from "../helpers/blockTypes";
import { SketchPicker } from "react-color";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { FaLink } from "react-icons/all";
import { Remarkable } from 'remarkable';
import TextSizePicker from './BlockHelper/TextSizePicker';
import TextAlignPicker from './BlockHelper/TextAlignPicker';
import i18next from 'i18next';

let md = new Remarkable();

function TextBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, link } = block;

    const $ = cheerio.load(html);
    const text_wrapper = $('[data-block-type=text]');
    const text = $('[data-block-type=text] div');

    const getTextValue = () => {
        return block && JSON.parse(block.other) && JSON.parse(block.other).markdown
    }

    const [show_secondary_field, setShowSecondaryField] = useState(getTextValue() && getTextValue().length !== 0);

    const getSelectedSize = () => {
        for (let i = 0, len = text_tsizes.length; i < len; i++) {
            if (text.hasClass(`is-${text_tsizes[i].value}`)) {
                // return text_tsizes[i].name;
                return i18next.t(text_tsizes[i].name.split(': ').join('_').toLowerCase())
            }
        }
        sizeChange(text_tsizes[0]);
        return text_tsizes[0].name;
    }

    const getColor = () => {
        return text.css('color') !== undefined ? text.css('color') : changeColor('rgba(0,0,0,255)')
    }

    const getTextAlign = () => {
        for (let i = 0, len = textAlign.length; i < len; i++) {
            if (text.hasClass(`has-text-${textAlign[i].type}`)) {
                return textAlign[i].icon;
            }
        }
        alignChange(textAlign[1]);
        return textAlign[1].icon;
    }

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const sizeChange = (size) => {
        let temp = block;
        text_tsizes.map(item => (
            text.removeClass(`is-${item.value}`)
        ))
        text.addClass(`is-${size.value}`)
        temp.html = cheerio.html(text_wrapper);
        updateBlock(index, temp);
    }

    const alignChange = (align) => {
        let temp = block;
        textAlign.map(item => (
            text.removeClass(`has-text-${item.type}`)
        ))
        text.addClass(`has-text-${align.type}`)
        temp.html = cheerio.html(text_wrapper);
        updateBlock(index, temp);
    }

    const valueChange = (event) => {
        let temp = block;
        text.html(md.render(event.target.value));
        temp.html = cheerio.html(text_wrapper);
        temp.other = JSON.stringify({ markdown: event.target.value });
        event.target.value.length !== 0 ? setShowSecondaryField(true) : setShowSecondaryField(false);
        updateBlock(index, temp);
    }

    const linkValueChange = (event) => {
        let temp = block;
        temp.link = event.target.value;
        updateBlock(index, temp);
    }

    const changeColor = (color) => {
        let temp = block;
        text.css('color', color)
        temp.html = cheerio.html(text_wrapper);
        updateBlock(index, temp);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col align={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={<TextSizePicker text_tsizes={text_tsizes} sizeChange={sizeChange} />} trigger={['click']}>
                            <Button className="btn">
                                {getSelectedSize()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={
                            <SketchPicker color={getColor()}
                                onChange={(color) => changeColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                            />
                        } trigger={['click']} placement={'bottomCenter'}>
                            <Button className="btn colorPicker-btn" style={{ padding: 4 }}>
                                <span style={{ background: getColor() }} />
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={<TextAlignPicker textAlign={textAlign} alignChange={alignChange} getTextAlign={getTextAlign} />} trigger={['click']}>
                            <Button className="btn">
                                {getTextAlign()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        className="textarea"
                        autoSize={{minRows: 2}}
                        placeholder={block.type.toLocaleString()} allowClear
                        value={getTextValue()} onChange={valueChange} />
                </Col>
                {show_secondary_field &&
                    <Col span={24}>
                        <Input placeholder={i18next.t("link(optional)")} className={'link-input'} prefix={
                            <FaLink />
                        } allowClear
                            value={link} onChange={linkValueChange} />
                    </Col>
                }
            </Row>
        </>
    )

}

export default TextBlockComponent;