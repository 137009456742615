import React from "react";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { block_icons } from "../helpers/blockTypes";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import cheerio from 'cheerio';
import parse from "html-react-parser";
import { image_sizes } from "../helpers/textSizes";
import { textAlign } from "../helpers/textAlign";
import i18next from 'i18next';

function IframeBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, link } = block;

    const $ = cheerio.load(html);
    const iframe_wrapper = $('[data-block-type=iframe]');
    const iframe = $('[data-block-type=iframe] iframe');

    const getSelectedSize = () => {
        for (let i = 0, len = image_sizes.length; i < len; i++) {
            if (image_sizes[i].value === iframe_wrapper.css('max-width')) {
                return image_sizes[i].name;
            }
        }
        sizeChange(image_sizes[1]);
    }

    const alignChange = (align) => {
        let temp = block;
        switch (align.type) {
            case 'left':
                iframe_wrapper.css('margin', 'auto auto auto 0');
                break;
            case 'center':
                iframe_wrapper.css('margin', 'auto auto auto auto');
                break;
            case 'right':
                iframe_wrapper.css('margin', 'auto 0 auto auto');
                break;
            default:
                break;
        }
        temp.html = cheerio.html(iframe_wrapper);
        updateBlock(temp, index);
    }

    const getIframeAlign = () => {
        switch (iframe_wrapper.css('margin')) {
            case 'auto auto auto 0':
                return textAlign[0].icon;
            case 'auto auto auto auto':
                return textAlign[1].icon;
            case 'auto 0 auto auto':
                return textAlign[2].icon;
            default:
                alignChange(textAlign[1]);
                break;
        }
    }

    const size_menu = (
        <Menu>
            {image_sizes.map(size => (
                <Menu.Item onClick={() => sizeChange(size)} key={size.name + size.value}>
                    <span>
                        {size.name}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    );

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const align_menu = (
        <Menu>
            {textAlign.map(item => (
                <Menu.Item onClick={() => alignChange(item)} key={item.type} className={'align-list-item ' + (getIframeAlign() === item.icon ? 'selected' : null)}>
                    {item.icon}
                </Menu.Item>
            ))}
        </Menu>
    )

    const sizeChange = (size) => {
        let temp = block;
        iframe_wrapper.css('max-width', size.value);
        temp.html = cheerio.html(iframe_wrapper);
        updateBlock(index, temp);
    }

    const srcValueChange = (event) => {
        let temp = block;
        temp.link = event.target.value;
        iframe.attr('src', event.target.value);
        temp.html = cheerio.html(iframe_wrapper);
        updateBlock(temp, index);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{type}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={size_menu} trigger={['click']}>
                            <Button style={{ width: '5rem' }}>
                                {getSelectedSize()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={align_menu} trigger={['click']}>
                            <Button className={'align-display'}>
                                {getIframeAlign()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button style={{ width: '2rem', padding: '0.25rem' }} icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'} align={'middle'}>
                <Col span={24}>
                    <TextArea
                        placeholder={i18next.t("enter_link")}
                        allowClear
                        autoSize={{minRows: 2}}
                        value={link}
                        onChange={srcValueChange} />
                </Col>
                <Col span={24}>
                    <div className={'player-wrapper'}>
                        {parse(cheerio.html(iframe))}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default IframeBlockComponent;
