import React from "react";
import cheerio from "cheerio";
import { sub_header_tsizes } from "../helpers/textSizes";
import { textAlign } from "../helpers/textAlign";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { block_icons } from "../helpers/blockTypes";
import { SketchPicker } from "react-color";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import TextSizePicker from './BlockHelper/TextSizePicker'
import TextAlignPicker from './BlockHelper/TextAlignPicker';
import i18next from 'i18next';

function SubHeadlineBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, html, other } = block;
    let other_json = null

    if(other)
        other_json = JSON.parse(other)
    else
        other_json = {}

    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }
    
    let text = other_json ? other_json.text : null;
    let text_size = other_json ? other_json.text_size : null;
    let text_size_name = other_json ? other_json.text_size_name : null;
    let text_color = other_json ? other_json.text_color : null;
    let text_align = other_json ? other_json.text_align : null;

    const $ = cheerio.load(html);
    const subheadline_wrapper = $('[data-block-type=subheadline]');
    const subheadline = $('[data-block-type=subheadline] h1');

    const getSelectedSize = () => {
        if(text_size_name)
            return text_size_name
        sizeChange(sub_header_tsizes[2]);
    }

    const getColor = () => {
        if(text_color)
            return text_color
        changeColor('rgba(0,0,0,255)')
    }

    const getTextAlign = () => {
        for (let i = 0, len = textAlign.length; i < len; i++) {
            if (subheadline.hasClass(`has-text-${textAlign[i].type}`)) {
                return textAlign[i].icon;
            }
        }
        alignChange(textAlign[1]);
    }

    const getTextValue = () => {
        return text;
    }

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const sizeChange = (size) => {
        let temp = block;
        sub_header_tsizes.map(item => (
            subheadline.removeClass(`is-${item.value}`)
        ))
        subheadline.addClass(`is-${size.value}`)
        let new_other = other_json;
        new_other.text_size = size.value
        new_other.text_size_name = size.name
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(subheadline_wrapper);
        updateBlock(index, temp);
    }

    const alignChange = (align) => {
        let temp = block;
        textAlign.map(item => (
            subheadline.removeClass(`has-text-${item.type}`)
        ))
        subheadline.addClass(`has-text-${align.type}`)
        let new_other = other_json;
        new_other.text_align = align.type
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(subheadline_wrapper);
        updateBlock(index, temp);
    }

    const valueChange = (event) => {
        let temp = block;
        let text = event.target.value
        subheadline.text(text);
        let new_other = other_json;
        new_other.text = text
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(subheadline_wrapper);
        updateBlock(index, temp);
    }

    const changeColor = (color) => {
        let temp = block;
        subheadline.css('color', color);
        let new_other = other_json;
        new_other.text_color = color
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(subheadline_wrapper);
        updateBlock(index, temp);
    }

    return (
        <>
            <Row className={'block-item--header'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col align={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        <Dropdown overlay={<TextSizePicker text_tsizes={sub_header_tsizes} sizeChange={sizeChange} />} trigger={['click']}>
                            <Button className="btn">
                                {getSelectedSize()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={
                            <SketchPicker color={getColor()}
                                onChange={(color) => changeColor('rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')')}
                            />
                        } trigger={['click']} placement={'bottomCenter'}>
                            <Button className="btn colorPicker-btn" style={{ padding: 4 }}>
                                <span style={{ background: getColor() }} />
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={<TextAlignPicker textAlign={textAlign} alignChange={alignChange} getTextAlign={getTextAlign} />} trigger={['click']}>
                            <Button className="btn">
                                {getTextAlign()}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
            <Row className={'block-item--tbox'}>
                <Col span={24}>
                    <TextArea
                        className="textarea"
                        autoSize={{minRows: 2}}
                        placeholder={block.type.toLocaleString()} allowClear
                        value={getTextValue()} onChange={valueChange} />
                </Col>
            </Row>
        </>
    )

}

export default SubHeadlineBlockComponent;
