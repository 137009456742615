import React from 'react';
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 42 }} spin />;

function Spinner() {
    return (
        <div className="spinner fx fx--ai-c fx--jc-c" style={{ height: '15rem' }}>
            <Spin indicator={antIcon} />
        </div>
    )
}

export default Spinner;