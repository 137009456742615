import React from "react";
import {AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined} from "@ant-design/icons";

export const textAlign = [
    {type: 'left', icon: <AlignLeftOutlined/>},
    {type: 'centered', icon: <AlignCenterOutlined/>},
    {type: 'right', icon: <AlignRightOutlined/>},
];

export const imageAlign = [
    {type: 'left', icon: <AlignLeftOutlined/>},
    {type: 'center', icon: <AlignCenterOutlined/>},
    {type: 'right', icon: <AlignRightOutlined/>},
];
