import template1 from '../assets/templates/Template1_black.png';
import template2 from '../assets/templates/Template1_call.png';
import template3 from '../assets/templates/Template1_thanks1.png';
import template4 from '../assets/templates/Template1_video1.png';
import template5 from '../assets/templates/Template1_white.png';

const initial_state = [
    {id: 1, title: 'Basic white template', image_url: template1},
    {id: 2, title: 'Thank you page', image_url: template2},
    {id: 3, title: 'Call now page', image_url: template3},
    {id: 4, title: 'Help & Support page', image_url: template4},
    {id: 5, title: 'Video page', image_url: template5},
    {id: 6, title: 'Carousel page', image_url: template1},
];

const PageTemplateReducer = (state = initial_state, action) => {
    return state;
};

export default PageTemplateReducer;
