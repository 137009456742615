import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, Input, Modal, Row, Select, Switch, Tooltip, Typography, notification, Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import { SaveOutlined, CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import axios from "axios";
import { history } from '../helpers/history'
import { auth_headers } from "../helpers/headers";
import transparent_bg from '../assets/images/transparent-bg.jpg';
import UploadImageComponent from "./UploadImageComponent";
import Spinner from "./Spinner";
import i18next from 'i18next';

const { Text } = Typography;
const { Option } = Select;

const WebsiteConfigComponent = ({ website_detail, updateDetailEvent, saveConfigEvent, saving_loader, error }) => {

    const { getting_loading } = useSelector(state => state.website_pages);
    const [tc_loader, setTCLoader] = useState(false);
    const [show_delete_modal, setShowDeleteModal] = useState(false);
    const [del_loader, setDelLoader] = useState(false);
    const [copy_loader, setCopyLoader] = useState(false);
    const [domain_loader, setDomainLoader] = useState(false);
    const [sharing_loader, setSharingLoader] = useState(false);
    const [sharing_id, setSharingID] = useState(null);
    const [tracking_codes, setTrackingCodes] = useState(null);
    const [website_detail_form] = Form.useForm();
    const [permissions, setPermissions] = useState([]);
    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])


    const field_layout = { flex: 'auto' };
    useEffect(() => getTrackingCodes(), [])

    const getTrackingCodes = () => {
        setTCLoader(true);
        let url = process.env.REACT_APP_API_URL + 'tracking-pixels';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setTrackingCodes(res.data.data);
                setTCLoader(false);
            })
            .catch(error => {
                setTCLoader(false);
                console.log(error.response);
            })
    }

    useEffect(() => {
        if (!tc_loader && website_detail !== null) {
            website_detail_form.setFieldsValue({
                name: website_detail['name'],
                subdomain: website_detail['subdomain'],
                tracking_pixel: website_detail['tracking_pixel'],
                meta_title: website_detail['meta_title'],
                meta_description: website_detail['meta_description'],
                custom_domain: website_detail['custom_domain'],
                og_image: website_detail['og_image'],
                favicon: website_detail['favicon'],
            })
            setSharingID(website_detail['share_id'])
        }
    }, [website_detail, tc_loader, website_detail_form])

    useEffect(() => {
        if (error !== undefined && error !== null) {
            website_detail_form.setFields([{
                name: error.field,
                errors: error.error
            }])
        }
    }, [error, website_detail_form])

    const onDetailChange = (field, value) => {
        updateDetailEvent(field, value);
    }

    const onCopy = (message) => {
        notification.success({
            message: message,
            duration: 2
        })
    }

    const closeWebsiteDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const checkDomain = () => {
        setDomainLoader(true);
        let url = `${process.env.REACT_APP_API_URL}validate-domain?domain=${website_detail['custom_domain']}`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDomainLoader(false);
                notification.success({
                    message: `Success! Your CNAME Record for ${website_detail['custom_domain']} is set properly.`,
                    duration: 5
                })
            })
            .catch(error => {
                setDomainLoader(false);
                if (error.response.status === 422) {
                    notification.error({
                        message: error.response.data['errors']['custom_domain'],
                        duration: 5
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("cname_record_not_found_please_check_with_your_dns_registrar_and_try_again"),
                        duration: 5
                    })
                }

            })
    }

    const changeSharingSetting = (value) => {
        setSharingLoader(true);
        let url = `${process.env.REACT_APP_API_URL}websites/${website_detail["id"]}/share`;
        axios.post(url, { share: value }, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setSharingLoader(false);
                    setSharingID(res.data.data.share_id)
                    notification.success({
                        message: i18next.t("successfully_changed_sharing_setting"),
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
            })
            .catch(error => {
                setSharingLoader(false);
                notification.error({
                    message: error.response.data.message,
                    duration: 5
                })

            })
    }

    const copyWebsite = () => {
        setCopyLoader(true);
        let url = `${process.env.REACT_APP_API_URL}websites/${website_detail['id']}/copy`;
        axios.post(url, {}, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setCopyLoader(false);
                    notification.success({
                        message: i18next.t("successfully_copied_the_website"),
                        duration: 2,
                        onClose: () => { window.location.replace(`/websites/${res.data.data.id}`) }
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }

            })
            .catch(error => {
                setCopyLoader(false);
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    const deleteWebsite = () => {
        setDelLoader(true);
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_detail['id'];
        axios.delete(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDelLoader(false);
                closeWebsiteDeleteModal();
                notification.success({
                    message: i18next.t("successfully_deleted"),
                    duration: 2
                })
                history.push('/');
            })
            .catch(error => {
                setDelLoader(false);
                closeWebsiteDeleteModal()
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    return (
        <>
            <div className="tab-content websiteConfig">
                {(getting_loading || tc_loader) &&
                    <Row>
                        <Col span={24}>
                            <Spinner />
                        </Col>
                    </Row>
                }
                {!getting_loading && !tc_loader &&
                    <>
                        <Row justify={'center'}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Form hideRequiredMark={'false'} wrapperCol={field_layout} name='website_detail' size='middle'
                                    className='website-create-form'
                                    onFinish={saveConfigEvent} form={website_detail_form} scrollToFirstError labelAlign={'left'} colon={false}>
                                    <div className="form-block">
                                        <Row className="row" gutter={30}>
                                            <Col className="col" xs={24} sm={24} md={24} lg={12} xl={12}>
                                                <div className="form-content">
                                                    <Card title={i18next.t("website_configuration")}>
                                                        <Form.Item label={<Text>{i18next.t("website_name")}</Text>} id={'name'} name={'name'}
                                                            rules={[
                                                                { required: true, message: 'Please enter name of your website' },
                                                                { min: 3, message: 'Website name should contain 3 or more letters' }
                                                            ]}>
                                                            <Input placeholder={i18next.t("your_website_name")} allowClear
                                                                onChange={(event => onDetailChange('name', event.target.value))} />
                                                        </Form.Item>
                                                        <Form.Item label={<Text>{i18next.t("subdomain")}</Text>} id={'subdomain'} name={'subdomain'}
                                                            rules={[{ required: true, message: 'Please enter website subdomain' }]}>
                                                            <Input placeholder={i18next.t("subdomain")} allowClear addonAfter={<small>.{process.env.REACT_APP_SITE_URL}</small>}
                                                                onChange={(event) => onDetailChange('subdomain', event.target.value)} />
                                                        </Form.Item>
                                                        {permissions.includes('ADD_TRACKING_PIXEL') &&
                                                            <Form.Item label={<Text>{i18next.t("tracking_pixel")}</Text>} id={'tracking_pixel'} name={'tracking_pixel'}>
                                                                <Select mode={'multiple'} allowClear showArrow={true}
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(event) => onDetailChange('tracking_pixel', event)}>
                                                                    {tracking_codes && tracking_codes.map(tc => (
                                                                        <Option value={tc.id} key={tc.id}>{tc.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        }
                                                        <Form.Item label={<Text>{i18next.t("meta_title")}</Text>} id={'meta_title'} name={'meta_title'}>
                                                            <Input placeholder={i18next.t("enter_meta_title")} allowClear
                                                                onChange={(event => onDetailChange('meta_title', event.target.value))} />
                                                        </Form.Item>
                                                        <Form.Item id={'meta_description'} name={'meta_description'}
                                                            label={
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <Text>{i18next.t("meta_description")}</Text>
                                                                </div>
                                                            }>
                                                            <TextArea
                                                                className="textarea"
                                                                placeholder={i18next.t("add_meta_description")}
                                                                allowClear
                                                                autoSize={{ minRows: 2 }}
                                                                onChange={(event => onDetailChange('meta_description', event.target.value))}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item className="fx--ai-fs" id={'og_image'} name={'og_image'}
                                                            label={
                                                                <div>
                                                                    <Text>{i18next.t("meta_image")}</Text><UploadImageComponent selectImageEvent={(url) => onDetailChange('og_image', url)} />
                                                                </div>
                                                            }>
                                                            <TextArea
                                                                className="textarea"
                                                                autoSize={{ minRows: 2 }}
                                                                placeholder={i18next.t("image(recommended)")}
                                                                allowClear
                                                                value={website_detail['og_image']}
                                                                onChange={(event => onDetailChange('og_image', event.target.value))}
                                                            />

                                                            <div style={{
                                                                padding: '2rem',
                                                                textAlign: 'center',
                                                                backgroundImage: `url(${transparent_bg})`,
                                                                border: '1px solid #d9d9d9',
                                                                borderTop: 'none',
                                                            }}>
                                                                {website_detail['og_image'] !== null && website_detail['og_image'] !== '' &&
                                                                    <img
                                                                        src={website_detail['og_image']}
                                                                        alt={website_detail['og_image']}
                                                                        style={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden' }}
                                                                    />
                                                                }
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item className="fx--ai-fs" id={'favicon'} name={'favicon'}
                                                            label={

                                                                <div>
                                                                    <Text>{i18next.t("favicon")}</Text><UploadImageComponent selectImageEvent={(url) => onDetailChange('favicon', url)} />
                                                                </div>
                                                            }>
                                                            <TextArea placeholder={i18next.t("favicon")} allowClear autoSize={{ minRows: 2 }} value={website_detail['favicon']}
                                                                onChange={(event => onDetailChange('favicon', event.target.value))} />

                                                            <div style={{
                                                                padding: '2rem',
                                                                textAlign: 'center',
                                                                backgroundImage: `url(${transparent_bg})`,
                                                                border: '1px solid #d9d9d9',
                                                                borderTop: 'none',
                                                            }}>
                                                                {website_detail['favicon'] !== null && website_detail['favicon'] !== '' &&
                                                                    <img
                                                                        src={website_detail['favicon']}
                                                                        alt={website_detail['favicon']}
                                                                        style={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden' }}
                                                                    />
                                                                }
                                                            </div>
                                                        </Form.Item>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col className="col" xs={24} sm={24} md={24} lg={12} xl={12}>
                                                {permissions.includes('ENTER_CUSTOM_DOMAIN') &&
                                                    <div className="form-content mb--20">
                                                        <Card title={i18next.t("advance_configuration")}>
                                                            <Form.Item label={<Text>{i18next.t("custom_domain")}</Text>} id={'custom_domain'} name={'custom_domain'}>
                                                                <div className="customDomain-inputField">
                                                                    <Input
                                                                        disabled={domain_loader}
                                                                        placeholder={i18next.t("domain_com_optional")}
                                                                        allowClear
                                                                        value={website_detail.custom_domain}
                                                                        onChange={(event) => onDetailChange('custom_domain', event.target.value)}
                                                                    />
                                                                    {website_detail
                                                                        && !website_detail.is_domain_active
                                                                        && website_detail.custom_domain
                                                                        &&
                                                                        <Tooltip
                                                                            title={i18next.t("verify_dns_tooltip_msg")}
                                                                            trigger={['hover']}
                                                                            placement="top"
                                                                        >
                                                                            <Button
                                                                                className="btn btn-warning-outline checkDNS-btn"
                                                                                loading={domain_loader}
                                                                                onClick={checkDomain}
                                                                            >
                                                                                {i18next.t("check_dns")}
                                                                            </Button>
                                                                        </Tooltip>}
                                                                </div>
                                                                {website_detail && !website_detail.is_domain_active && website_detail.custom_domain &&
                                                                    <div className="verify-msg">
                                                                        <React.Fragment>{`Requires DNS update. Add an CNAME record from `}<b>{website_detail.custom_domain}</b>{" to "}
                                                                            <CopyToClipboard text={process.env.REACT_APP_CNAME_RECORD}
                                                                                onCopy={text =>
                                                                                    onCopy(`${text} copied to clipboard`)
                                                                                }>
                                                                                <Tooltip trigger={['click']}>
                                                                                    <span className="copy_link"><b>{process.env.REACT_APP_CNAME_RECORD}</b></span>
                                                                                </Tooltip>
                                                                            </CopyToClipboard>
                                                                        </React.Fragment>
                                                                    </div>
                                                                }
                                                            </Form.Item>
                                                        </Card>
                                                    </div>
                                                }
                                                {permissions.includes('COPY_WEBSITE') &&
                                                    <div className="form-content">
                                                        <Card title={i18next.t("more_configuration")}>
                                                            <div className="form-group">
                                                                <div className="content fx fx--ai-c fx--jc-sb">
                                                                    <label className="label">{i18next.t("copy_the_website")}</label>
                                                                    <Button
                                                                        loading={copy_loader}
                                                                        type={'primary'}
                                                                        icon={<CopyOutlined />} onClick={copyWebsite}
                                                                    >
                                                                        {i18next.t("copy")}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="content fx fx--ai-c fx--jc-sb">
                                                                <label className="label">{i18next.t("share_the_website")}</label>
                                                                <Switch
                                                                    disabled={sharing_loader}
                                                                    defaultChecked={sharing_id !== null}
                                                                    onChange={(checked) => { changeSharingSetting(checked) }}
                                                                />
                                                            </div>
                                                            {sharing_id &&
                                                                <div className="input-group">
                                                                    <div className={'share-link-box fx fx--ai-c fx--jc-sb w-100'}>
                                                                        <div className={'share-link'}>
                                                                            {`${process.env.REACT_APP_SHARE_URL}/${sharing_id}`}
                                                                        </div>
                                                                        <div className="copyBtn-box">
                                                                            <CopyToClipboard
                                                                                text={`${
                                                                                    process.env.REACT_APP_SHARE_URL}/${sharing_id}`
                                                                                }
                                                                                onCopy={text =>
                                                                                    onCopy(`Share URL copied to clipboard`)
                                                                                }
                                                                            >
                                                                                <Tooltip trigger={['click']}>
                                                                                    <Button className="btn btn-primary copy-btn">
                                                                                        <CopyOutlined />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </CopyToClipboard>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    <Form.Item className="align-right" wrapperCol={{ span: 24 }} style={{ marginTop: '2rem', marginBottom: '0rem' }}>
                                        {/* <Divider style={{ margin: '0.5rem 0' }} /> */}
                                        <Button
                                            type={'primary'}
                                            htmlType={'submit'}
                                            size={'large'}
                                            icon={<SaveOutlined />}
                                            loading={saving_loader}
                                        >
                                            {i18next.t("save")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        <Modal
                            visible={show_delete_modal}
                            title={i18next.t("confirm_delete_website")}
                            onCancel={closeWebsiteDeleteModal}
                            footer={[
                                <Button
                                    key={'delete_website'}
                                    type={'danger'}
                                    loading={del_loader}
                                    onClick={deleteWebsite}
                                >
                                    {i18next.t("delete_website")}
                                </Button>,
                                <Button
                                    key={'cancel'}
                                    type={'default'}
                                    onClick={closeWebsiteDeleteModal}
                                >
                                    {i18next.t("cancel")}
                                </Button>
                            ]}
                        >
                            <Row>
                                <Col span={24}>
                                    <p>
                                        {i18next.t("are_you_sure")}?<br />
                                        {i18next.t("the_webiste_leads_all_pages_will_be_deleted")}<br />
                                        {i18next.t("this_can't_be_undone")}!
                                    </p>
                                </Col>
                            </Row>
                        </Modal>
                    </>
                }
            </div>
        </>
    )
}

export default WebsiteConfigComponent;
