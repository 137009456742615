import React, { useEffect, useRef, useState } from "react";
import { auth_headers } from "../helpers/headers";
import { Button, Col, DatePicker, Row, notification, Table, Tooltip } from "antd";
import axios from 'axios';
import moment from "moment";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
// import { GoLinkExternal } from "react-icons/all";
import useCurrentWidth from "../helpers/useCurrentWidth";
import { CSVLink } from "react-csv";
import EmptyState from "./EmptyState";
import Spinner from "./Spinner";
import i18next from 'i18next';

// const { Title } = Typography;
const { RangePicker } = DatePicker;
const defaultRanges = [moment().subtract(7, 'days'), moment()];

function LeadsComponent({ website_id }) {

    const [loader, setLoader] = useState(false);
    const [download_loader, setDownloadLoader] = useState(false);
    const [leads, setLeads] = useState(null);
    const [download_data, setDownloadData] = useState('');
    const [leads_meta_info, setLeadsMetaInfo] = useState(null);
    const [ranges, setRanges] = useState(defaultRanges);
    const width = useCurrentWidth();
    const download_csv_ref = useRef();
    const [permissions, setPermissions] = useState([]);
    useEffect(() => setPermissions(localStorage.getItem('permissions').split(',')), [])

    useEffect(() => getLeads(), [])

    const getLeads = () => {
        setLoader(true);
        let url = process.env.REACT_APP_API_URL + 'leads/websites/' + website_id + '?from=' + ranges[0].format('YYYY-MM-DD') + '&to=' + ranges[1].format('YYYY-MM-DD');
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setLoader(false);
                setLeads(res.data.data);
                setLeadsMetaInfo(res.data['meta']['pagination']);
            })
            .catch(error => {
                setLoader(false);
                console.log(error.response);
            })
    }

    const l_head_layout = { xs: { span: 9 }, sm: { span: 5 }, md: { span: 3 } };
    const l_value_layout = { xs: { span: 15 }, sm: { span: 19 }, md: { span: 21 } };

    const changeRanges = (event) => {
        if (event === null) {
            setRanges(defaultRanges);
        } else {
            setRanges(event);
        }
    }

    const downloadLeads = () => {
        setDownloadData('');
        setDownloadLoader(true);
        let url = process.env.REACT_APP_API_URL + 'leads/websites/' + website_id + '/download?from=' + ranges[0].format('YYYY-MM-DD') + '&to=' + ranges[1].format('YYYY-MM-DD');
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setDownloadLoader(false);
                setDownloadData(res.data);
                download_csv_ref.current.link.click();
            })
            .catch(error => {
                setDownloadLoader(false);
                handleError(error);
            })
    }

    const handleError = (error) => {
        notification.error({
            message: error.response.data.message,
            duration: 2
        })
    }

    const columns = [
        {
            title: i18next.t("first_name"),
            dataIndex: 'first_name',
            key: 'first_name',
            width: 400,
        },
        {
            title: i18next.t("last_name"),
            dataIndex: 'last_name',
            key: 'last_name',
            width: 400,
        },
        {
            title: i18next.t("email"),
            dataIndex: 'email',
            key: 'email',
            width: 400,
            render: email => (
                <a href={'mailto:' + email}>{email}</a>
            )
        },
        {
            title: i18next.t("phone_no"),
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: 400,
        },
        {
            title: i18next.t("other"),
            dataIndex: 'other',
            key: 'other',
            width: 400,
            render: other => {
                let data = JSON.parse(other)
                let text = Object.keys(data).map((element) => (
                        <Row>
                            <Col>
                                <b>{`${element} :`}</b>
                            </Col>
                            <Col>
                            &nbsp;{`${data[element]}`}
                            </Col>
                        </Row>
                ))
                return text
            }
        },
        {
            title: i18next.t("page_name"),
            dataIndex: 'page_name',
            key: 'page_name',
            width: 400,
        },
        {
            title: i18next.t("created_at"),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 400,
            render: created_at => (
                <Tooltip placement="topLeft" title={moment(created_at).format('MM/DD/YYYY hh:mm A')}>

                    <div style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                        {moment(created_at).fromNow()}
                    </div>
                </Tooltip>
            )
        },
    ]

    const small_columns = [
        {
            title: 'Details',
            key: 'details',
            width: '100%',
            render: lead => (
                <Col span={24}>
                    <div className={'leads-content'}>
                        <div className={'leads-body'}>
                            <Row className={'l-item'} align={'middle'}>
                                <Col {...l_head_layout} className={'l-head'}>{i18next.t("first_name")}:</Col>
                                <Col {...l_value_layout} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                    {lead['first_name']}
                                </Col>
                            </Row>
                            <Row className={'l-item'} align={'middle'}>
                                <Col {...l_head_layout} className={'l-head'}>{i18next.t("last_name")}:</Col>
                                <Col {...l_value_layout} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                    {lead['last_name']}
                                </Col>
                            </Row>
                            <Row className={'l-item'} align={'middle'}>
                                <Col {...l_head_layout} className={'l-head'}>{i18next.t("email")}:</Col>
                                <Col {...l_value_layout} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                    <a href={'mailto:' + lead['email']}>{lead['email']}</a>
                                </Col>
                            </Row>
                            <Row className={'l-item'} align={'middle'}>
                                <Col {...l_head_layout} className={'l-head'}>{i18next.t("phone_no")}:</Col>
                                <Col {...l_value_layout} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                    {lead['phone_no']}
                                </Col>
                            </Row>
                            <Row className={'l-item'} align={'middle'}>
                                <Col {...l_head_layout} className={'l-head'}>{i18next.t("page_name")}:</Col>
                                <Col {...l_value_layout} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                    {lead['page_name']}
                                </Col>
                            </Row>
                            <Row className={'l-item'} align={'middle'}>
                                <Col {...l_head_layout} className={'l-head'}>{i18next.t("created")}:</Col>
                                <Col {...l_value_layout} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                    {moment(lead['created_at']).fromNow()}
                                    <br />
                                    <small>{moment(lead['created_at']).format('MM/DD/YYYY [@] hh:mm A')}</small>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            )
        }
    ]

    return (
        <>
            <div className="tab-content leads">
                <Row justify={'center'}>
                    <Col span={24}>
                        <div className="title-row">
                            <div className="filter-row fx fx--ai-c fx--jc-sb">
                                {/* <Col>
                                    <Title level={4} className="mb--0-imp">
                                        <Space>Leads {leads_meta_info !== null && <Badge count={leads_meta_info['total']} />}</Space>
                                    </Title>
                                </Col> */}
                                <div className="rangePicker-filter fx fx--ai-c">
                                    <div className="rangePicker">
                                        <RangePicker
                                            defaultValue={ranges}
                                            format={['DD-MMM-YYYY', 'DD-MMM-YYYY']}
                                            disabledDate={(current) => {
                                                return current > moment().endOf('day')
                                            }}
                                            onChange={changeRanges}
                                        />
                                    </div>
                                    <div className="go-btn-box">
                                        <Button
                                            className="btn go-btn"
                                            type={'primary'}
                                            loading={loader} icon={<SearchOutlined />}
                                            onClick={getLeads}
                                        >
                                            {i18next.t("go")}
                                        </Button>
                                    </div>
                                </div>
                                {permissions.includes('DOWNLOAD_LEADS') &&
                                    <div className="download-btn-box">
                                        <Button
                                            className="btn download-btn"
                                            disabled={!(leads && Object.keys(leads).length)}
                                            type={'primary'}
                                            loading={download_loader}
                                            icon={<DownloadOutlined />}
                                            onClick={downloadLeads}
                                        >
                                            {i18next.t("download_csv")}
                                        </Button>
                                        <CSVLink
                                            data={download_data}
                                            style={{ display: 'none' }}
                                            ref={download_csv_ref}
                                            target={'_blank'}
                                            filename={ranges[0].format('DD MMM YY') + '-' + ranges[1].format('DD MMM YY') + '.csv'}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        {loader &&
                            <Row>
                                <Col span={24}>
                                    <Spinner />
                                </Col>
                            </Row>
                        }
                        {!loader && leads !== null && leads_meta_info !== null &&
                            <Row justify={'center'}>
                                {leads.length !== 0 ?
                                    <>
                                        <Col span={24}>
                                            <Table dataSource={leads}
                                                // columns={columns}
                                                columns={width > 740 ? columns : small_columns}
                                                rowkey={lead => lead.id}
                                                pagination={true} />
                                        </Col>
                                        {/* <Col span={24} style={{ marginTop: '1rem' }}>
                                            <div className={'leads-pagination-wrapper'}>
                                                <Space>
                                                    <Button icon={<CaretLeftOutlined />}
                                                        disabled={
                                                            (leads_meta_info['current_page'] === leads_meta_info['total_pages']) ||
                                                            (leads_meta_info['current_page'] === 1)
                                                        } />
                                                    <Button>{leads_meta_info['current_page']}</Button>
                                                    <Button icon={<CaretRightOutlined />}
                                                        disabled={leads_meta_info['current_page'] === leads_meta_info['total_pages']} />
                                                </Space>
                                            </div>
                                        </Col> */}
                                    </>
                                    :
                                    <Col span={24}>
                                        <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                            <EmptyState message={i18next.t("lead_not_found")} />
                                        </div>
                                    </Col>
                                }
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LeadsComponent;
