import React from "react";
import { Col, Row, Spin } from "antd";
import HeadlineBlockComponent from "./HeadlineBlockComponent";
import ImageBlockComponent from "./ImageBlockComponent";
import IconBlockComponent from "./IconBlockComponent";
import { Draggable } from "react-beautiful-dnd";
import VideoBlockComponent from "./VideoBlockComponent";
import ButtonBlockComponent from "./ButtonBlockComponent";
import SpacerBlockComponent from "./SpacerBlockComponent";
import SeparatorBlockComponent from "./SeparatorBlockComponent";
import FormBlockComponent from "./FormBlockComponent";
import SubHeadlineBlockComponent from "./SubHeadlineBlockComponent";
import TextBlockComponent from "./TextBlockComponent";
import TimerBlockComponent from "./TimerBlockComponent";
import IframeBlockComponent from "./IframeBlockComponent";
import CustomHtmlBlockComponent from "./CustomHtmlBlockComponent";
import CalendlyBlockComponent from "./CalendlyBlockComponent";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";

function PageDesignComponent({ blocks, updateBlockData, onUpdateBlockSettings }) {

    const { primary_loading } = useSelector(state => state.page_details)

    const updateBlock = (index, block_data) => {
        updateBlockData(index, block_data);
    }

    const updateBlockSettings = (type, index) => {
        onUpdateBlockSettings(type, index)
    }

    return (
        <Row>
            {primary_loading &&
                <Col span={24}>
                    <Spinner />
                </Col>
            }
            {!primary_loading &&
                <Col span={24}>
                    {blocks.map((block, index) => (

                        <Draggable key={block.sequence} draggableId={'block' + block.sequence} index={index}>
                            {(provided, snapshot) => (
                                <div key={'block' + block.sequence}
                                    className={'block-item'}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    {block.type === 'HEADLINE' &&
                                        <HeadlineBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'SUBHEADLINE' &&
                                        <SubHeadlineBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'TEXT' &&
                                        <TextBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'IMAGE' &&
                                        <ImageBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'ICONS' &&
                                        <IconBlockComponent data={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {(block.type === 'YOUTUBE' || block.type === 'VIMEO') &&
                                        <VideoBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'BUTTON' &&
                                        <ButtonBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'SPACER' &&
                                        <SpacerBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'SEPARATOR' &&
                                        <SeparatorBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'FORM' &&
                                        <FormBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'TIMER' &&
                                        <TimerBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'IFRAME' &&
                                        <IframeBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'HTML' &&
                                        <CustomHtmlBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                    {block.type === 'CALENDLY' &&
                                        <CalendlyBlockComponent block={block} index={index} key={'block' + block.sequence} blocks_length={blocks.length}
                                            updateBlock={updateBlock} updateBlockSettings={updateBlockSettings} />
                                    }
                                </div>
                            )}
                        </Draggable>
                    ))}
                </Col>
            }
        </Row>
    )
}

export default PageDesignComponent;
