export const separator_types = [
    { name: 'Solid', value: 'solid' },
    { name: 'Dashed', value: 'dashed' },
    { name: 'Dotted', value: 'dotted' },
]

export const separator_weights = [
    { name: 'Small', value: 'thin' },
    { name: 'Medium', value: 'thick' },
    { name: 'Large', value: 'thicker' },
]