import React from "react";
import cheerio from "cheerio";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { block_icons } from "../helpers/blockTypes";
import { separator_types, separator_weights } from "../helpers/separatorAttributes";
import i18next from 'i18next';

function SpacerBlockComponent({ block, index, updateBlock, blocks_length, updateBlockSettings }) {

    const { type, other, html } = block;
    let other_json = other ? JSON.parse(other) : {}
    while (typeof other_json === 'string') {
        other_json = JSON.parse(other_json)
    }

    const settings_menu = (
        <Menu>
            {index !== 0 && <Menu.Item key={'setting-mu'} onClick={() => blockSettingsUpdate('move_up')}>
                <span>
                    {i18next.t("move_up")}
                </span>
            </Menu.Item>
            }
            {blocks_length !== (index + 1) && <Menu.Item key={'setting-md'} onClick={() => blockSettingsUpdate('move_down')}>
                <span>
                    {i18next.t("move_down")}
                </span>
            </Menu.Item>
            }
            <Menu.Item key={'setting-cp'} onClick={() => blockSettingsUpdate('copy')}>
                <span>
                    {i18next.t("copy")}
                </span>
            </Menu.Item>
            <Menu.Divider key={'setting-dv'} />
            <Menu.Item key={'setting-dl'} onClick={() => blockSettingsUpdate('delete')}>
                <span>
                    {i18next.t("delete")}
                </span>
            </Menu.Item>
        </Menu>
    )

    const $ = cheerio.load(html);
    const spacer = $('[data-block-type=spacer]');

    const blockSettingsUpdate = (type) => {
        updateBlockSettings(type, index);
    }

    const getSelectedType = () => {
        for (let i = 0, len = separator_types.length; i < len; i++) {
            if (spacer.hasClass(`${separator_types[i].value}`)) {
                return separator_types[i];
            }
        }
        typeChange(separator_types[0]);
        return separator_types[0];
    }

    const getSelectedWeight = () => {
        for (let i = 0, len = separator_weights.length; i < len; i++) {
            if (spacer.hasClass(`${separator_weights[i].value}`)) {
                return separator_weights[i];
            }
        }
        weightChange(separator_weights[1]);
        return separator_weights[1];
    }

    const typeChange = (type) => {
        let temp = block;
        let new_other = other_json;
        separator_types.map(item => (
            spacer.removeClass(`${item.value}`)
        ))
        spacer.addClass(`${type.value}`)
        new_other.type = type.value;
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(spacer);
        updateBlock(index, temp);
    }

    const weightChange = (weight) => {
        let temp = block;
        let new_other = other_json;
        separator_weights.map(item => (
            spacer.removeClass(`${item.value}`)
        ))
        spacer.addClass(`${weight.value}`)
        new_other.weight = weight.value;
        temp.other = JSON.stringify(new_other);
        temp.html = cheerio.html(spacer);
        updateBlock(index, temp);
    }

    const type_menu = (
        <Menu>
            {separator_types.map(item => (
                <Menu.Item onClick={() => typeChange(item)} key={item.type}>
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    )

    const weight_menu = (
        <Menu>
            {separator_weights.map(item => (
                <Menu.Item onClick={() => weightChange(item)} key={item.type}>
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    )

    return (
        <>
            <Row className={'block-item--header spacer'}>
                <Col align={'left'} flex={'auto'}>
                    <span className={'icon'}>{block_icons[type]} </span>
                    <span className={'heading'}>{i18next.t(type)}</span>
                </Col>
                <Col aling={'right'}>
                    <Button.Group style={{ display: 'flex' }}>
                        {/* <Dropdown overlay={type_menu} trigger={['click']}>
                            <Button className="btn" style={{ width: '5rem' }}>
                                {getSelectedType().name}
                            </Button>
                        </Dropdown> */}
                        <Dropdown overlay={weight_menu} trigger={['click']}>
                            <Button className="btn">
                                {getSelectedWeight().name}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={settings_menu} trigger={['click']}>
                            <Button className="btn" icon={<SettingOutlined />} />
                        </Dropdown>
                    </Button.Group>
                </Col>
            </Row>
        </>
    )
}

export default SpacerBlockComponent;
