import React, { useEffect, useRef, useState } from "react";
import { Button, Col, notification, Row, Tabs, Switch, Tooltip } from "antd";
import { FaChartLine, FaCheck, FaCogs, FaPencilAlt, FaRegSave, FaExternalLinkAlt } from 'react-icons/all';
import PageTemplateDisplayComponent from "./PageTemplateDisplayComponent";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import PageSettingsComponent from "./PageSettingsComponent";
import PageDesignComponent from "./PageDesignComponent";
import { useDispatch, useSelector } from "react-redux";
import AddBlockComponent from "./AddBlockComponent";
import { LoadingOutlined, EyeOutlined, SaveOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { auth_headers } from "../helpers/headers";
import { useParams } from "react-router";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import useCurrentWidth from "../helpers/useCurrentWidth";
import StatsComponent from "./StatsComponent";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import NavBlockComponent from "./NavBlockComponent";
import NavFooterBlockComponent from "./NavFooterBlockComponent";
import i18next from 'i18next';

import {
    button_block_html, calendly_block_html, form_block_html,
    headline_block_html,
    image_block_html,
    icon_block_html,
    nav_block_html,
    nav_footer_block_html,
    spacer_block_html,
    separator_block_html,
    subheadline_block_html,
    text_block_html, timer_block_html,
    vimeo_block_html,
    youtube_block_html
} from "../helpers/pageTemplates";

const { TabPane } = Tabs;

function PageDetailComponent() {

    const { website_id, page_id, tab_key } = useParams();
    const dispatch = useDispatch();
    const page_details = useSelector(state => state.page_details['page_details']);
    const { pages } = useSelector(state => state.website_pages);
    const [active_key, setActiveKey] = useState('design');
    const [published_at, setPublishedTime] = useState(null);
    const [page_data, setPageData] = useState(page_details);
    const [nav_block, setNavBlock] = useState({
        is_enabled: false,
        link: null,
        html: nav_block_html,
        type: "NAVBAR",
        other: JSON.stringify(
            {
                links: [
                ],
                image: "",
                alt: ""

            }
        )
    });

    const [nav_footer_block, setNavFooterBlock] = useState({
        is_enabled: false,
        link: null,
        html: nav_footer_block_html,
        type: "FOOTER",
        other: JSON.stringify(
            {
                links: [
                ],
                image: "",
                alt: ""
            }
        )
    });
    const [page_blocks, setPageBlocks] = useState(page_details['blocks']);
    const [is_timer_available, setIsTimerAvailable] = useState(true);
    const [is_form_available, setIsFormAvailable] = useState(true);
    const [saving_page, setSavingPage] = useState(false);
    const [preview, updatePreview] = useState(false);
    const [publish_loader, setPublishLoader] = useState(false);
    const [website_detail, setWebsiteDetail] = useState(null);
    const save_details_button = useRef();
    const width = useCurrentWidth();

    const getPageDetails = () => {
        dispatch({ type: 'GET_PAGE_DETAIL_REQ' })
        const url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                dispatch({ type: 'GET_PAGE_DETAIL_SUC', payload: res.data.data })
            })
            .catch(error => {
                dispatch({ type: 'GET_PAGE_DETAIL_ERR', payload: error.response })
            })
    }

    const getWebsiteDetails = () => {
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setWebsiteDetail(res.data.data);
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
            })
    }

    const getWebsitePages = () => {
        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages';
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                dispatch({ type: 'GET_WEBSITE_PAGES_SUCCESS', payload: res.data.data })
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch({ type: 'GET_WEBSITE_PAGES_ERROR' })
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }
            })
    }

    useEffect(() => {
        setPageData(page_details)
        if (page_details && page_details['nav']) {
            setNavBlock(page_details['nav']);
        }
        if (page_details && page_details['published_at']) {
            setPublishedTime(page_details['published_at']);
        }
        if (page_details && page_details['nav_footer']) {
            page_details['nav_footer'].is_enabled = true
            setNavFooterBlock(page_details['nav_footer']);
        }
    }, [page_details])

    useEffect(() => {
        setPageBlocks(page_details['blocks'])
    }, [page_details])

    useEffect(() => {
        setActiveKey(['design', 'settings', 'stats'].indexOf(tab_key) === -1 ? 'design' : tab_key)
        getPageDetails()
    }, [])

    useEffect(() => {
        getWebsiteDetails();
        getWebsitePages();
    }, [])

    useEffect(() => {
        if (page_blocks !== undefined) {
            setIsFormAvailable(true);
            setIsTimerAvailable(true);
            for (let i = 0, len = page_blocks.length; i < len; i++) {
                if (page_blocks[i].type === 'FORM') {
                    setIsFormAvailable(false);
                }
                if (page_blocks[i].type === 'TIMER') {
                    setIsTimerAvailable(false);
                }
            }
        }
    }, [page_blocks])

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        const new_blocks = Array.from(page_blocks);
        const [removed] = new_blocks.splice(source.index, 1);
        new_blocks.splice(destination.index, 0, removed);

        setPageBlocks(new_blocks);
    }

    const updateBlockData = (index, data) => {
        const new_blocks = [...page_blocks];
        new_blocks[index] = data;
        setPageBlocks(new_blocks);
    }

    const handleError = (error) => {
        if (error.response.status === 422 && Object.keys(error.response.data['errors']).length) {
            let field = Object.keys(error.response.data['errors'])[0];
            notification.error({
                message: error.response.data['errors'][field],
                duration: 2
            })
        } else {
            notification.error({
                message: error.response.data.message,
                duration: 2
            })
        }
    }

    const savePageDetails = () => {
        setSavingPage(true);
        const blocks_url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id + '/blocks';
        const meta_info_url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id + '/metainfo';

        let blocks_payload = {
            blocks: page_blocks
        }
        if (nav_block && nav_block.is_enabled) {
            blocks_payload.blocks = [...blocks_payload.blocks, nav_block];
        }
        if (nav_footer_block.is_enabled) {
            blocks_payload.blocks = [...blocks_payload.blocks, nav_footer_block];
        }
        for (let i = 0, len = blocks_payload.blocks.length; i < len; i++) {
            blocks_payload.blocks[i].sequence = i;
        }
        let meta_info = {
            name: page_data['name'],
            url: page_data['url'],
            meta_title: page_data['meta_title'],
            meta_description: page_data['meta_description'],
            background_color: page_data['background_color'],
            content_area_color: page_data['content_area_color'],
            background_image: page_data['background_image'],
            og_image: page_data['og_image'],
            text_color: page_data['text_color'],
            custom_html: page_data['custom_html'],
            heading_font: page_data['heading_font'],
            tracking_pixel: page_data['tracking_pixel'],
            text_font: page_data['text_font'],
            forward_data: page_data['forward_data'],
            redirect_url: page_data['redirect_url'],
        }
        axios.patch(blocks_url, blocks_payload, {
            headers: {
                ...auth_headers,
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        })
            .then(res => {
                axios.patch(meta_info_url, meta_info, {
                    headers: {
                        ...auth_headers,
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(res => {
                        setSavingPage(false);
                        notification.success({
                            message: i18next.t("saved_successfully"),
                            duration: 2
                        })
                    })
                    .catch(error => {
                        setSavingPage(false);
                        handleError(error)
                    })
            })
            .catch(err => {
                setSavingPage(false);
                handleError(err);
            })
    }

    const publishPage = () => {
        setPublishLoader(true);
        const blocks_url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id + '/blocks';
        const meta_info_url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id + '/metainfo';

        let blocks_payload = {
            blocks: page_blocks
        }
        if (nav_block && nav_block.is_enabled) {
            blocks_payload.blocks = [...blocks_payload.blocks, nav_block];
        }
        if (nav_footer_block.is_enabled) {
            blocks_payload.blocks = [...blocks_payload.blocks, nav_footer_block];
        }
        for (let i = 0, len = blocks_payload.blocks.length; i < len; i++) {
            blocks_payload.blocks[i].sequence = i;
        }
        let meta_info = {
            name: page_data['name'],
            url: page_data['url'],
            meta_title: page_data['meta_title'],
            meta_description: page_data['meta_description'],
            background_color: page_data['background_color'],
            content_area_color: page_data['content_area_color'],
            background_image: page_data['background_image'],
            og_image: page_data['og_image'],
            text_color: page_data['text_color'],
            custom_html: page_data['custom_html'],
            heading_font: page_data['heading_font'],
            tracking_pixel: page_data['tracking_pixel'],
            text_font: page_data['text_font'],
            forward_data: page_data['forward_data'],
            redirect_url: page_data['redirect_url'],
        }
        axios.patch(blocks_url, blocks_payload, {
            headers: {
                ...auth_headers,
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        })
            .then(res => {
                axios.patch(meta_info_url, meta_info, {
                    headers: {
                        ...auth_headers,
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(res => {
                        let url = process.env.REACT_APP_API_URL + 'websites/' + website_id + '/pages/' + page_id + '/publish';
                        axios.post(url, {}, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
                            .then(res => {
                                setPublishLoader(false);
                                setPublishedTime(res.data.data);
                                notification.success({
                                    message: i18next.t("successfully_published"),
                                    duration: 2
                                })
                            })
                            .catch(error => {
                                setPublishLoader(false);
                                handleError(error);
                            })
                    })
                    .catch(error => {
                        setPublishLoader(false);
                        handleError(error)
                    })
            })
            .catch(err => {
                setPublishLoader(false);
                handleError(err);
            })
    }

    const updatePageDetailsEvent = (field, value) => {
        let new_page_details = {
            ...page_data,
        }
        new_page_details[field] = value;
        setPageData(new_page_details);
    }

    const addNewBlock = (block_type) => {
        let new_blocks = [...page_blocks];
        let block_to_add = {
            sequence: uuidv4(),
            type: block_type
        }
        switch (block_type) {
            case 'HEADLINE':
                new_blocks.push({
                    ...block_to_add,
                    html: headline_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            text: "Your strong offer here..."
                        }
                    )
                });
                break;
            case 'SUBHEADLINE':
                new_blocks.push({
                    ...block_to_add,
                    html: subheadline_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            text: "Supporting text for your offer"
                        }
                    )
                });
                break;
            case 'TEXT':
                new_blocks.push({
                    ...block_to_add,
                    html: text_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            markdown: ""
                        }
                    )
                });
                break;
            case 'IMAGE':
                new_blocks.push({
                    ...block_to_add,
                    html: image_block_html,
                    link: null
                });
                break;
            case 'ICONS':
                new_blocks.push({
                    ...block_to_add,
                    html: icon_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            links: [
                            ]
                        }
                    )
                });
                break;
            case 'YOUTUBE':
                new_blocks.push({
                    ...block_to_add,
                    html: youtube_block_html,
                    link: process.env.REACT_APP_YOUTUBE_LINK,
                });
                break;
            case 'VIMEO':
                new_blocks.push({
                    ...block_to_add,
                    html: vimeo_block_html,
                    link: process.env.REACT_APP_VIMEO_LINK,
                });
                break;
            case 'SPACER':
                new_blocks.push({
                    ...block_to_add,
                    html: spacer_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            // type: "solid",
                            weight: "thick",
                            color: "rgb(0, 0, 0, 255)"
                        }
                    )
                });
                break;
            case 'SEPARATOR':
                new_blocks.push({
                    ...block_to_add,
                    html: separator_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            type: "solid",
                            weight: "thick",
                            color: "rgb(0, 0, 0, 255)"
                        }
                    )
                });
                break;
            case 'BUTTON':
                new_blocks.push({
                    ...block_to_add,
                    html: button_block_html,
                    link: process.env.REACT_APP_BUTTON_LINK,
                    other: JSON.stringify(
                        {
                            text: "Click Here"
                        }
                    )
                });
                break;

            case 'FORM':
                new_blocks.push({
                    ...block_to_add,
                    html: form_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            form_fields: [
                                {
                                    placeholder: "John",
                                    visible: true,
                                    required: true,
                                    field_name: "first_name",
                                    type: "text"
                                },
                                {
                                    placeholder: "Doe",
                                    visible: true,
                                    required: true,
                                    field_name: "last_name",
                                    type: "text"
                                },
                                {
                                    placeholder: "Your email",
                                    visible: true,
                                    required: true,
                                    field_name: "email",
                                    type: "email"
                                },
                                {
                                    placeholder: "0123456789",
                                    visible: true,
                                    required: true,
                                    field_name: "phone_number",
                                    type: "text"
                                },
                            ],
                            other_form_fields: [
                            ],
                            button_text: "Let's Go!"
                        }
                    )
                });
                break;
            case 'TIMER':
                new_blocks.push({
                    ...block_to_add,
                    html: timer_block_html,
                    link: null,
                    other: JSON.stringify(
                        {
                            end_date: moment.utc().utcOffset('+00:00').add(7, 'days')._d,
                            message: 'Time has expired!!!',
                            timezone: 'Etc/GMT-0',
                            value: 'Etc/GMT-0'
                        }
                    ),
                })
                break;
            case 'HTML':
                new_blocks.push({
                    ...block_to_add,
                    html: ''
                })
                break;
            case 'CALENDLY':
                new_blocks.push({
                    ...block_to_add,
                    html: calendly_block_html,
                    link: ''
                })
                break;
            default:
                break;
        }
        setPageBlocks(new_blocks);
    }

    const onUpdateBlockSettings = (type, index) => {
        switch (type) {
            case 'move_up':
                let up_new_blocks = Array.from(page_blocks);
                let [up_block] = up_new_blocks.splice(index, 1);
                up_new_blocks.splice((index - 1), 0, up_block);
                setPageBlocks(up_new_blocks);
                break;
            case 'move_down':
                let down_new_blocks = Array.from(page_blocks);
                let [down_block] = down_new_blocks.splice(index, 1);
                down_new_blocks.splice((index + 1), 0, down_block);
                setPageBlocks(down_new_blocks);
                break;
            case 'copy':
                let copy_new_blocks = Array.from(page_blocks);
                let copy_block = { ...copy_new_blocks[index] };
                copy_block.sequence = uuidv4();
                copy_new_blocks.splice((index + 1), 0, copy_block);
                setPageBlocks(copy_new_blocks);
                break;
            case 'delete':
                let delete_new_blocks = [...page_blocks];
                delete_new_blocks.splice(index, 1);
                setPageBlocks(delete_new_blocks);
                break;
            default:
                break;
        }
    }

    const updateNavDisplay = (is_enabled) => {
        let new_nav_block = { ...nav_block };
        new_nav_block.is_enabled = is_enabled
        setNavBlock(new_nav_block);
    }

    const updateNavBlock = (new_block) => {
        setNavBlock({ ...new_block });
    }

    const updateNavFooterDisplay = (is_enabled) => {
        let new_nav_footer_block = { ...nav_footer_block };
        new_nav_footer_block.is_enabled = is_enabled
        setNavFooterBlock(new_nav_footer_block);
    }

    const updateNavFooterBlock = (new_footer_block) => {
        setNavFooterBlock({ ...new_footer_block });
    }

    return (
        <>
            <div className="bodyContent-wrapper">
                <div className="pageDetails-conatiner">
                    <div className="container">
                        <div className="breadcrumb-row">
                            <Row align={'middle'}>
                                <Col flex={'auto'} align={'left'} className="title-badge">
                                    {website_id && page_id &&
                                        <BreadcrumbsComponent website_id={website_id} page_id={page_id} />
                                    }
                                </Col>
                                <Col style={width < 767 ? { marginTop: '0.75rem', width: '100%' } : {}}>
                                    <div className="button-box fx fx--ai-c">
                                        <div className="lg-button-box">
                                            <Button.Group>
                                                <div className="savePublichBtn-box">
                                                    <Button
                                                        className="btn btn-light warning savePublish-btn"
                                                        size={width > 767 ? 'large' : 'middle'}
                                                        onClick={publishPage}
                                                    >
                                                        {publish_loader
                                                            ?
                                                            <LoadingOutlined />
                                                            :
                                                            <CheckCircleOutlined />
                                                        }
                                                        {i18next.t("save_&_publish")}
                                                    </Button>
                                                    {published_at &&

                                                        <div className="lastUpdated-note">
                                                            <span className="label">{i18next.t("last_published")}:</span>
                                                            <span className="timeStamp">
                                                                <Tooltip placement="topLeft" title={moment(published_at).format('MM/DD/YYYY hh:mm A')}>
                                                                    {moment(published_at).fromNow()}
                                                                </Tooltip>
                                                            </span>
                                                        </div>

                                                    }
                                                </div>
                                                <div className="saveBtn-box">
                                                    <Button
                                                        className="btn btn-light success save-btn"
                                                        size={width > 767 ? 'large' : 'middle'}
                                                        ref={save_details_button}
                                                        onClick={savePageDetails}
                                                    >

                                                        {saving_page
                                                            ?
                                                            <LoadingOutlined />
                                                            :
                                                            <SaveOutlined />
                                                        }
                                                        {saving_page ? i18next.t("saving") : i18next.t("save")}
                                                    </Button>
                                                </div>
                                                <div className="publishBtn-box">
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={website_detail && page_data && "//" + website_detail.subdomain + "." + process.env.REACT_APP_SITE_URL + "/" + (page_data.url ? page_data.url : "")}
                                                    >
                                                        <Button
                                                            className="btn btn-light primary preview-btn"
                                                            size={width > 767 ? 'large' : 'middle'}
                                                        >
                                                            <EyeOutlined />
                                                            {i18next.t("preview")}
                                                        </Button>
                                                    </a>
                                                </div>
                                            </Button.Group>
                                        </div>

                                        {active_key !== "stats" &&
                                            <div className="sm-preview-iframe">
                                                <label className="label">{i18next.t("switch_to_preview")}</label>
                                                <Switch onChange={(checked) => { updatePreview(checked) }} />
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="content-row">
                            <Row justify={'center'}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} align={'left'}>
                                    <Tabs activeKey={active_key} onChange={(key) => setActiveKey(key)} className={'w-details-tabs-bar page-design'}>

                                        <TabPane key={'design'} tab={
                                            <span className={'w-details-tabs'}>
                                                <FaPencilAlt className={'tab-icon'} />{i18next.t("design")}
                                            </span>}
                                        >
                                            <div className="tab-content design">
                                                <Row className="row">
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}
                                                        className={preview ? "page-design-wrapper main-column div-hidden" : "page-design-wrapper main-column div-visible"}>
                                                        <div className="form-content">
                                                            <div className="form-group addBlock-buttonBox">
                                                                <AddBlockComponent is_form_available={is_form_available} is_timer_available={is_timer_available} addNewBlock={addNewBlock} />
                                                            </div>
                                                            <div className="form-group">
                                                                <NavBlockComponent data={nav_block} updateData={updateNavDisplay} updateBlock={updateNavBlock} />
                                                            </div>
                                                            <div className="form-group">
                                                                <DragDropContext onDragEnd={onDragEnd}>
                                                                    <Droppable droppableId="droppable">
                                                                        {(provided, snapshot) => (
                                                                            <div key={'droppable'}
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                <PageDesignComponent blocks={page_blocks}
                                                                                    updateBlockData={updateBlockData}
                                                                                    onUpdateBlockSettings={onUpdateBlockSettings} />
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                            <div className="form-group">
                                                                <NavFooterBlockComponent data={nav_footer_block} updateData={updateNavFooterDisplay} updateBlock={updateNavFooterBlock} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}
                                                        className={preview ? "page-design-wrapper main-column div-visible" : "page-design-wrapper main-column div-hidden"}
                                                    >
                                                        {/* {published_at &&
                                                            <Row className="row">
                                                                <Col xs={24} sm={24} md={24} lg={24}>
                                                                    <div className="lastUpdated-note">
                                                                        <span className="label">{i18next.t("last_published")}:</span>
                                                                        <span className="timeStamp">
                                                                            <Tooltip placement="topLeft" title={moment(published_at).format('MM/DD/YYYY hh:mm A')}>
                                                                                {moment(published_at).fromNow()}
                                                                            </Tooltip>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        } */}
                                                        <PageTemplateDisplayComponent
                                                            blocks_data={page_blocks}
                                                            nav_block={nav_block}
                                                            page_details={page_data}
                                                            nav_footer_block={nav_footer_block}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>
                                        <TabPane key={'settings'} tab={
                                            <span className={'w-details-tabs'}>
                                                <FaCogs className={'tab-icon'} />{i18next.t("settings")}
                                            </span>
                                        }>
                                            <div className="tab-content setting">
                                                <Row className="row">
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}
                                                        className={preview ? "page-design-wrapper main-column div-hidden" : "page-design-wrapper main-column div-visible"}>
                                                        <PageSettingsComponent page_details={page_data}
                                                            website_pages={pages}
                                                            website_detail={website_detail}
                                                            updatePageDetailsEvent={updatePageDetailsEvent}
                                                            saveDetailEvent={() => getPageDetails()} />
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}
                                                        className={preview ? "page-design-wrapper main-column div-visible" : "page-design-wrapper main-column div-hidden"}
                                                    >
                                                        {/* {published_at &&
                                                            <Row className="row">
                                                                <Col xs={24} sm={24} md={24} lg={24}>
                                                                    <div className="lastUpdated-note">
                                                                        <span className="label">{i18next.t("last_published")}:</span>
                                                                        <span className="timeStamp">
                                                                            <Tooltip placement="topLeft" title={moment(published_at).format('MM/DD/YYYY hh:mm A')}>
                                                                                {moment(published_at).fromNow()}
                                                                            </Tooltip>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        } */}
                                                        <PageTemplateDisplayComponent
                                                            blocks_data={page_blocks}
                                                            nav_block={nav_block}
                                                            page_details={page_data}
                                                            nav_footer_block={nav_footer_block}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>
                                        <TabPane key={'stats'} tab={
                                            <span className={'w-details-tabs'}>
                                                <FaChartLine className={'tab-icon'} />{i18next.t("trend")}
                                            </span>
                                        }>
                                            <div className="tab-content stats">
                                                <StatsComponent type={'page'} website_id={website_id} page_id={page_id} />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageDetailComponent;
