import React, { useEffect, useState } from "react";
import { Card, Col, Row, notification, Carousel, Button, Typography, Pagination } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import TemplateDetailComponent from "./TemplateDetailComponent";
import axios from 'axios';
import { auth_headers } from "../helpers/headers";
import EmptyState from './EmptyState'
import Spinner from "./Spinner";
import default_template from "../assets/images/default-template.jpg";
import no_page from "../assets/images/no-page.jpg";
import i18next from 'i18next';

const { Title } = Typography;

function TemplateListComponent() {

    const [init_loader, setInitLoader] = useState(false);
    const [modal_loading, setModalLoading] = useState(false);
    const [install_loading, setInstallLoading] = useState(false);
    const [show_detail_modal, setShowDetailModal] = useState(false);
    const [templates, setTemplates] = useState(null);
    const [meta_info, setMetaInfo] = useState(null);
    const [selected_template, setSelectedTemplate] = useState(null);

    useEffect(() => getTemplates(), [])

    const getTemplates = (page_no = 1) => {
        setInitLoader(true);
        let url = `${process.env.REACT_APP_API_URL}templates?page=${page_no}`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                setInitLoader(false);
                setTemplates(res.data.data);
                setMetaInfo(res.data.meta['pagination']);
            })
            .catch(error => {
                setInitLoader(false);
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    const installTemplate = (template_id) => {
        setInstallLoading(true);
        let url = `${process.env.REACT_APP_API_URL}templates/${template_id}/install`;
        axios.get(url, { headers: { ...auth_headers, 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } })
            .then(res => {
                if (res && res.data && res.data.data) {
                    notification.success({
                        message: i18next.t("successfully_installed_the_template"),
                        duration: 1,
                        onClose: () => { window.location.replace(`/websites/${res.data.data.id}`) }
                    })
                }
                else {
                    setInstallLoading(false);
                    notification.error({
                        message: i18next.t("something_went_wrong"),
                        duration: 2
                    })
                }

            })
            .catch(error => {
                setInstallLoading(false);
                notification.error({
                    message: error.response.data.message,
                    duration: 2
                })
            })
    }

    const openTemplateDetailModal = (id) => {
        setSelectedTemplate(id)
        setShowDetailModal(true);
    }

    const closeTemplateDetailModal = () => {
        setSelectedTemplate(null);
        setShowDetailModal(false);
    }

    const getModalLoading = (value) => {
        setModalLoading(value)
    }

    return (
        <>
            {init_loader &&
                <Row>
                    <Col span={24}>
                        <Spinner />
                    </Col>
                </Row>
            }
            {!init_loader &&
                <>
                    <>
                        <div className="bodyContent-wrapper">
                            <div className="container">
                                <Row type="flex" className="container-row" justify="space-between" align="left">
                                    <Col>
                                        <Title level={4} style={{ margin: 0 }}>{i18next.t("template_directory")}</Title>
                                    </Col>
                                </Row>
                                <div className="content-row">
                                    {templates && templates.length !== 0 &&
                                        <div className="templateList-content">
                                            <Row className="row" gutter={30}>
                                                {templates && templates.map((template, index) => (
                                                    <Col className="column" xs={24} sm={12} md={12} lg={6}>
                                                        <Card className="template-card">
                                                            <div className={'w-template--body'}>
                                                                <div className="carousel-box">
                                                                    <Carousel
                                                                        // autoplay
                                                                        dots='true'
                                                                    >
                                                                        {template.pages &&
                                                                            template.pages.map((page, index) => (
                                                                                <div className="templateImg-box">
                                                                                    <div className="img" style={{
                                                                                        backgroundImage: `url(${page.image ? page.image : default_template})`
                                                                                    }} />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        {
                                                                            template.pages && !Object.keys(template.pages).length &&
                                                                            <div className="templateImg-box">
                                                                                <div className="img" style={{
                                                                                    backgroundImage: `url(${no_page})`
                                                                                }} />
                                                                            </div>
                                                                        }
                                                                    </Carousel>
                                                                </div>
                                                                <div className={'w-template--details'}>
                                                                    <div className={'w-template--header'}>
                                                                        <div className="templateName-box">
                                                                            <div className="title">
                                                                                <Typography.Text ellipsis={true}>
                                                                                    {template.name}
                                                                                </Typography.Text>
                                                                            </div>
                                                                        </div>
                                                                        <div className="button-box fx fx--ai-c fx--jc-sb">
                                                                            <Button
                                                                                className="btn"
                                                                                type={'default'}
                                                                                size={'middle'}
                                                                                disabled={install_loading || (template.pages && !Object.keys(template.pages).length)}
                                                                                onClick={() => installTemplate(template.id)}
                                                                            >
                                                                                <DownloadOutlined /> {i18next.t("install")}
                                                                            </Button>
                                                                            <Button
                                                                                className="btn btn-primary"
                                                                                type={'primary'}
                                                                                size={'middle'}
                                                                                disabled={modal_loading || (template.pages && !Object.keys(template.pages).length)}
                                                                                onClick={() => openTemplateDetailModal(template.id)}
                                                                            >
                                                                                <EyeOutlined /> {i18next.t("preview")}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="description">
                                                                        {template.description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                            {meta_info && meta_info.total > meta_info.per_page &&
                                                <Row>
                                                    <Col className="column" xs={24} sm={12} md={12} lg={6}>
                                                        <Pagination
                                                            current={meta_info.current_page ? meta_info.current_page : 1}
                                                            total={meta_info.total ? meta_info.total : 0}
                                                            pageSize={meta_info.per_page ? meta_info.per_page : 1}
                                                            onChange={getTemplates} />
                                                    </Col>
                                                </Row>
                                            }

                                        </div>
                                    }
                                    {templates && templates.length === 0 &&
                                        <div className="empty empty-container fx fx--ai-c fx--jc-c w-100">
                                            <EmptyState message={i18next.t("template_not_found")} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {show_detail_modal && <TemplateDetailComponent show_modal={show_detail_modal} closeModal={closeTemplateDetailModal} template_id={selected_template} modal_loading={getModalLoading} />}
                    </>
                </>
            }
        </>
    )
}

export default TemplateListComponent;
