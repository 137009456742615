import HomeComponent from "../components/HomeComponent";
import TemplateListComponent from "../components/TemplateListComponent";
import WebSiteDetailComponent from "../components/WebSiteDetailComponent";
import PageDetailComponent from "../components/PageDetailComponent";
import PrivacyPolicyPageComponent from "../components/PrivacyPolicyPageComponent";
import TermOfServicePageComponent from "../components/TermOfServicePageComponent";
import HelpPageComponent from "../components/HelpPageComponent";
import SubUserComponent from "../components/SubUserComponent";
import TrackingPixelComponent from "../components/TrackingPixelComponent";
import AccountsComponent from "../components/AccountsComponent";

export const routes = [
    {path: '/', component: HomeComponent, key: ['home']},
    {path: '/templates', component: TemplateListComponent, key: ['templates']},
    {path: '/websites/:website_id', component: WebSiteDetailComponent, key: []},
    {path: '/:website_id/edit/:page_id', component: PageDetailComponent, key: []},
    {path: '/legal', component: PrivacyPolicyPageComponent, key: []},
    {path: '/tos', component: TermOfServicePageComponent, key: []},
    {path: '/help', component: HelpPageComponent, key: ['help']},
    {path: '/tracking-pixel', component: TrackingPixelComponent, key: ['tracking_pixel']},
    {path: '/sub-user', component: SubUserComponent, key: ['sub_user']},
    {path: '/account', component: AccountsComponent, key: ['account']},
]
